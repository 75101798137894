// @vue/component
import ListingPage from '@/components/page-templates/ListingPage.vue';
import RomPaginator from '@/components/pagination/RomPaginator.vue';
import vehicleStatusValues from '@/enums/vehicleStatusValues';
import moment from 'moment';
import Datepicker from 'vuejs-datepicker';
import {
  FETCH_RESERVATIONS,
  FETCH_RESERVATION,
  UPDATE_FILTER_RESERVATIONS,
} from '@/store/actions.type';

export default {
  name: 'Reservationlistpage',
  components: {
    ListingPage,
    RomPaginator,
    Datepicker,
  },
  data: () => ({
    columns: [
      {
        name: 'id',
        label: 'ID',
      },
      {
        name: 'nume',
        label: 'Client',
      },
      {
        name: 'telefon',
        label: 'Telefon',
      },
      {
        name: 'dataCursa',
        label: 'Data Cursa',
        type: 'date',
      },
      {
        name: 'tarif',
        label: 'Tarif',
      },
      {
        name: 'codMoneda',
        label: 'Moneda',
      },
      {
        name: 'numeRuta',
        label: 'Ruta',
      },
      {
        name: 'bilet',
        label: 'Bilet',
      },
      {
        name: 'punctPlecare',
        label: 'Punct Plecare',
      },
      {
        name: 'punctSosire',
        label: 'Punct Sosire',
      },
      {
        name: 'numePunct',
        label: 'Nume Punct',
      },
      {
        name: 'numeJurnal',
        label: 'Nume Jurnal',
      },
      {
        name: 'observatii',
        label: 'Observatii',
      },
      {
        name: 'renuntata',
        label: 'Renuntat',
      },
    ],
    filters: {
      id: null,
      nume: null,
      dataCursa: null,
      numeRuta: null,
      bilet: null,
      numePunct: null,
      punctSosire: null,
      punctPlecare: null,
      numeJurnal: null,

    },
    actions: {}
  }),
  computed: {
    reservations() {
      return this.$store.getters.reservationsItems;
    },
    reservationsPerPage() {
      return this.$store.getters.reservationsPerPage;
    },
    reservationsCurrentPage() {
      return this.$store.getters.reservationsCurrentPage;
    },
    reservationsLastPage() {
      return this.$store.getters.reservationsLastPage;
    },
    reservationsFrom() {
      return this.$store.getters.reservationsFrom;
    },
    reservationsTo() {
      return this.$store.reservationsTo;
    },
    reservationsTotal() {
      return this.$store.getters.reservationsTotal;
    },
    reservationsFilters() {
      return this.$store.getters.reservationsFilters;
    },
  },
  mounted() {
    this.initReservations();
  },
  methods: {
    initReservations() {
      const params = {
        page: this.reservationsCurrentPage,
        perPage: this.reservationsPerPage,
        filters: this.filters,
      };
      this.fetchReservations(params);
    },
    fetchReservations(params) {
      this.$store.dispatch(FETCH_RESERVATIONS, params).then((response) => {
        this.$log.info('FETCH_RESERVATIONS', response);
      }).catch((error) => {
        this.$log.error('FETCH_RESERVATIONS', error);
      });
    },
    onFirstPage() {
      const params = {
        page: 1,
        perPage: this.reservationsPerPage,
        filters: this.filters,
      };
      this.fetchReservations(params);
    },
    onPreviousPage() {
      const params = {
        page: this.reservationsCurrentPage - 1,
        perPage: this.reservationsPerPage,
        filters: this.filters,
      };
      this.fetchReservations(params);
    },
    onChangePage(data) {
      const params = {
        page: data,
        perPage: this.reservationsPerPage,
        filters: this.filters
      };
      this.fetchReservations(params);
    },
    onNextPage() {
      const params = {
        page: this.reservationsCurrentPage + 1,
        perPage: this.reservationsPerPage,
        filters: this.filters,
      };
      this.fetchReservations(params);
    },
    onLastPage() {
      const params = {
        page: this.reservationsLastPage,
        perPage: this.reservationsPerPage,
        filters: this.filters
      };
      this.fetchReservations(params);
    },
    refreshList(data) {
      const params = {
        page: 1,
        perPage: data,
      };
      this.fetchReservations(params);
    },
    onSearch(data) {
      let query = {
        page: 1,
        perPage: this.reservationsPerPage,
        filters: this.filters
      };

      this.fetchReservations(query);
    },
    onDetail(reservation) {
      this.$router.push({name: 'reservation', params: {slug: reservation.id}});
    },
  },
};


