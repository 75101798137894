<template>
  <md-card v-if="pageData">
    <md-card-media v-if="pageData.imageUrl">
      <div id="rom-page-image-header"
           style="background-position:center; background-size: cover;"
           :style="{ 'background-image': 'url(' + pageData.imageUrl + ')' }">

        <md-menu v-if="isAdmin"
                 id="page-image-menu"
                 md-size="big"
                 md-directiown="bottom-end">

          <md-button class="md-icon-button" md-menu-trigger>
            <md-icon>more_vert</md-icon>
          </md-button>

          <md-menu-content>
            <md-menu-item @click="onEdit" v-if="pageMode === 'VIEW'">
              <span>Edit</span>
              <md-icon><i class="far fa-edit"></i></md-icon>
            </md-menu-item>

            <md-menu-item @click="toImplement('admin option')" v-if="pageMode !== 'VIEW' && pageData.imageUrl">
              <span>Remove Image</span>
              <md-icon><i class="fas fa-toggle-on"></i></md-icon>
            </md-menu-item>

            <md-menu-item @click="toImplement('admin option')" v-if="pageMode === 'VIEW'">
              <span>Enable/Disable</span>
              <md-icon><i class="fas fa-toggle-on"></i></md-icon>
            </md-menu-item>

            <md-menu-item @click="toImplement('admin option')" v-if="pageMode === 'VIEW'">
              <span>Comments Enable/Disable</span>
              <md-icon><i class="fas fa-toggle-off"></i></md-icon>
            </md-menu-item>

            <md-menu-item @click="toImplement('admin option')" v-if="pageMode === 'VIEW'">
              <span>Delete</span>
              <md-icon><i class="far fa-trash-alt"></i></md-icon>
            </md-menu-item>
          </md-menu-content>
        </md-menu>

        <editor api-key="67z0ywwm73vd848fmecniir1hgfx8aorxcnifz630vovd8ye"
                v-if="pageMode !== 'VIEW'"
                id="editor-name-header"
                data-field="name"
                tagName='h1'
                :class="'title'"
                :initial-value="pageData.name"
                :inline="true"
                :init="tinyZeroConfig"
                model-events="change"
                output-format="text"
                @onChange="onChangeText">
        </editor>

        <editor api-key="67z0ywwm73vd848fmecniir1hgfx8aorxcnifz630vovd8ye"
                v-if="pageMode !== 'VIEW'"
                id="editor-description-header"
                data-field="description"
                tagName='h2'
                :class="'sub-title'"
                :initial-value="pageData.description"
                :inline="true"
                :init="tinyZeroConfig"
                model-events="change"
                output-format="text"
                @onChange="onChangeText">
        </editor>

        <h1 class="title" v-if="pageMode === 'VIEW'" v-html="pageData.name"></h1>
        <h2 v-if="pageMode == 'VIEW' && pageData.description" class="sub-title" v-html="pageData.description"></h2>
      </div>
    </md-card-media>

    <md-card-header v-if="!pageData.imageUrl">
      <md-card-header-text>
        <editor api-key="67z0ywwm73vd848fmecniir1hgfx8aorxcnifz630vovd8ye"
                v-if="pageMode !== 'VIEW'"
                id="editor-name"
                data-field="name"
                tagName='h1'
                :class="'md-title'"
                :initial-value="pageData.name"
                :inline="true"
                :init="tinyZeroConfig"
                model-events="change"
                output-format="text"
                @onChange="onChangeText">
        </editor>

        <editor api-key="67z0ywwm73vd848fmecniir1hgfx8aorxcnifz630vovd8ye"
                v-if="pageMode !== 'VIEW'"
                id="editor-description"
                data-field="description"
                tagName='h2'
                :class="'md-subhead'"
                :initial-value="pageData.description"
                :inline="true"
                :init="tinyZeroConfig"
                model-events="change"
                output-format="text"
                @onChange="onChangeText">
        </editor>

        <div class="md-title" v-if="pageMode === 'VIEW'" v-html="pageData.name"></div>
        <div class="md-subhead" v-if="pageMode === 'VIEW'" v-html="pageData.description"></div>
      </md-card-header-text>

      <md-menu v-if="isAdmin"
               id="page-menu"
               md-size="big"
               md-direction="bottom-end">
        <md-button class="md-icon-button" md-menu-trigger>
          <md-icon>more_vert</md-icon>
        </md-button>

        <md-menu-content>
          <md-menu-item @click="toImplement('admin option')" v-if="pageMode !== 'VIEW'">
            <span>Add Image</span>
            <md-icon><i class="fas fa-toggle-on"></i></md-icon>
          </md-menu-item>

          <md-menu-item @click="onEdit" v-if="pageMode === 'VIEW'">
            <span>Edit</span>
            <md-icon><i class="far fa-edit"></i></md-icon>
          </md-menu-item>

          <md-menu-item @click="toImplement('admin option')" v-if="pageMode === 'VIEW'">
            <span>Enable/Disable</span>
            <md-icon><i class="fas fa-toggle-on"></i></md-icon>
          </md-menu-item>

          <md-menu-item @click="toImplement('admin option')" v-if="pageMode === 'VIEW'">
            <span>Comments Enable/Disable</span>
            <md-icon><i class="fas fa-toggle-off"></i></md-icon>
          </md-menu-item>

          <md-menu-item @click="toImplement('admin option')" v-if="pageMode === 'VIEW'">
            <span>Delete</span>
            <md-icon><i class="far fa-trash-alt"></i></md-icon>
          </md-menu-item>
        </md-menu-content>
      </md-menu>
    </md-card-header>

    <md-card-content>
      <editor api-key="67z0ywwm73vd848fmecniir1hgfx8aorxcnifz630vovd8ye"
              v-if="pageMode !== 'VIEW'"
              id="editor-content"
              data-field="content"
              :initial-value="pageData.content"
              :inline="true"
              :init="tinyConfig"
              model-events="change"
              @onChange="onChangeText">
      </editor>
      <div v-html="pageData.content" v-if="pageMode === 'VIEW'"></div>
    </md-card-content>

    <md-card-actions v-if="pageMode !== 'CREATE'" md-alignment="right">
      <div class="md-subhead">Posted on : {{pageData.created_at}}</div>
      <div class="md-subhead">Last updated on : {{pageData.updated_at}}</div>
    </md-card-actions>
  </md-card>
</template>

<script>
/* eslint-disable max-len */
import Editor from '@tinymce/tinymce-vue';
import { PAGE_CHANGE_FIELD_VALUE } from '@/store/actions.type';

export default {
  name: 'PageComponent',
  components: {
    Editor,
  },
  props: {
    pageData: Object,
  },
  data: () => ({
    tinyConfig: {
      height: 500,
      menubar: true,
      plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste code help wordcount',
      ],
      toolbar: 'undo redo | formatselect | bold italic forecolor backcolor \\ alignleft aligncenter alignright alignjustify \\ bullist numlist outdent indent | removeformat | help',
    },
    tinyZeroConfig: {
      height: 100,
      menubar: false,
      plugins: [],
      toolbar: 'undo redo | forecolor backcolor \\ | help',
    },
  }),
  computed: {
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    pageMode() {
      return this.$store.getters.pageMode;
    },
  },
  methods: {
    toImplement(text) {
      const message = `To implement ${text}`;
      this.$toasted.info(message, { duration: 2000, icon: 'info' });
    },
    onChangeText(event, editor) {
      const element = editor.getElement();
      const newContent = editor.getContent();
      const theField = element.dataset.field;

      const params = {
        field: theField,
        fieldValue: newContent,
      };

      this.$log.info('PAGE_CHANGE_FIELD_VALUE', params);
      this.$store.dispatch(PAGE_CHANGE_FIELD_VALUE, params).then((response) => {
        this.$log.info('PAGE_CHANGE_FIELD_VALUE', response);
      }).catch((error) => {
        this.$log.error('PAGE_CHANGE_FIELD_VALUE', error);
      });
    },
    onEdit() {
      this.$router.push({ name: 'editPage', params: { slug: this.pageData.slug } });
    },
  },
};
</script>

<style lang="scss">
  @import '@/assets/scss/layout/rom-page-component.scss';
</style>
