import {required, maxLength} from 'vuelidate/lib/validators'
import Multiselect from 'vue-multiselect'
import {
  FETCH_ROUTEDEFINITION,
  CRUISE_CREATE_SUBMIT,
  HUB_CREATE_SUBMIT,
  CRUISE_RESERVATIONS_CREATE_SUBMIT, CRUISE_STATUS_SUBMIT, HUB_EDIT_SUBMIT
} from '@/store/actions.type';
import Calendar from 'v-calendar/lib/components/calendar.umd';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import SelectReservation from '@/components/page-templates/ReservationListPage.vue';
import CruisePlanningEditStep2 from '@/views/CruisePlanningEdit/CruisePlanningEditStep2/CruisePlanningEditStep2.vue';
import daysCalendar from '@/enums/calendar';
import Vue from "vue";
import moment from "moment";

export default {

  name: 'Cruiseplanningedit',
  components: {
    DatePicker,
    Multiselect,
    Calendar,
    SelectReservation,
    CruisePlanningEditStep2,
    daysCalendar
  },
  props: {
    slug: Number | String,
    routeTypeId: Number | String,
    identificationCode: Number | String,
    cruiseDate: Number | String,
    cruiseDay: Number | String,
  },

  data: () => ({
    show: {
      createRoute: true,
      selectReservation: false,
      createRoutesStep2: false,
      createRoutesStep3: false,
    },
    columns: [
      {
        name: 'id',
        label: 'ID',
      },
      {
        name: 'nume',
        label: 'Client',
      },
      {
        name: 'telefon',
        label: 'Telefon',
      },
      {
        name: 'dataCursa',
        label: 'Data Cursa',
        type: 'date',
      },
      {
        name: 'tarif',
        label: 'Tarif',
      },
      {
        name: 'codMoneda',
        label: 'Moneda',
      },
      {
        name: 'numeRuta',
        label: 'Ruta',
      },
      {
        name: 'bilet',
        label: 'Bilet',
      },
      {
        name: 'punctPlecare',
        label: 'Punct Plecare',
      },
      {
        name: 'punctSosire',
        label: 'Punct Sosire',
      },
      {
        name: 'numePunct',
        label: 'Nume Punct',
      },
      {
        name: 'numeJurnal',
        label: 'Nume Jurnal',
      },
      {
        name: 'observatii',
        label: 'Observatii',
      },
      {
        name: 'renuntata',
        label: 'Renuntat',
      },
    ],
    formData: {
      numeTipRuta: null,
      routeId: null,
      countries: null,
      transportType: null,
      trackType: null,
      identificationCode: null,
      courseTime: null,
      date: null,
      day: null,
      calendar: [],
    },
    days: daysCalendar,
    savedCruiseId: null,
    submitStatus: null,
    country_code_component: null,
    country_name_component: null,
    route_type_component: null,
    date: new Date(),
    selectedReservations: [],
    modelConfig: {
      type: 'string',
      // mask: 'd.getDay() ,DD-MM-YYYY, HH:mm', // Uses 'iso' if missing
      mask: "DD-MM-YYYY HH:mm:ss",
    },
    dateC: null,
    routeType: null,
  }),
  computed: {},
  mounted() {
    this.initRouteDefinition();
    this.initCruise();
  },
  methods: {
    initCruise() {
      this.formData.identificationCode = this.identificationCode;
      this.formData.date = moment(this.cruiseDate).format('DD-MM-YYYY h:mm:ss');
      this.formData.day = this.cruiseDay;
    },
    initRouteDefinition() {
      this.$store.dispatch(FETCH_ROUTEDEFINITION, this.routeTypeId).then((response) => {
        this.formData.numeTipRuta = response.data.numeTipRuta;
        this.formData.routeId = response.data.id;
        this.formData.countries = response.data.country_name;
        this.formData.transportType = (response.data.transportType == 'TR_PERS' ? 'Persoane' : 'Colete');
        this.formData.trackType = response.data.trackType;
        this.country_code_component = response.data.country_code;
        this.country_name_component = response.data.country_name;
        this.route_type_component = response.data.routeType;
        // Select the selected days in calendar multiselect
        var calendarArr = response.data.calendar.split(', '); // split the calendar value

        this.formData.calendar = []; //we need to define the array again
        for (var i = 0; i < calendarArr.length; i++) { // loop on calendar items from response
          for (var j = 0; j < daysCalendar.length; j++) { //loop on dayscalendar saved into calendar.js file
            if (calendarArr[i] == daysCalendar[j].value) { //Find each respojnse calendar item into daycalendar
              this.formData.calendar.push(this.days.find(option => option.value === daysCalendar[j].value)); //Push the results into calendar multiselect array
            }
          }
        }
        this.$log.info('FETCH_ROUTEDEFINITION', response);
      }).catch((error) => {
        this.$log.error('FETCH_ROUTEDEFINITION', error);
      });
    },
    computedActiveClass(actualValue, expectedValue) {
      return actualValue === expectedValue ? 'active' : '';
    },
    onSelectSteps(item) {
      this.selectedReservations = item;
      this.show = {
        createRoute: true,
        createRouteStep2: false,
        createRouteStep3: false,
      }
    },
    changeDisplay(createRoute, reservation, createRouteStep2, createRouteStep3) {
      this.show = {
        createRoute: createRoute,
        createRoutesStep2: createRouteStep2,
        createRoutesStep3: createRouteStep3,
      }
    },
    goToRoute(routeName) {
      this.$router.push({name: routeName});
    },
    onSubmitCruiseDefinition(e) {
      Vue.notify({
        group: 'success',
        type: 'success',
        title: 'Se trece la urmatorul pas!',
      })
      this.savedCruiseId = this.slug;
      this.changeDisplay(false, false, true, false);
    },
  },
  watch: {
    slug: {
      handler(val) {
        this.initPageData(val);
      },
    },
  },
};

