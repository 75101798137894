<template>
  <div>
    <div class="form-group">
      <img :src="fileContent" class="w-100 p-3" v-if="fileContent && isImage(file.fileName)" v-b-modal="`modal-${id}`">
      <pdf
        ref="pdf"
        class="w-100 p-3"
        :src="fileContent"
        v-if="fileContent && isPdf(file.fileName)"
        v-b-modal="`modal-${id}`"
      />
      <b-modal :id="`modal-${id}`" :title="title" ok-only ok-variant="btn btn-purple" ok-title="Inchide" v-if="file">
        <img :src="fileContent" class="w-100 h-100" v-if="isImage(file.fileName)">
        <div>
          <div class="text-center">
            <input v-model.number="page" type="number" style="width: 5em" min="1" max="2"> /{{numPages}}
            <button @click="prevPage"><i class="fas fa-angle-double-left md-primary"/></button>
            <button @click="nextPage"><i class="fas fa-angle-double-right md-primary"/></button>
          </div>
          <div v-if="isPdf(file.fileName) && fileContent">
            <div v-if="loadedRatio > 0 && loadedRatio < 1"
                 :style="{ width: loadedRatio * 100 + '%' }">{{ Math.floor(loadedRatio * 100) }}%</div>
            <pdf
              ref="pdf"
              class="w-100 h-100"
              :src="fileContent"
              :page="page"
              @progress="loadedRatio = $event"
              @error="error"
              @num-pages="numPages = $event"
              @link-clicked="page = $event"
            />
          </div>
        </div>
      </b-modal>
    </div>
    <div class="form-group" v-if="expirationDate" >
      <label class="text-purple pl-1">
        Data expirarii
      </label>
      <datepicker :clear-button="true" :bootstrap-styling="true" input-class="form-control mb-3" v-model="expirationDate" :name="id" disabled></datepicker>
    </div>
    <div class="form-group">
      <a class="btn btn-purple btn-md btn-block bg-white primary-color btn-outline-primary" style="color:#603084"
         @click="downloadFile"
         v-if="file"
      >Descarca document</a>
    </div>
  </div>

</template>

<script>
  import { API_URL } from '@/config';
  import Datepicker from 'vuejs-datepicker';
  import { BModal, VBModal } from "bootstrap-vue";
  import pdf from 'vue-pdf'
  import FilesService from '@/services/files.service';


  export default {
    name: 'rfr-file-previewer',
    components: {
      Datepicker,
      BModal,
      pdf
    },
    directives: {
      'b-modal': VBModal
    },
    props: {
      id: String,
      title: String,
      file: {
        type: Object,
        default() {
          return null;
        },
      },
      expirationDate: {
        type: String | Date,
        default() {
          return null;
        },
      },
    },
    data: () => ({
      loadedRatio: 0,
      page: 1,
      numPages: 0,
      previewContent: null,
      fileContent: null,
    }),
    mounted() {
      this.page = 1;
      this.numPages = 1;
      this.loadedRatio = 0;

      if (this.file) {
        this.getFile(this.file);
      }
    },
    computed: {
      // previewContent: function () {
      //   if (this.file) {
      //    this.getFilePreview(this.file);
      //   }
      // },
      // fileContent:  function () {
      //   if (this.file) {
      //    this.getFile(this.file);
      //   }
      // }
    },
    methods: {
      nextPage() {
        if (this.page <= this.numPages) {
          this.page = this.page + 1;
        }
        return this.page;
      },
      prevPage() {
        if (this.page > 1) {
          this.page = this.page - 1;
        }
        return this.page;
      },
      getFilePreview(file) {
        FilesService.getFilePreviewContent(file.id).then((url) => {
          this.previewContent = url;
        });
      },
      getFile(file) {
        FilesService.getFileContent(file.id).then((url) => {
          this.fileContent = url;
        });
      },
      downloadFile() {
        FilesService.downloadFile(this.file.id, this.file.fileName).then((url) => {
        });
      },
      isImage(filename) {
        return filename.match(/.(jpg|jpeg|png|gif)$/i);
      },
      isPdf(filename) {
        return filename.match(/.(pdf)$/i);
      },
      error: function(err) {
        console.log(err);
      },
    },
    watch: {
      file: {
        handler(file) {
          this.getFilePreview(file);
          this.getFile(file);
        },
        deep: true
      },
    },
  };
</script>


<style>
  .autosuggest__input {
    outline: none;
    position: relative;
    display: block;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
  }

  .autosuggest__input.autosuggest__input-open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .autosuggest__results-container {
    position: relative;
    width: 100%;
  }

  .autosuggest__results {
    font-weight: 300;
    margin: 0;
    position: absolute;
    z-index: 10000001;
    width: 100%;
    border: 1px solid #e0e0e0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background: white;
    padding: 0px;
    max-height: 400px;
    overflow-y: scroll;
  }

  .autosuggest__results ul {
    list-style: none;
    padding-left: 0;
    margin: 0;
  }

  .autosuggest__results .autosuggest__results-item {
    cursor: pointer;
    padding: 15px;
  }

  #autosuggest ul:nth-child(1) > .autosuggest__results_title {
    border-top: none;
  }

  .autosuggest__results .autosuggest__results-before {
    color: gray;
    font-size: 11px;
    margin-left: 0;
    padding: 15px 13px 5px;
    border-top: 1px solid lightgray;
  }

  .autosuggest__results .autosuggest__results-item:active,
  .autosuggest__results .autosuggest__results-item:hover,
  .autosuggest__results .autosuggest__results-item:focus,
  .autosuggest__results,
  .autosuggest__results-item.autosuggest__results-item--highlighted {
    background-color: #f6f6f6;
  }
</style>
