import Vue from "vue";
import {
  FETCH_ROUTEDEFINITION,
  CRUISE_RESERVATIONS_VEHICLES_DELETE,
  FETCH_CRUISES_WITH_VEHICLES_AND_DRIVERS,
  FETCH_RESERVATION_VEHICLE_NAME,
  CRUISE_STATUS_SUBMIT
} from "@/store/actions.type";
import daysCalendar from "@/enums/calendar";
import Calendar from 'v-calendar/lib/components/calendar.umd';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
export default {
  name: 'Cruiseplanningcreatestep5',

  components: {},

  mixins: [],

  props: {
    slug: Number | String,
    savedCruiseId: Number,
    reservations: Object | Array,
    routeId: Number | String,
    routeTypeId: Number | String,
  },
  mounted() {
    this.initReservationsWithCars(this.reservations);
    this.initRouteDefinition();
    this.initVehicleAndDriversOnCruise();
  },
  data: () => ({
    show: {
      createRoute: false,
      selectReservation: false,
      createRoutesStep2: false,
      createRoutesStep3: false,
      createRoutesStep4: false,
      createRoutesStep5: true,
    },
    formData: {
      numeTipRuta: null,
      routeId: null,
      countries: null,
      transportType: null,
      trackType: null,
      identificationCode: null,
      courseTime: null,
      date: null,
      calendar: [],
    },
    cruise_vehicles_drivers: null,
    country_code_component: null,
    country_name_component: null,
    route_type_component: null,
  }),
  methods: {
    changeDisplay(createRoute, reservation, createRouteStep2, createRouteStep3, createRoutesStep4, createRoutesStep5) {
      this.show = {
        createRoute: createRoute,
        selectReservation: reservation,
        createRoutesStep2: createRouteStep2,
        createRoutesStep3: createRouteStep3,
        createRoutesStep4: createRoutesStep4,
        createRoutesStep5: createRoutesStep5,
      }
    },
    //Get the current step
    onSelectSteps(item) {
      this.selectedReservations = item;
      this.show = {
        createRoute: false,
        selectReservation: false,
        createRouteStep2: false,
        createRouteStep3: false,
        createRouteStep4: false,
        createRouteStep5: true,
      }
    },
    //Display the route details
    initRouteDefinition() {
      this.$store.dispatch(FETCH_ROUTEDEFINITION, this.routeTypeId).then((response) => {
        this.formData.numeTipRuta = response.data.numeTipRuta;
        this.formData.routeId = response.data.id;
        this.formData.countries = response.data.country_name;
        this.formData.transportType = (response.data.transportType == 'TR_PERS' ? 'Persoane' : 'Colete');
        this.formData.trackType = response.data.trackType;
        this.country_code_component = response.data.country_code;
        this.country_name_component = response.data.country_name;
        this.route_type_component = (response.data.routeType  == 'external') ? 'Extern' : 'Intern';

        this.$log.info('FETCH_ROUTEDEFINITION', response);
      }).catch((error) => {
        this.$log.error('FETCH_ROUTEDEFINITION', error);
      });
    },
    //Find the vehicle number for each reservation
    initReservationsWithCars(reservations) {
      //Get Reservation by country
      Object.entries(reservations).forEach(reservation => {
        let peopleReservations = reservation[1];
        //Get reservation on each country

        peopleReservations.forEach(peopleReservation => {
          //Get the main/transfer vehicle on each reservation
          this.$store.dispatch(FETCH_RESERVATION_VEHICLE_NAME, peopleReservation.id).then((response) => {
            response = response.data;
            //Explode group_concat if the reservation have main and transfer vehicle
            let vehicleNamesArr = response[0]['vehicleNumber'].split(", ");
                peopleReservation['transferVehicleName'] = vehicleNamesArr[1];
            this.$log.info('FETCH_RESERVATION_VEHICLE_NAME', response);
          }).catch((error) => {
            this.$log.error('FETCH_RESERVATION_VEHICLE_NAME', error);
          });
        });
      });
    },
    //display the cruise vehicles with drivers
    initVehicleAndDriversOnCruise() {
      this.$store.dispatch(FETCH_CRUISES_WITH_VEHICLES_AND_DRIVERS, this.savedCruiseId).then((response) => {
        response = response.data;

        this.cruise_vehicles_drivers = response;
        this.$log.info('FETCH_CRUISES_WITH_VEHICLES_AND_DRIVERS', response);
      }).catch((error) => {
        this.$log.error('FETCH_CRUISES_WITH_VEHICLES_AND_DRIVERS', error);
      });
    },
    //Go back

    onSelectPreviousStep() {
      this.$store.dispatch(CRUISE_RESERVATIONS_VEHICLES_DELETE, this.savedCruiseId).then((response) => {
        Vue.notify({
          group: 'success',
          type: 'success',
          title: 'Atribuirea soferilor pe vehicule a fost stearsa!',
        })
        this.$emit('onSelectSteps', false, false, false, false, true, false);
        this.$log.info('CRUISE_RESERVATIONS_VEHICLES_DELETE', response);
      }).catch((error) => {
        this.$log.error('CRUISE_RESERVATIONS_VEHICLES_DELETE', error);
      });
    },
    submitPlanningCourse() {
      Vue.notify({
        group: 'success',
        type: 'success',
        title: 'Cursa a fost planificata!',
      })
      let status = 'completed';
      this.$store.dispatch(CRUISE_STATUS_SUBMIT, {id: this.savedCruiseId, status}).then((response) => {
        this.submitStatus = null;
        this.$log.info('CRUISE_STATUS_SUBMIT', response);
        this.$router.push({name: 'cruisePlanningList'});
      }).catch((error) => {
        this.submitStatus = null;
        if (error.response && error.response.status === 400) {
          this.invalidFieldsErrors = error.response.data || {};
        }
        this.$log.error('CRUISE_STATUS_SUBMIT', error);
      });
    },
  },
  watch: {
    slug: {
      handler(val) {
        this.initPageData(val);
      },
    },
  },
}
