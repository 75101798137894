// @vue/component
import {
  FETCH_CRUISE_RESERVATIONS_VEHICLES,
  CRUISE_RESERVATIONS_VEHICLES_CREATE,
  CRUISE_RESERVATIONS_VEHICLES_DELETE,
  CRUISE_RESERVATIONS_MAIN_VEHICLE_SUBMIT,
  FETHC_CRUISE_RESERVATIONS_VEHICLE_DRIVERS
} from "@/store/actions.type";
import Vue from "vue";
import {required} from "vuelidate/lib/validators";
import {VueAutosuggest} from 'vue-autosuggest';
import RomfourDriverAutosuggest from "@/components/autosuggest/RomfourDriverCruise";
import CruisePlanningEditStep5 from "@/views/CruisePlanningEdit/CruisePlanningEditStep5/CruisePlanningEditStep5.vue";
import RomfourVehicleAutosuggest from "@/components/autosuggest/RomfourVehiclesCruisePlanning";

export default {
  name: 'Cruiseplanningeditstep4',

  components: {
    RomfourDriverAutosuggest,
    RomfourVehicleAutosuggest,
    VueAutosuggest,
    CruisePlanningEditStep5
  },

  props: {
    slug: Number | String,
    reservations: Object | Array,
    savedCruiseId: Number,
    routeId: Number | String,
    routeTypeId: Number | String,
  },

  data: () => ({
    show: {
      createRoute: false,
      selectReservation: false,
      createRoutesStep2: false,
      createRoutesStep3: false,
      createRoutesStep4: true,
      createRoutesStep5: false,
    },
    vehicles: null,
    cruiseId: null,
    mainDriverId: null,
    backupDriverId: null,
    secondDriverId: null,
    drivers: {},
    submitStatus: false,
    formData: {},
    mainTrailerId: null,
    selectedBackupVehicleId: null,
    selectedTrailersId: null,

  }),
  validations: {
    drivers: {required},
  },

  mounted() {
    this.getVehicles();
    this.getSavedDriversWithVehicles();
    console.log(this.drivers);
  },
  methods: {
    //Change the template for each step
    changeDisplay(createRoute, reservation, createRoutesStep2, createRoutesStep3, createRoutesStep4, createRoutesStep5) {
      this.show = {
        createRoute: createRoute,
        selectReservation: reservation,
        createRoutesStep2: createRoutesStep2,
        createRoutesStep3: createRoutesStep3,
        createRoutesStep4: createRoutesStep4,
        createRoutesStep5: createRoutesStep5,
      }
    },
    //Go back a step
    onSelectSteps(item) {
      this.selectedReservations = item;
      this.show = {
        createRoute: false,
        createRoutesStep2: false,
        createRoutesStep3: false,
        createRoutesStep4: true,
        createRoutesStep5: false,
      }
    },
    //Get the selected vehicles on this cruise
    getVehicles() {
      this.$store.dispatch(FETCH_CRUISE_RESERVATIONS_VEHICLES, this.savedCruiseId).then((response) => {
        response = response.data;
        this.vehicles = response;

        this.$log.info('FETCH_CRUISE_RESERVATIONS_VEHICLES', response);
      }).catch((error) => {
        this.$log.error('FETCH_CRUISE_RESERVATIONS_VEHICLES', error);
      });
    },
    //Save the main driver for each vehicle
    selectMainDriverHandler(item) {
      //Used if the driver is already selected, if not create a new array
      if (this.drivers[item.vehicleId]) {
        this.drivers[item.vehicleId]['main_driver'] = item.codAng;
      } else {
        this.drivers[item.vehicleId] = {
          'main_driver': item.codAng,
          'vehicleType': item.vehicleType,
          'cruiseId': this.savedCruiseId
        };
      }
    },
    //Save the second driver for each vehicle
    selectSecondDriverHandler(item) {
      //Used if the driver is already selected, if not create a new array
      if (this.drivers[item.vehicleId]) {
        this.drivers[item.vehicleId]['second_driver'] = item.codAng;
      } else {
        this.drivers[item.vehicleId] = {
          'second_driver': item.codAng,
          'vehicleType': item.vehicleType,
          'cruiseId': this.savedCruiseId
        };
      }
    },
    //Save the bakcup driver for each vehicle
    selectBackupDriverHandler(item) {
      //Used if the driver is already selected, if not create a new array
      if (this.drivers[item.vehicleId]) {
        this.drivers[item.vehicleId]['backup_driver'] = item.codAng;
      } else {
        this.drivers[item.vehicleId] = {
          'backup_driver': item.codAng,
          'vehicleType': item.vehicleType,
          'cruiseId': this.savedCruiseId
        };
      }
    },
    selectTrailerVehicle(item) {
      if (this.drivers[item.vehicleId]) {
        this.drivers[item.vehicleId]['selectedTrailersId'] = item.codAutovehicul;
      } else {
        this.drivers[item.vehicleId] = {
          'selectedTrailersId': item.codAutovehicul,
        };
      }
    },
    getSavedDriversWithVehicles(){
      this.$store.dispatch(FETHC_CRUISE_RESERVATIONS_VEHICLE_DRIVERS, this.savedCruiseId).then((response) => {
        let drivers = {};
        _.each(response.data, function (value, key) {
          drivers[value.vehicleId] = {
            'vehicle_id': value.vehicleId,
            'main_driver': value.mainDriverId,
            'main_driver_name': value.mainDriverName,
            'second_driver': value.secondDriverId,
            'second_driver_name': value.secondDriverName,
            'backup_driver': value.backupDriverId,
            'backup_driver_name': value.backupDriverName,
            'selectedTrailersId': value.trailerId,
            'selectedTrailersId_name': value.trailerNumberPlate,
            'selectedBackupVehicleId': value.backupVehicleId,
            'selectedBackupVehicleId_name': value.backupVehicleNumberPlate,
            'vehicleType': value.vehicleType,
            'cruiseId': value.cruiseId
          }
        });
        this.drivers = drivers;

        Vue.notify({
          group: 'success',
          type: 'success',
          title: 'Atribuirea soferilor pe vehicule a fost stearsa!',
        })
        this.$log.info('FETHC_CRUISE_RESERVATIONS_VEHICLE_DRIVERS', response);
      }).catch((error) => {
        this.$log.error('FETHC_CRUISE_RESERVATIONS_VEHICLE_DRIVERS', error);
      });
    },
    selectBackupVehicle(item) {
      if (this.drivers[item.vehicleId]) {
        this.drivers[item.vehicleId]['selectedBackupVehicleId'] = item.codAutovehicul;
      } else {
        this.drivers[item.vehicleId] = {
          'selectedBackupVehicleId': item.codAutovehicul,

        };
      }
    },
    //Go back a step and delete the saved drivers for vehicles
    onSelectPreviousStep() {
      this.$emit('onSelectSteps', false, false, false, true, false, false);
    },
    //Submit form with the selected drivers
    onSubmitCruiseReservationDefinitionDrivers() {
      //Validation for main driver
      // if(this.vehicles['mainTrailerId']) {

      //   this.mainTrailerId = this.vehicles['mainTrailerId'];
      //   delete this.vehicles['mainTrailerId'];
      // }
      if (Object.keys(this.drivers).length === 0) {
        this.submitStatus = true;
      } else {
        let counter = 0;
        Object.values(this.vehicles).forEach(vehicle => {
          Object.keys(vehicle).forEach(nr => {
            counter++;
          });
        });
        if (Object.keys(this.drivers).length !== counter) {
          this.submitStatus = true;
        } else {
          this.submitStatus = false;
        }
      }
      if (this.submitStatus === false) {
        this.formData = this.drivers;
        let formData = new FormData();
        formData.append('data', JSON.stringify(this.formData));
        this.$store.dispatch(CRUISE_RESERVATIONS_VEHICLES_CREATE, formData).then((response) => {
          this.submitStatus = null;
          this.$log.info('CRUISE_RESERVATIONS_VEHICLES_CREATE', response);
          Vue.notify({
            group: 'success',
            type: 'success',
            title: 'Soferii au fost salvati cu success!',
          })
          this.vehicles['mainTrailerId'] = this.mainTrailerId;
          this.changeDisplay(false, false, false, false, false, true);
        }).catch((error) => {
          this.submitStatus = null;
          if (error.response && error.response.status === 400) {
            this.invalidFieldsErrors = error.response.data || {};
          }
          this.$log.error('CRUISE_RESERVATIONS_VEHICLES_CREATE', error);
        });
      }
    }
  },
  watch: {
    slug: {
      handler(val) {
        this.initPageData(val);
      },
    },
  },
}
