import {FETCH_RESERVATION} from '@/store/actions.type';

export default {
  name: 'Reservationdetailpage',
  components: {},
  props: {
    slug: Number | String,
  },
  data: () => ({
    response: null,
  }),
  computed: {},
  mounted() {
    this.initReservation();
  },
  methods: {
    initReservation() {
      this.$store.dispatch(FETCH_RESERVATION, this.slug).then((response) => {
        this.response = response.data;
        this.$log.info('FETCH_RESERVATION', response);
      }).catch((error) => {
        this.$log.error('FETCH_RESERVATION', error);
      });
    },
    goToRoute(route) {
      this.$router.push({name: route});
    },

  },
  watch: {
    slug: {
      handler(val) {
        this.initPageData(val);
      },
    },
  },
};

