<template>
  <div>
    <div class="row">
      <div class="col mb-3">
        <span class="text-purple text-md pl-3 pr-1">Situatia Autovehiculului: {{numberPlate}}</span>
      </div>
      <div class="col mb-3">
        <a class="btn btn-purple btn-block shadow" style="float:right; margin-right: 20px; width: 75%;" @click="goToRoute('vehiclesCourseType')">Inapoi catre Lista de Autovehicule</a>
      </div>
    </div>
    <md-card class="card filters">
      <md-card-content class="card-body rounded shadow-lg">
        <div class="card-header text-purple">
          Cautare
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-3 col-sm-12">
              <div class="form-group">
                <label class="text-purple pl-1">
                  Status
                </label>
                <select
                  v-model="filters.status"
                  class="form-control"
                  id="vehicle-status-select"
                >
                  <option value=""></option>
                  <option :value="vehicleStatusVal.transfer">{{$t('transfer')}}</option>
                  <option :value="vehicleStatusVal.course">{{$t('course')}}</option>
                  <option :value="vehicleStatusVal.transferAndCourse">{{$t('transferAndCourse')}}</option>
                  <option :value="vehicleStatusVal.damaged">{{$t('damaged')}}</option>
                  <option :value="vehicleStatusVal.broken">{{$t('broken')}}</option>
                  <option :value="vehicleStatusVal.withoutDriver">{{$t('withoutDriver')}}</option>
                  <option :value="vehicleStatusVal.withoutDocuments">{{$t('withoutDocuments')}}</option>
                  <option :value="vehicleStatusVal.service">{{$t('service')}}</option>
                  <option :value="vehicleStatusVal.ontrack">{{$t('ontrack')}}</option>
                </select>
              </div>
            </div>
            <div class="col-md-3 col-sm-12">
              <div class="form-group">
                <label class="text-purple pl-1">
                  Numar inmatriculare*
                </label>
                <input
                  v-model="filters.numberPlate"
                  class="form-control mb-3"
                  type="text"
                  id="input-license-plate"
                >
              </div>
            </div>
            <div class="col-md-3 col-sm-12">
              <div class="form-group">
                <label class="text-purple pl-1">
                  Data Inceperii
                </label>
                <datepicker :clear-button="true" :bootstrap-styling="true"
                            input-class="form-control"
                            v-model="filters.startAt" id="startAt" name="startAt"></datepicker>
              </div>
            </div>
            <div class="col-md-3 col-sm-12">
              <div class="form-group">
                <label class="text-purple pl-1">
                  Data Finalizarii
                </label>
                <datepicker :clear-button="true" :bootstrap-styling="true"
                            input-class="form-control"
                            v-model="filters.endAt" id="endAt" name="endAt"></datepicker>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-2 col-sm-12 pl-1">
              <md-button class="btn-purple btn-block shadow purple" @click="onSearch()">
                Cauta
              </md-button>
            </div>
          </div>
        </div>
      </md-card-content>
    </md-card>
    <md-card class="card">
      <md-card-content class="card-body rounded shadow-lg">
        <!--TABLE-->
        <listing-page :items="vehiclesStatus"
                      :columns="columns"
        >
        </listing-page>
        <!--PAGINATION-->
        <div class="md-layout">
          <div class="md-layout-item md-size-50">
            {{ vehiclesStatusTotal }} Vehicule (de la {{ vehiclesStatusFrom }} pana la {{ vehiclesStatusTo }})
          </div>
          <div class="md-layout-item md-size-50">
            <rom-paginator :current-page="vehiclesStatusCurrentPage"
                           :last-page="vehiclesStatusLastPage"
                           :threshold="2"
                           :has-prev-next="true"
                           :has-first-last="true"
                           @onFirstPage="onFirstPage"
                           @onPreviousPage="onPreviousPage"
                           @onNextPage="onNextPage"
                           @onLastPage="onLastPage"
                           @onChangePage="onChangePage">
            </rom-paginator>
          </div>
        </div>
      </md-card-content>
    </md-card>

  </div>
</template>

<script>
import ListingPage from '@/components/page-templates/ListingPage.vue';
import RomPaginator from '@/components/pagination/RomPaginator.vue';
import vehicleStatusValues from '@/enums/vehicleStatusValues';
import moment from 'moment';
import Datepicker from 'vuejs-datepicker';
import {
  FETCH_VEHICLES_STATUS,
  FETCH_VEHICLE_STATUS,
  UPDATE_VEHICLE_STATUS,
} from '@/store/actions.type';

export default {
  name: 'currentVehicleStatusListPage',
  components: {
    ListingPage,
    RomPaginator,
    Datepicker,
  },
  props: {
    slug: Number | String,

  },
  data: () => ({
    columns: [
      {
        name: 'vehicleId',
        label: 'Cod Autovehicul',
      },
      {
        name: 'status',
        label: 'Situatie Autovehicul',
      },
      // {
      //   name: 'subStatus',
      //   label: 'Motiv Masina Indisponibila',
      // },
      {
        name: 'numberPlate',
        label: 'Numar Inmatriculare',
      },
      {
        name: 'startAt',
        label: 'Data Inceperii',
        type: 'datetime',
      },
      {
        name: 'endAt',
        label: 'Data Finalizarii',
        type: 'datetime',
      },
      {
        name: 'createdBy',
        label: 'Create de',
      },
      {
        name: 'updatedBy',
        label: 'Modificat de'
      },
    ],
    vehicleStatusVal: vehicleStatusValues,
    filters: {
      status: null,
      subStatus: null,
      vehicleId: null,
      numberPlate: null,
      startAt: null,
      endAt: null,
      createdBy : null,
      updatedBy: null,
    },
    numberPlate: null,
    actions: {}
  }),
  computed: {
    vehiclesStatus() {
      return this.$store.getters.vehiclesStatusItems;
    },
    vehiclesStatusPerPage() {
      return this.$store.getters.vehiclesStatusPerPage;
    },
    vehiclesStatusCurrentPage() {
      return this.$store.getters.vehiclesStatusCurrentPage;
    },
    vehiclesStatusLastPage() {
      return this.$store.getters.vehiclesStatusLastPage;
    },
    vehiclesStatusFrom() {
      return this.$store.getters.vehiclesStatusFrom;
    },
    vehiclesStatusTo() {
      return this.$store.getters.vehiclesTo;
    },
    vehiclesStatusTotal() {
      return this.$store.getters.vehiclesStatusTotal;
    },
    vehiclesStatusFilters() {
      return this.$store.getters.vehiclesStatusFilters;
    },
  },
  mounted() {
    this.filters.vehicleId = this.slug;
    this.initVehiclesStatus();
    this.$i18n.locale = 'ro';
  },
  methods: {
    initVehiclesStatus() {
      const params = {
        page: this.vehiclesCurrentPage,
        perPage: this.vehiclesPerPage,
        filters: this.filters,
      };
      this.fetchVehiclesStatus(params);
    },
    fetchVehiclesStatus(params) {
      this.$store.dispatch(FETCH_VEHICLES_STATUS, params).then((response) => {
        this.$log.info('FETCH_VEHICLES_STATUS', response);
        if(response['data'][0].numberPlate) {
          this.numberPlate = response['data'][0].numberPlate;
        }
      }).catch((error) => {
        this.$log.error('FETCH_VEHICLES_STATUS', error);
      });
    },
    onFirstPage() {
      const params = {
        page: 1,
        perPage: this.vehiclesStatusPerPage,
        filters: this.filters,
      };
      this.fetchVehiclesStatus(params);
    },
    onPreviousPage() {
      const params = {
        page: this.vehiclesStatusCurrentPage - 1,
        perPage: this.vehiclesStatusPerPage,
        filters: this.filters,
      };
      this.fetchVehiclesStatus(params);
    },
    onChangePage(data) {
      const params = {
        page: data,
        perPage: this.vehiclesStatusPerPage,
        filters: this.filters
      };
      this.fetchVehiclesStatus(params);
    },
    onNextPage() {
      const params = {
        page: this.vehiclesStatusCurrentPage + 1,
        perPage: this.vehiclesStatusPerPage,
        filters: this.filters,
      };
      this.fetchVehiclesStatus(params);
    },
    onLastPage() {
      const params = {
        page: this.vehiclesStatusLastPage,
        perPage: this.vehiclesStatusPerPage,
        filters: this.filters
      };
      this.fetchVehiclesStatus(params);
    },
    refreshList(data) {
      const params = {
        page: 1,
        perPage: data,
      };
      this.fetchVehiclesStatus(params);
    },
    onSearch(data) {
      let query = {
        page: 1,
        perPage: this.vehiclesStatusPerPage,
        filters: this.filters
      };

      this.fetchVehiclesStatus(query);
    },
    goToRoute(route) {
      this.$router.push({ name: route});
    },
  },
  watch: {
    slug: {
      handler(val) {
        this.initPageData(val);
      },
    },
  },
};
</script>
