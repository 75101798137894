<template>
  <div>
    <div class="row">
      <div class="col mb-3">
          <span class="text-purple text-md pl-3 pr-1">Lista autovehiculelor</span>
      </div>
    </div>
    <md-card class="card filters">
      <md-card-content class="card-body rounded shadow-lg">


          <div class="card-header text-purple">
            Cautare
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-3 col-sm-12">
                <div class="form-group">
                  <label class="text-purple pl-1">
                    Clasa
                  </label>
                  <select
                    v-model="filters.vehicleClass"
                    class="form-control text-purple"
                    id="vehcile-class-select"
                  >
                    <option value=""></option>
                    <option v-for="vehicleClass in classes" :value="vehicleClass.codClasaAuto">
                      {{ vehicleClass.numeClasaAuto }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-md-3 col-sm-12">
                <div class="form-group">
                  <label class="text-purple pl-1">
                    Tip
                  </label>
                  <select
                    v-model="filters.vehicleType"
                    class="form-control"
                    id="vehicle-type-select"
                    >
                    <option v-for="vType in types" :value="vType.codTipAutovehicul">
                      {{ vType.numeTipAutovehicul }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-md-3 col-sm-12">
                <div class="form-group">
                  <label class="text-purple pl-1">
                    Status
                  </label>
                  <select
                    v-model="filters.vehicleStatus"
                    class="form-control"
                    id="vehicle-status-select"
                  >
                    <option value=""></option>
                    <option value="activ">Activ</option>
                    <option value="inactiv">Inactiv</option>
                  </select>
                </div>
              </div>

              <div class="col-md-3 col-sm-12">
                <div class="form-group">
                  <label class="text-purple pl-1">
                    Disponibilitate
                  </label>
                  <select
                    v-model="filters.vehicleAvailabilityStatus"
                    class="form-control"
                    id="vehicle-av-status-select"
                  >
                    <option value=""></option>
                    <option value="acte oprite">acte oprite</option>
                    <option value="activa/ruleaza">activa/ruleaza</option>
                    <option value="avariata">avariata</option>
                    <option value="defect">defect</option>
                    <option value="disparuta">disparuta</option>
                    <option value="donata">donata</option>
                    <option value="furata">furata</option>
                    <option value="la casat">la casat</option>
                    <option value="la radiat">la radiat</option>
                    <option value="la vanzare">la vanzare</option>
                    <option value="lasata in parcare">lasata in parcare</option>
                    <option value="libera">libera</option>
                    <option value="lipsa valabilitate acte">lipsa valabilitate acte</option>
                    <option value="parc">parc</option>
                    <option value="retinut UE">retinut UE</option>
                    <option value="service">service</option>
                    <option value="transferuri">transferuri</option>
                    <option value="vandut">vandut</option>
                  </select>
                </div>
              </div>
              <div class="col-md-3 col-sm-12">
                <div class="form-group">
                  <label class="text-purple pl-1">
                    Numar Sasiu*
                  </label>
                  <input
                    v-model="filters.numarSasiu"
                    class="form-control mb-3"
                    type="text"
                    id="input-numar-sasiu"
                  >
                </div>
              </div>
              <div class="col-md-3 col-sm-12">
                <div class="form-group">
                  <label class="text-purple pl-1">
                    Numar inmatriculare*
                  </label>
                  <input
                    v-model="filters.licensePlate"
                    class="form-control mb-3"
                    type="text"
                    id="input-license-plate"
                  >
                </div>
              </div>
              <div class="col-md-3 col-sm-12">
                <div class="form-group">
                  <label class="text-purple pl-1">
                    Nume Sofer
                  </label>
                  <romfour-driver-autosuggest
                    :selectedValue="filters.selectedDriverName"
                    @onSelectSuggestion="onSelectDriver"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-2 col-sm-12 pl-1">
                <md-button class="btn-purple btn-block shadow purple" @click="onSearch()">
                  Cauta
                </md-button>
              </div>
            </div>
          </div>
      </md-card-content>
    </md-card>


  <md-card class="card">
    <md-card-content class="card-body rounded shadow-lg">
      <!--TABLE-->
      <listing-page :items="vehicles"
                    :columns="columns"
                    :actions="{edit: true}"
                    @onEdit="onEdit"
      >
      </listing-page>

      <!--PAGINATION-->
      <div class="md-layout">
        <div class="md-layout-item md-size-50">
          {{ vehiclesTotal }} Vehicule (de la {{ vehiclesFrom }} pana la {{ vehiclesTo }})
        </div>
        <div class="md-layout-item md-size-50">
          <rom-paginator :current-page="vehiclesCurrentPage"
                         :last-page="vehiclesLastPage"
                         :threshold="2"
                         :has-prev-next="true"
                         :has-first-last="true"
                         @onFirstPage="onFirstPage"
                         @onPreviousPage="onPreviousPage"
                         @onNextPage="onNextPage"
                         @onLastPage="onLastPage"
                         @onChangePage="onChangePage">
          </rom-paginator>
        </div>
      </div>
    </md-card-content>
  </md-card>

  </div>
</template>

<script>
  import ListingPage from '@/components/page-templates/ListingPage.vue';
  import RomPaginator from '@/components/pagination/RomPaginator.vue';
  import RomfourDriverAutosuggest from "@/components/autosuggest/RomfourDriver";
  import {
    FETCH_VEHICLES,
    FETCH_VEHICLES_CLASSES,
    FETCH_VEHICLES_TYPES,
  } from '@/store/actions.type';

  export default {
    name: 'Vehicles',
    components: {
      RomfourDriverAutosuggest,
      ListingPage,
      RomPaginator,
    },
    data: () => ({
      columns: [
        {
          name: 'codAutovehicul',
          label: 'Cod Autovehicul',
        },
        {
          name: 'numarInmatriculare',
          label: 'Numar Inmatriculare',
        },
        {
          name: 'statusAuto',
          label: 'Status',
        },
        {
          name: 'drivingStatus',
          label: 'Disponibilitate',
        },
        {
          name: 'tahografExpirationDate',
          label: 'Expirare Tahograf',
          type: 'date'
        },
        {
          name: 'numeClasaAuto',
          label: 'Clasa de autovehicule',
        },
        {
          name: 'numarSasiu',
          label: 'Numar Sasiu',
        },
        {
          name: 'marcaAutovehicul',
          label: 'Marca',
        },
        {
          name: 'modelAutovehicul',
          label: 'Model',
        },
        {
          name: 'trailerNumarInmatriculare',
          label: 'Remorca',
        },
        {
          name: 'nrLocuri',
          label: 'Nr. Locuri',
        },
        {
          name: ['mainDriverFirstName', 'mainDriverLastName'],
          label: 'Sofer principal',
          // mapItem: (item) => {
          //   const mainDriverName = item.mainDriverId ? item.mainDriverFirstName + ' ' + item.mainDriverLastName : '';
          //   const backupDriverName = item.backupDriverId ? item.backupDriverFirstName + ' ' + item.backupDriverLastName : '';
          //
          //   return  (mainDriverName + ' ' + backupDriverName).trim();
          // }
        },
        {
          name: ['backupDriverFirstName', 'backupDriverLastName'],
          label: 'Sofer Rezerva',
        },
        {
          name: ['thirdDriverFirstName', 'thirdDriverLastName'],
          label: 'Sofer Rezerva 2',
        },
      ],
      filters: {
        vehicleType: null,
        vehicleClass: null,
        vehicleStatus: 'activ',
        vehicleAvailabilityStatus: null,
        licensePlate: null,
        selectedDriverName: null,
        driverEmployeeId: null,
        numarSasiu: null,
      },
      actions: {}
    }),
    computed: {
      vehicles() {
        return this.$store.getters.vehiclesItems;
      },
      vehiclesPerPage() {
        return this.$store.getters.vehiclesPerPage;
      },
      vehiclesCurrentPage() {
        return this.$store.getters.vehiclesCurrentPage;
      },
      vehiclesLastPage() {
        return this.$store.getters.vehiclesLastPage;
      },
      vehiclesFrom() {
        return this.$store.getters.vehiclesFrom;
      },
      vehiclesTo() {
        return this.$store.getters.vehiclesTo;
      },
      vehiclesTotal() {
        return this.$store.getters.vehiclesTotal;
      },
      types() {
        return this.$store.getters.vehicleTypes;
      },
      classes() {
        return this.$store.getters.vehicleClasses;
      },
      vehiclesFilters() {
        return this.$store.getters.vehiclesFilters;
      },
    },
    mounted() {
      this.initVehicles();
    },
    methods: {
      initVehicles() {
        const params = {
          page: this.vehiclesCurrentPage,
          perPage: this.vehiclesPerPage,
          filters: this.filters,
        };
        this.fetchVehicles(params);
        this.fetchVehiclesTypes();
        this.fetchVehiclesClasses();
      },
      fetchVehicles(params) {
        this.$store.dispatch(FETCH_VEHICLES, params).then((response) => {
          this.$log.info('FETCH_VEHICLES', response);
        }).catch((error) => {
          this.$log.error('FETCH_VEHICLES', error);
        });
      },
      fetchVehiclesTypes() {
        this.$store.dispatch(FETCH_VEHICLES_TYPES).then((response) => {
          this.$log.info('FETCH_VEHICLES_TYPES', response);
        }).catch((error) => {
          this.$log.error('FETCH_VEHICLES_TYPES', error);
        });
      },
      fetchVehiclesClasses() {
        this.$store.dispatch(FETCH_VEHICLES_CLASSES).then((response) => {
          this.$log.info('FETCH_VEHICLES_CLASSES', response);
        }).catch((error) => {
          this.$log.error('FETCH_VEHICLES_CLASSES', error);
        });
      },
      onFirstPage() {
        const params = {
          page: 1,
          perPage: this.vehiclesPerPage,
          filters: this.filters,
        };
        this.fetchVehicles(params);
      },
      onPreviousPage() {
        const params = {
          page: this.vehiclesCurrentPage - 1,
          perPage: this.vehiclesPerPage,
          filters: this.filters,
        };
        this.fetchVehicles(params);
        this.fetchVehiclesTypes();
        this.fetchVehiclesClasses();
      },
      onChangePage(data) {
        const params = {
          page: data,
          perPage: this.vehiclesPerPage,
          filters: this.filters
        };
        this.fetchVehicles(params);
      },
      onNextPage() {
        const params = {
          page: this.vehiclesCurrentPage + 1,
          perPage: this.vehiclesPerPage,
          filters: this.filters,
        };
        this.fetchVehicles(params);
      },
      onLastPage() {
        const params = {
          page: this.vehiclesLastPage,
          perPage: this.vehiclesPerPage,
          filters: this.filters
        };
        this.fetchVehicles(params);
      },
      refreshList(data) {
        const params = {
          page: 1,
          perPage: data,
        };
        this.fetchVehicles(params);
      },
      onSearch(data) {
        let query = {
          page: 1,
          perPage: this.vehiclesPerPage,
          filters: this.filters
        };

        this.fetchVehicles(query);
      },
      onSelectDriver(item) {
          if (!item) {
            this.filters.selectedDriverName = '';
          }

          this.filters.driverEmployeeId = item ? item.codAng : null;
      },
      onEdit(vehicle) {
        this.$router.push({ name: 'vehicleEdit', params: { slug: vehicle.codAutovehicul } });
      },
      create() {
        this.$toasted.show('create', {
          type: 'info',
          position: 'top-right',
          duration: 5000,
          icon: 'check',
        });
      },
    },
  };
</script>
