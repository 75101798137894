// @vue/component
import ListingPage from '@/components/page-templates/ListingPage.vue';
import RomPaginator from '@/components/pagination/RomPaginator.vue';
import daysCalendar from '@/enums/calendar';
import {
  FETCH_ROUTEDEFINITIONS, FETCH_TIPURI_RUTE, FETCH_HUB_ROUTES, HUB_DELETE_SUBMIT, ROUTEDEFINITION_DELETE_SUBMIT,
} from '@/store/actions.type';
import Vue from "vue";


export default {
  name: 'Routedefinitionlistpage',
  components: {
    ListingPage,
    RomPaginator,
  },
  data: () => ({
    columns: [
      {
        name: 'id',
        label: 'Id Ruta',
      },
      {
        name: 'numeTipRuta',
        label: 'Nume Ruta',
      },
      {
        name: 'routeType',
        label: 'Tip ruta',
      },
      {
        name: 'trackType',
        label: 'Tip Cursa',
      },
      {
        name: 'transportType',
        label: 'Tipul de Transport',
      },
      {
        name: 'country_name',
        label: 'Traseu',
      },
      {
        name: 'calendar',
        label: 'Zile',
      },
      {
        name: 'deleted',
        label: 'Status',
      },
    ],
    filters: {
      id: null,
      tblTipuriRuteId: null,
      routeType: null,
      trackType: null,
      transportType: null,
    },
    tipuriRuteList: [],
    countryList: [],
    actions: {}
  }),
  computed: {
    routeDefinitions() {
      return this.$store.getters.routeDefinitionsItems;
    },
    routeDefinitionsPerPage() {
      return this.$store.getters.routeDefinitionsPerPage;
    },
    routeDefinitionsCurrentPage() {
      return this.$store.getters.routeDefinitionsCurrentPage;
    },
    routeDefinitionsLastPage() {
      return this.$store.getters.routeDefinitionsLastPage;
    },
    routeDefinitionsFrom() {
      return this.$store.getters.routeDefinitionsFrom;
    },
    routeDefinitionsTo() {
      return this.$store.getters.routeDefinitionsTo;
    },
    routeDefinitionsTotal() {
      return this.$store.getters.routeDefinitionsTotal;
    },
    routeDefinitionsFilters() {
      return this.$store.getters.routeDefinitionsFilters;
    },
  },
  mounted() {
    this.initrouteDefinitions();
    this.initTipuriRute();
    this.initHubRoutes();
  },
  methods: {
    initrouteDefinitions() {
      const params = {
        page: this.routeDefinitionsCurrentPage,
        perPage: this.routeDefinitionsPerPage,
        filters: this.filters,
      };
      this.fetchRouteDefinitions(params);
    },
    fetchRouteDefinitions(params) {
      this.$store.dispatch(FETCH_ROUTEDEFINITIONS, params).then((response) => {
        //Get the calendar values and find the name match from calendar.js enum file
        //To understand better, "eachmonday" is a value, the same value is in enum/calendar.js and has the name "In fiecare luni",
        // that name has to be saved to be displayed in list page
        response.data.forEach(function (item, index) {
          var calendar = item.calendar.split(', '); // split the calendar value
          //loop for response calendar values and calendar.js values
          for (var i = 0; i < calendar.length; i++) {
            for (var j = 0; j < daysCalendar.length; j++) {
              if (calendar[i] == daysCalendar[j].value) {
                calendar[i] = daysCalendar[j].name; // if match save it
              }
            }
          }
          //Replace calendar values with the names
          var calendarString = calendar.join(', ');
          item.calendar = calendarString;


          if(item.routeType == 'internal') {
            item.routeType = 'Intern';
            if(item.country_name == null) {
              item.country_name = item.country_code;
            }
          } else if(item.routeType == 'external') {
            item.routeType = 'Extern';
          }
          if(item.transportType == 'TR_PERS') {
            item.transportType = 'Persoane';
          } else if(item.transportType == 'TR_COL') {
            item.transportType = 'Colete';
          }
          if(item.deleted == 1) {
            item.deleted = 'Ruta Dezactivata';
          } else {
            item.deleted = 'Ruta Activa';
          }
        });

        this.$log.info('FETCH_ROUTEDEFINITIONS', response);
      }).catch((error) => {
        this.$log.error('FETCH_ROUTEDEFINITIONS', error);
      });
    },
    initHubRoutes() {
      this.$store.dispatch(FETCH_HUB_ROUTES).then((response) => {
        this.countryList = response.data;
        this.$log.info('FETCH_HUB_ROUTES', response);
      }).catch((error) => {
        this.$log.error('FETCH_HUB_ROUTES', error);
      });
    },
    initTipuriRute() {
      this.$store.dispatch(FETCH_TIPURI_RUTE).then((response) => {
        this.tipuriRuteList = response.data;
        this.$log.info('FETCH_TIPURI_RUTE', response);
      }).catch((error) => {
        this.$log.error('FETCH_TIPURI_RUTE', error);
      });
    },
    onFirstPage() {
      const params = {
        page: 1,
        perPage: this.routeDefinitionsPerPage,
        filters: this.filters,
      };
      this.fetchRouteDefinitions(params);
    },
    onPreviousPage() {
      const params = {
        page: this.routeDefinitionssCurrentPage - 1,
        perPage: this.vrouteDefinitionsPerPage,
        filters: this.filters,
      };
      this.fetchRouteDefinitions(params);
    },
    onChangePage(data) {
      const params = {
        page: data,
        perPage: this.routeDefinitionsPerPage,
        filters: this.filters
      };
      this.fetchRouteDefinitions(params);
    },
    onNextPage() {
      const params = {
        page: this.routeDefinitionsCurrentPage + 1,
        perPage: this.routeDefinitionsPerPage,
        filters: this.filters,
      };
      this.fetchRouteDefinitions(params);
    },
    onLastPage() {
      const params = {
        page: this.routeDefinitionsLastPage,
        perPage: this.routeDefinitionsPerPage,
        filters: this.filters
      };
      this.fetchRouteDefinitions(params);
    },
    refreshList(data) {
      const params = {
        page: 1,
        perPage: data,
      };
      this.fetchRouteDefinitions(params);
    },
    onSearch(data) {
      let query = {
        page: 1,
        perPage: this.routeDefinitionsPerPage,
        filters: this.filters
      };

      this.fetchRouteDefinitions(query);
    },
    goToRoute(route) {
      this.$router.push({ name: route});
    },
    onEdit(item) {
      this.$router.push({name: 'routeDefinitionEdit', params: {slug: item.id}});
    },
    onDelete(item) {
      this.$confirm('Esti sigur ca vrei sa stergi aceasta ruta?', '', 'question', {
        cancelButtonText: 'Nu',
        confirmButtonText: 'Da',
        showLoaderOnConfirm: true
      }).then((response) => {
        if (response) {
          this.isLoading = true;
          this.$store.dispatch(ROUTEDEFINITION_DELETE_SUBMIT, item.id).then((response) => {
            Vue.notify({
              group: 'success',
              type: 'success',
              title: 'Ruta a fost dezactivata cu success!',
            })
            this.$log.info('ROUTEDEFINITION_DELETE_SUBMIT', response);
            this.initrouteDefinitions();
          }).catch((error) => {
            this.$log.error('ROUTEDEFINITION_DELETE_SUBMIT', error);
          });
        }
      });
    },
    onNewRide(item) {
      this.$router.push({name: 'Createnewride', params: {slug: item.id}});
    },
  },
}
