<template>
  <div>
    <div class="row pt-5">
      <div class="col">
        <span class="mx-1"  style="color:#603084;"> <strong class="font-weight-bold" style="font-size: 25px;">Lista autovehiculelor</strong></span>
      </div>
    </div>
    <md-card class="card">
      <md-card-content class="card-body rounded shadow-lg">


          <div class="card-header text-purple">
            Cautare
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label class="text-purple pl-1">
                    Clasa
                  </label>
                  <select
                    v-model="filters.vehicleClass"
                    class="form-control"
                    id="vehcile-class-select"
                  >
                    <option value=""></option>
                    <option v-for="vehicleClass in classes" :value="vehicleClass.codClasaAuto">
                      {{ vehicleClass.numeClasaAuto }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-md">
                <div class="form-group">
                  <label class="text-purple pl-1">
                    Tip
                  </label>
                  <select
                    v-model="filters.vehicleType"
                    class="form-control"
                    id="vehicle-type-select"
                    >
                    <option v-for="vType in types" :value="vType.codTipAutovehicul">
                      {{ vType.numeTipAutovehicul }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col">
                <div class="form-group">
                  <label class="text-purple pl-1">
                    Status
                  </label>
                  <select
                    v-model="filters.vehicleStatus"
                    class="form-control"
                    id="vehicle-status-select"
                  >
                    <option value=""></option>
                    <option value="activ">Activ</option>
                    <option value="inactiv">Inactiv</option>
                  </select>
                </div>
              </div>

              <div class="col">
                <div class="form-group">
                  <label class="text-purple pl-1">
                    Disponibilitate
                  </label>
                  <select
                    v-model="filters.vehicleAvailabilityStatus"
                    class="form-control"
                    id="vehicle-av-status-select"
                  >
                    <option value=""></option>
                    <option value="acte oprite">acte oprite</option>
                    <option value="activa/ruleaza">activa/ruleaza</option>
                    <option value="avariata">avariata</option>
                    <option value="defect">defect</option>
                    <option value="disparuta">disparuta</option>
                    <option value="donata">donata</option>
                    <option value="furata">furata</option>
                    <option value="la casat">la casat</option>
                    <option value="la radiat">la radiat</option>
                    <option value="la vanzare">la vanzare</option>
                    <option value="lasata in parcare">lasata in parcare</option>
                    <option value="libera">libera</option>
                    <option value="lipsa valabilitate acte">lipsa valabilitate acte</option>
                    <option value="parc">parc</option>
                    <option value="retinut UE">retinut UE</option>
                    <option value="service">service</option>
                    <option value="transferuri">transferuri</option>
                    <option value="vandut">vandut</option>
                  </select>
                </div>
              </div>

              <div class="col">
                <div class="form-group">
                  <label class="text-purple pl-1">
                    Numar inmatriculare*
                  </label>
                  <input
                    v-model="filters.licensePlate"
                    class="form-control mb-3"
                    type="text"
                    id="input-license-plate"
                  >
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-2">
                <md-button class="btn-purple btn-block shadow purple" @click="onSearch()">
                  Cauta
                </md-button>
              </div>
            </div>
          </div>
      </md-card-content>
    </md-card>


  <md-card class="card">
    <md-card-content class="card-body rounded shadow-lg">
      <!--TABLE-->
      <div class="listing-page">
        <table class="table table-responsive-lg table-striped table-hover t-vehicles" style="table-layout: fixed; width: 100%;">
          <!--<table class="table table-bordered table-responsive-lg table-striped text-nowrap">-->
          <thead class="bg--white">
          <tr>
            <th
              v-for="column in columns"
              class="text-purple align-top text-center"
              style="word-wrap: normal"
            >
              {{ column.label }}
            </th>
          </tr>
          </thead>
          <tbody class="text-center" id="tbody">
          <tr v-for="item in vehicles">
            <td
              @click="onSelect($event, item)"
              v-for="column in columns"
              class="align-top text-center"
              style="word-wrap: normal"
            >
              {{ getItemProperty(item, column.name) }}
            </td>
          </tr>
          </tbody>
        </table>
      </div>


      <!--PAGINATION-->
      <div class="md-layout">
        <div class="md-layout-item md-size-50">
          {{ vehiclesTotal }} Vehicule (de la {{ vehiclesFrom }} pana la {{ vehiclesTo }})
        </div>
        <div class="md-layout-item md-size-50">
          <rom-paginator :current-page="vehiclesCurrentPage"
                         :last-page="vehiclesLastPage"
                         :threshold="2"
                         :has-prev-next="true"
                         :has-first-last="true"
                         @onFirstPage="onFirstPage"
                         @onPreviousPage="onPreviousPage"
                         @onNextPage="onNextPage"
                         @onLastPage="onLastPage"
                         @onChangePage="onChangePage">
          </rom-paginator>
        </div>
      </div>
    </md-card-content>
  </md-card>

  </div>
</template>

<script>
  import ListingPage from '@/components/page-templates/ListingPage.vue';
  import RomPaginator from '@/components/pagination/RomPaginator.vue';
  import {
    FETCH_VEHICLES,
    FETCH_VEHICLES_CLASSES,
    FETCH_VEHICLES_TYPES,
  } from '@/store/actions.type';

  export default {
    name: 'SelectVehicles',
    components: {
      ListingPage,
      RomPaginator,
    },
    data: () => ({
      columns: [
        {
          name: 'codAutovehicul',
          label: 'Cod Autovehicul',
        },
        {
          name: 'numarInmatriculare',
          label: 'Numar Inmatriculare',
        },
        {
          name: 'statusAuto',
          label: 'Status',
        },
        {
          name: 'drivingStatus',
          label: 'Disponibilitate',
        },
        {
          name: 'tahografExpirationDate',
          label: 'Expirare Tahograf',
          type: 'date'
        },
        {
          name: 'numeClasaAuto',
          label: 'Clasa de autovehicule',
        },
        {
          name: 'nrLocuri',
          label: 'Nr. Locuri',
        },
        {
          name: 'capacitateCilindrica',
          label: 'Capacitate Cilindrica',
        },
        {
          name: 'sarcinaMaxima',
          label: 'Sarcina Maxima',
        },
      ],
      filters: {
        vehicleType: null,
        vehicleClass: null,
        vehicleStatus: 'activ',
        vehicleAvailabilityStatus: null,
        licensePlate: null,
      },
      actions: {}
    }),
    computed: {
      vehicles() {
        return this.$store.getters.vehiclesItems;
      },
      vehiclesPerPage() {
        return this.$store.getters.vehiclesPerPage;
      },
      vehiclesCurrentPage() {
        return this.$store.getters.vehiclesCurrentPage;
      },
      vehiclesLastPage() {
        return this.$store.getters.vehiclesLastPage;
      },
      vehiclesFrom() {
        return this.$store.getters.vehiclesFrom;
      },
      vehiclesTo() {
        return this.$store.getters.vehiclesTo;
      },
      vehiclesTotal() {
        return this.$store.getters.vehiclesTotal;
      },
      types() {
        return this.$store.getters.vehicleTypes;
      },
      classes() {
        return this.$store.getters.vehicleClasses;
      },
      vehiclesFilters() {
        return this.$store.getters.vehiclesFilters;
      },
    },
    mounted() {
      this.initVehicles();
    },
    methods: {
      getClass(item) {
        if (item.enabled === 0) return 'page-enabled';
        if (item.enabled === 1) return 'page-disabled';
        return '';
      },
      getItemProperty(item, property) {
        const properties = property.split('.');
        while(properties.length && (item = item[properties.shift()]));
        return item;
      },
      onSelect(event, item) {
        this.selected = item;
        this.$emit('onSelectVehicle', item);
      },
      isNumeric(item, property) {
        return !isNaN(this.getItemProperty(item, property));
      },
      initVehicles() {
        const params = {
          page: this.vehiclesCurrentPage,
          perPage: this.vehiclesPerPage,
          filters: this.filters
        };
        this.fetchVehicles(params);
        this.fetchVehiclesTypes();
        this.fetchVehiclesClasses();
      },
      fetchVehicles(params) {
        this.$store.dispatch(FETCH_VEHICLES, params).then((response) => {
          this.$log.info('FETCH_VEHICLES', response);
        }).catch((error) => {
          this.$log.error('FETCH_VEHICLES', error);
        });
      },
      fetchVehiclesTypes() {
        this.$store.dispatch(FETCH_VEHICLES_TYPES).then((response) => {
          this.$log.info('FETCH_VEHICLES_TYPES', response);
        }).catch((error) => {
          this.$log.error('FETCH_VEHICLES_TYPES', error);
        });
      },
      fetchVehiclesClasses() {
        this.$store.dispatch(FETCH_VEHICLES_CLASSES).then((response) => {
          this.$log.info('FETCH_VEHICLES_CLASSES', response);
        }).catch((error) => {
          this.$log.error('FETCH_VEHICLES_CLASSES', error);
        });
      },
      onFirstPage() {
        const params = {
          page: 1,
          perPage: this.vehiclesPerPage,
          filters: this.filters,
        };
        this.fetchVehicles(params);
      },
      onPreviousPage() {
        const params = {
          page: this.vehiclesCurrentPage - 1,
          perPage: this.vehiclesPerPage,
          filters: this.filters,
        };
        this.fetchVehicles(params);
        this.fetchVehiclesTypes();
        this.fetchVehiclesClasses();
      },
      onChangePage(data) {
        const params = {
          page: data,
          perPage: this.vehiclesPerPage,
          filters: this.filters
        };
        this.fetchVehicles(params);
      },
      onNextPage() {
        const params = {
          page: this.vehiclesCurrentPage + 1,
          perPage: this.vehiclesPerPage,
          filters: this.filters,
        };
        this.fetchVehicles(params);
      },
      onLastPage() {
        const params = {
          page: this.vehiclesLastPage,
          perPage: this.vehiclesPerPage,
          filters: this.filters
        };
        this.fetchVehicles(params);
      },
      refreshList(data) {
        const params = {
          page: 1,
          perPage: data,
        };
        this.fetchVehicles(params);
      },
      onSearch(data) {
        let query = {
          page: 1,
          perPage: this.vehiclesPerPage,
          filters: this.filters
        };

        this.fetchVehicles(query);
      },

      create() {
        this.$toasted.show('create', {
          type: 'info',
          position: 'top-right',
          duration: 5000,
          icon: 'check',
        });
      },
    },
  };
</script>
