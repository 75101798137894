const days = [
  { name: 'In fiecare Luni', value: 'eachmonday' },
  { name: 'In fiecare Marti', value: 'eachtuesday' },
  { name: 'In fiecare Miercuri', value: 'eachwednesday' },
  { name: 'In fiecare Joi', value: 'eachthursday' },
  { name: 'In fiecare Vineri', value: 'eachfriday' },
  { name: 'In fiecare Sambata', value: 'eachsaturday' },
  { name: 'In fiecare Duminica', value: 'eachsunday' },
  { name: 'Luni', value: 'monday' },
  { name: 'Marti', value: 'tuesday' },
  { name: 'Miercuri', value: 'wednesday' },
  { name: 'Joi', value: 'thursday' },
  { name: 'Vineri', value: 'friday' },
  { name: 'Sambata', value: 'saturday' },
  { name: 'Duminica', value: 'sunday' },
]

export default days;
