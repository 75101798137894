/* eslint-disable import/no-cycle */
import ApiService from '@/services/api.service';

export default {
  downloadFile(fileId, filename) {
    // return `${API_URL}/files/${fileId}/preview`;
    // return `${API_URL}/files/${fileId}`;
    return ApiService.downloadFile(`/files/${fileId}`, filename);
  },
  getFileContent(fileId) {
    return ApiService.getFileContent(`/files/${fileId}`);
  },
  getFilePreviewContent(fileId) {
    return ApiService.getFileContent(`/files/${fileId}/preview`);
  },
};
