<template>
  <form novalidate>
    <h1 class="text-purple pl-1">Documente</h1>
    <div class="row">

      <div class="col-xs-12 col-md-6">
        <div class="row">
<!--          <div class="col-xs-12 col-md-4">-->
<!--            <div class="card rounded card-custom shadow-lg">-->
<!--              <div class="card-header text-purple">-->
<!--                <strong class="pl-1">Incarca Document</strong>-->
<!--              </div>-->
<!--              <div class="card-body">-->
<!--                <div class="form-group">-->
<!--                  <select id="docType"-->
<!--                          class="form-control select2-hidden-accessible select2-dropdown d-block"-->
<!--                          tabindex="-1"-->
<!--                          aria-hidden="true"-->
<!--                          v-model="formData.documentType"-->
<!--                  >-->
<!--                    <option hidden disabled selected>Alege optiune</option>-->
<!--                    <option value="carte-de-identitate">Carte de identitate</option>-->
<!--                    <option value="permis">Permis</option>-->
<!--                    <option value="aviz-medical">Aviz Medical</option>-->
<!--                    <option value="aviz-psihologic">Aviz Psihologic</option>-->
<!--                    <option value="atesta-marfuri-periculoase">Atestat transport marfuri periculoase</option>-->
<!--                    <option value="atestat-tip-marfa">Atestat transport marfa</option>-->
<!--                    <option value="atestat-tip-persoane">Atestat transport persoane</option>-->
<!--                    <option value="card-tahograf">Card Tahograf</option>-->
<!--                    <option value="contract-de-munca">Contract de munca</option>-->
<!--                    <option value="cazier-judiciar">Cazier Judiciar</option>-->
<!--                  </select>-->
<!--                </div>-->
<!--                <div class="form-group" v-if="formData.documentType != 'contract-de-munca'">-->
<!--                  <label class="text-purple pl-1">-->
<!--                    Data expirarii-->
<!--                  </label>-->
<!--                  <datepicker :clear-button="true" :bootstrap-styling="true" input-class="form-control" v-model="formData.expirationDate" name="docExpirationDate"></datepicker>-->
<!--                </div>-->
<!--                <div class="form-group">-->
<!--                  <label class="text-purple pl-1">-->
<!--                    Upload Document-->
<!--                  </label>-->
<!--                  <div class="custom-file">-->
<!--                    <input type="file" class="custom-file-input" id="doc-file" ref="docFile"  v-on:change="onFileChange($event)"/>-->
<!--                    <label class="custom-file-label" for="doc-file">-->
<!--                      <span v-if="formData.file">{{formData.file.name}}</span>-->
<!--                      <span v-if="!formData.file">Incarca fisier</span>-->
<!--                    </label>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->

          <div class="col-xs-12 col-md-4">
            <!-- CI -->
            <div class="card rounded shadow-lg">
              <div class="card-header text-purple">
                <strong class="pl-1">CI</strong>
              </div>
              <div class="card-body">
                <rfr-file-previewer
                  title="CI"
                  id="ci"
                  :file="documents.ci.file"
                  :expirationDate="documents.ci.expirationDate"
                  v-if="documents.ci.file"
                />
                <div class="col" v-if="!documents.ci.file">Documentul nu a fost incarcat</div>

              </div>
            </div>
          </div>
          <div class="col-xs-12 col-md-4">
            <!-- Permis -->
            <div class="card rounded shadow-lg">
              <div class="card-header text-purple">
                <strong class="pl-1">Permis</strong>
              </div>
              <div class="card-body">
                <rfr-file-previewer
                  title="Permis"
                  id="drivingLicence"
                  :file="documents.drivingLicence.file"
                  v-if="documents.drivingLicence.file"
                  :expirationDate="documents.drivingLicence.expirationDate"
                />
                <div class="col" v-if="!documents.drivingLicence.file">Documentul nu a fost incarcat</div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
            <!-- Aviz psihologic si medical-->
            <div class="card card-custom rounded shadow-lg">
              <div class="card-body">
                <div class="row">
                  <div class="col-xs-12 col-md-6">
                    <div class="form-group card-header text-purple">
                      <strong>Aviz medical</strong>
                    </div>
                    <rfr-file-previewer
                      title="Aviz medical"
                      id="medicalCertificate"
                      :file="documents.medicalCertificate.file"
                      v-if="documents.medicalCertificate.file"
                      :expirationDate="documents.medicalCertificate.expirationDate"
                    />
                    <div class="col" v-if="!documents.medicalCertificate.file">Documentul nu a fost incarcat</div>

                  </div>
                  <div class="col-xs-12 col-md-6">
                    <div class="form-group  card-header text-purple">
                      <strong>Aviz psihologic</strong>
                    </div>
                    <rfr-file-previewer
                      title="Aviz psihologic"
                      id="psihologicalCertificate"
                      :file="documents.psihologicalCertificate.file"
                      v-if="documents.psihologicalCertificate.file"
                      :expirationDate="documents.psihologicalCertificate.expirationDate"
                    />
                    <div class="col" v-if="!documents.psihologicalCertificate.file">Documentul nu a fost incarcat</div>
                  </div>
                </div>
              </div>
            </div>
        </div>
        <div class="row">
          <div class="col-xs-12 col-md-6">
            <div class="card rounded shadow-lg">
              <div class="card-header text-purple">
                <strong class="pl-1">Contract de munca</strong>
              </div>
              <div class="card-body">
                <rfr-file-previewer
                  title="Contract"
                  id="contractDeMunca"
                  :file="documents.contractDeMunca.file"
                  v-if="documents.contractDeMunca.file"
                />
                <div class="col" v-if="!documents.contractDeMunca.file">Documentul nu a fost incarcat</div>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-md-6">
            <!-- CI -->
            <div class="card rounded shadow-lg">
              <div class="card-header text-purple">
                <strong class="pl-1">Card Tahograf</strong>
              </div>
              <div class="card-body">
                <rfr-file-previewer
                  title="Card Tahograf"
                  id="cardTahograf"
                  :file="documents.cardTahograf.file"
                  :expirationDate="documents.cardTahograf.expirationDate"
                  v-if="documents.cardTahograf.file"
                />
                <div class="col" v-if="!documents.cardTahograf.file">Documentul nu a fost incarcat</div>
              </div>
            </div>
          </div>

        </div>
        <div class="row">
        </div>
      </div>
      <!-- Atestate -->
      <div class="col-xs-12 col-md-4">
        <div class="card rounded shadow-lg">
          <div class="card-header text-purple">
            <strong class="pl-1">Atestate</strong>
          </div>
          <div class="card-body">
            <div class="form-group">
              <label class="card-header text-purple d-block">Atestat tip marfa
              </label>
            </div>
            <rfr-file-previewer
              title="Atestat transport marfa"
              id="goodsTransportCertificate"
              :file="documents.goodsTransportCertificate.file"
              v-if="documents.goodsTransportCertificate.file"
              :expirationDate="documents.goodsTransportCertificate.expirationDate"
            />
            <div class="col" v-if="!documents.goodsTransportCertificate.file">Documentul nu a fost incarcat</div>

            <div class="form-group flex-column flex">
              <label class="card-header text-purple d-block">Atestat persoane
              </label>
            </div>

            <rfr-file-previewer
              title="Atestat persoane"
              id="peopleTransportCertificate"
              :file="documents.peopleTransportCertificate.file"
              :expirationDate="documents.peopleTransportCertificate.expirationDate"
              v-if="documents.peopleTransportCertificate.file"
            />
            <div class="col" v-if="!documents.peopleTransportCertificate.file">Documentul nu a fost incarcat</div>

            <div class="form-group flex-column flex custom-height">
              <label class="card-header text-purple d-block">Atestat marfuri periculoase
              </label>
            </div>

            <rfr-file-previewer
              title="Atestat marfuri periculoase"
              id="dangerousGoodsTransportCertificate"
              :file="documents.dangerousGoodsTransportCertificate.file"
              :expirationDate="documents.dangerousGoodsTransportCertificate.expirationDate"
              v-if="documents.dangerousGoodsTransportCertificate.file"
            />
            <div class="col" v-if="!documents.goodsTransportCertificate.file">Documentul nu a fost incarcat</div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
  import { FETCH_DRIVER, DRIVER_UPLOAD_DOCUMENT } from '@/store/actions.type';
  import Datepicker from 'vuejs-datepicker';
  import RfrFilePreviewer from "../../components/fields/RfrFilePreviewer";


  export default {
    name: 'DriverEditPage',
    components: {
      RfrFilePreviewer,
      Datepicker,
    },
    props: {
      slug: Number|String,
    },
    data: () => ({
      formData: {
        file: null,
        documentType: null,
        expirationDate: null,
      },
      documents: {
        ci: {
          expirationDate: null,
          file: null
        },
        drivingLicence: {
          expirationDate: null,
          file: null
        },
        peopleTransportCertificate: {
          expirationDate: null,
          file: null
        },
        goodsTransportCertificate: {
          expirationDate: null,
          file: null
        },
        dangerousGoodsTransportCertificate: {
          expirationDate: null,
          file: null,
          hasCertificate: 'nu'
        },
        medicalCertificate: {
          expirationDate: null,
          file: null
        },
        psihologicalCertificate: {
          expirationDate: null,
          file: null
        },
        cardTahograf: {
          expirationDate: null,
          file: null
        },
        contractDeMunca: {
          file: null
        }
      },

    }),
    computed: {
      newDriverForm() {
        return this.$store.getters.newDriverForm;
      },
    },
    mounted() {
      this.initDriver();
    },
    methods: {
      initDriver() {
        this.$store.dispatch(FETCH_DRIVER, this.slug).then((response) => {
          response = response.data;
          const docs = response.docs || {};

          this.documents = {
              ci: {
                cnp: response.employee.cnp,
                expirationDate: docs['carte-de-identitate'] ? docs['carte-de-identitate'].expirationDate : null,
                series: response.employee.ciSerie,
                number: response.employee.ciNumar,
                file: docs['carte-de-identitate']? docs['carte-de-identitate'].file : null
              },
              drivingLicence: {
                type:  response.driver? response.driver.drivingLicenceType : null,
                expirationDate: docs['permis'] ? docs['permis'].expirationDate : null,
                file:  docs['permis'] ? docs['permis'].file : null
              },
              peopleTransportCertificate: {
                expirationDate: docs['atestat-tip-persoane'] ? docs['atestat-tip-persoane'].expirationDate : null,
                file: docs['atestat-tip-persoane'] ? docs['atestat-tip-persoane'].file : null
              },
              contractDeMunca: {
                expirationDate: null,
                file: docs['contract-munca'] ? docs['contract-munca'].file : null
              },
              goodsTransportCertificate: {
                expirationDate: docs['atestat-tip-marfa'] ? docs['atestat-tip-marfa'].expirationDate : null,
                file: docs['atestat-tip-marfa'] ? docs['atestat-tip-marfa'].file : null
              },
              dangerousGoodsTransportCertificate: {
                expirationDate: docs['atestat-marfuri-periculoase'] ? docs['atestat-marfuri-periculoase'].expirationDate : null,
                file: docs['atestat-marfuri-periculoase'] ? docs['atestat-marfuri-periculoase'].file : null,
              },
              medicalCertificate: {
                expirationDate: docs['aviz-medical'] ? docs['aviz-medical'].expirationDate : null,
                file: docs['aviz-medical'] ? docs['aviz-medical'].file : null
              },
              psihologicalCertificate: {
                expirationDate: docs['aviz-psihologic'] ? docs['aviz-psihologic'].expirationDate : null,
                file: docs['aviz-psihologic'] ? docs['aviz-psihologic'].file : null
              },
              cardTahograf: {
                expirationDate: docs['card-tahograf'] ? docs['card-tahograf'].expirationDate : null,
                series: docs['card-tahograf'] ? docs['card-tahograf'].seriesNumber : null,
                downloadedPeriod: docs['card-tahograf'] && docs['card-tahograf']['extraInfo'] ?
                  JSON.parse(docs['card-tahograf']['extraInfo']).period : null,
                file: docs['card-tahograf'] ? docs['card-tahograf'].file : null,
              },
            };
          this.$log.info('FETCH_DRIVER', response);
        }).catch((error) => {
          this.$log.error('FETCH_DRIVER', error);
        });
      },

      goToRoute(route) {
        this.$router.push({ name: route});
      },
      isImage(filename) {
        return filename.match(/.(jpg|jpeg|png|gif)$/i);
      },
      isPdf(filename) {
        return filename.match(/.(pdf)$/i);
      },
      onFileChange(e) {
        var files = e.target.files || e.dataTransfer.files;
        if (!files.length)
          return;
        this.formData.file = files[0];
      },
      onSubmitDocument(e) {
        let formData = new FormData();
        formData.append('data', JSON.stringify(this.formData));
        formData.append('file', this.formData.file);

        this.$store.dispatch(DRIVER_UPLOAD_DOCUMENT, {id: this.slug, formData}).then((response) => {
          this.$log.info('DRIVER_UPLOAD_DOCUMENT', response);
          this.initDriver();
        }).catch((error) => {
          this.$log.error('DRIVER_UPLOAD_DOCUMENT', error);
        });
      },
    },
    watch: {
      slug: {
        handler(val) {
          this.initPageData(val);
        },
      },
    },
  };
</script>
