// @vue/component
import ListingPage from '@/components/page-templates/ListingPage.vue';
import RomPaginator from '@/components/pagination/RomPaginator.vue';
import daysCalendar from '@/enums/calendar';
import Datepicker from 'vuejs-datepicker';
import {
  CRUISE_RESERVATIONS_DELETE_SUBMIT,
  FETCH_CRUISES,CRUISE_DELETE
} from '@/store/actions.type';
import Vue from "vue";
import moment from "moment";


export default {
  name: 'Cruiseplanninglist',
  components: {
    ListingPage,
    RomPaginator,
    Datepicker,
  },
  data: () => ({
    columns: [
      {
        name: 'id',
        label: 'Id Cursa',
      },
      {
        name: 'identification_code',
        label: 'Cod Identificare',
      },
      {
        name: 'routeType',
        label: 'Tip ruta',
      },
      {
        name: 'trackType',
        label: 'Tip Cursa',
      },
      {
        name: 'transportType',
        label: 'Tipul de Transport',
      },
      {
        name: 'routeTypeName',
        label: 'Traseu ruta',
      },
      {
        name: 'day',
        label: 'Ziua',
      },
      {
        name: 'date',
        label: 'Data',
        type: 'date'
      },
      {
        name: 'status',
        label: 'Status',
      },
    ],
    filters: {
      id: null,
      tblTipuriRuteId: null,
      routeType: null,
      trackType: null,
      transportType: null,
      codIdentificare: null,
      date:null,
    },
    tipuriRuteList: [],
    countryList: [],
    actions: {},
    currentSort: {column: 'date', dir: 'desc'},
  }),
  computed: {
    cruises() {
      return this.$store.getters.cruisesItems;
    },
    cruisesPerPage() {
      return this.$store.getters.cruisesPerPage;
    },
    cruisesCurrentPage() {
      return this.$store.getters.cruisesCurrentPage;
    },
    cruisesLastPage() {
      return this.$store.getters.cruisesLastPage;
    },
    cruisesFrom() {
      return this.$store.getters.cruisesFrom;
    },
    cruisesTo() {
      return this.$store.getters.cruisesTo;
    },
    cruisesTotal() {
      return this.$store.getters.cruisesTotal;
    },
    cruisesFilters() {
      return this.$store.getters.cruisesFilters;
    },
  },
  mounted() {
    this.initcruises();

  },
  methods: {
    initcruises() {
      const params = {
        page: this.cruisesCurrentPage,
        perPage: this.cruisesPerPage,
        filters: this.filters,
        sort: this.currentSort,
      };
      this.fetchcruises(params);
    },
    fetchcruises(params) {
      this.$store.dispatch(FETCH_CRUISES, params).then((response) => {
        response.data.forEach(function (item, index) {
          if (item.routeType == 'internal') {
            item.routeType = 'Intern';
            if (item.country_name == null) {
              item.country_name = item.country_code;
            }
          } else if (item.routeType == 'external') {
            item.routeType = 'Extern';
          }
          if (item.transportType == 'TR_PERS') {
            item.transportType = 'Persoane';
          } else if (item.transportType == 'TR_COL') {
            item.transportType = 'Colete';
          }
          if (item.status == 'empty') {
            item.status = 'Neplanificata';
          } else if (item.status == 'inProgress') {
            item.status = 'In curs de planificare';
          } else if (item.status == '25') {
            item.status = '25% Planificata';
          }  else if (item.status == '50') {
            item.status = '50% Planificata';
          }  else if (item.status == '75') {
            item.status = '75% Planificata';
          }  else if (item.status == 'completed') {
            item.status = 'Planificata';
          }
          //Display the day name
          moment.locale('Ro')
          let cruiseDayName = moment(item.date).format('dddd');
          item.day = cruiseDayName.toUpperCase();

          item.routeTypeName = item.numeTipRuta;
        });
        this.$log.info('FETCH_CRUISES', response);
      }).catch((error) => {
        this.$log.error('FETCH_CRUISES', error);
      });
    },

    onFirstPage() {
      const params = {
        page: 1,
        perPage: this.cruisesPerPage,
        filters: this.filters,
      };
      this.fetchcruises(params);
    },
    onPreviousPage() {
      const params = {
        page: this.cruisesCurrentPage - 1,
        perPage: this.cruisesPerPage,
        filters: this.filters,
      };
      this.fetchcruises(params);
    },
    onChangePage(data) {
      const params = {
        page: data,
        perPage: this.cruisesPerPage,
        filters: this.filters
      };
      this.fetchcruises(params);
    },
    onNextPage() {
      const params = {
        page: this.cruisesCurrentPage + 1,
        perPage: this.cruisesPerPage,
        filters: this.filters,
      };
      this.fetchcruises(params);
    },
    onLastPage() {
      const params = {
        page: this.cruisesLastPage,
        perPage: this.cruisesPerPage,
        filters: this.filters
      };
      this.fetchcruises(params);
    },
    refreshList(data) {
      const params = {
        page: 1,
        perPage: data,
      };
      this.fetchcruises(params);
    },
    onSearch(data) {
      let query = {
        page: 1,
        perPage: this.cruisesPerPage,
        filters: this.filters,
        sort: this.currentSort,
      };

      this.fetchcruises(query);
    },
    goToRoute(route) {
      this.$router.push({name: route});
    },
    onEdit(item) {
      this.$router.push({name: 'routeDefinitionEdit', params: {slug: item.id}});
    },
    onCruisePlanning(item) {
      this.$router.push({name: 'cruisePlanning', params: {slug: item.id, routeTypeId: item.routeTypeId, identificationCode: item.identification_code, cruiseDate: item.date, cruiseDay: item.day, status:item.status}});
    },
    onCruisePlanningEdit(item) {
      this.$router.push({name: 'cruisePlanningEdit', params: {slug: item.id, routeTypeId: item.routeTypeId, identificationCode: item.identification_code, cruiseDate: item.date, cruiseDay: item.day}});
    },
    onCruisePlanningView(item) {
      this.$router.push({name: 'cruisePlanningView', params: {slug: item.id, routeTypeId: item.routeTypeId, identificationCode: item.identification_code, cruiseDate: item.date, cruiseDay: item.day}});
    },
    onCruisePlanningDelete(item) {
      this.$store.dispatch(CRUISE_DELETE, item.id).then((response) => {
        Vue.notify({
          group: 'success',
          type: 'success',
          title: 'Planificarea a fost stearsa',
        })
        this.initcruises();
        this.$log.info('CRUISE_DELETE', response);
      }).catch((error) => {
        this.$log.error('CRUISE_DELETE', error);
      });
    }
  },
}
