import RomfourVehicleAutosuggest from "@/components/autosuggest/RomfourVehiclesCruisePlanning";
import CruisePlanningEditStep4 from "@/views/CruisePlanningEdit/CruisePlanningEditStep4/CruisePlanningEditStep4.vue";
import {
  CRUISE_RESERVATIONS_MAIN_VEHICLE_SUBMIT,
  CRUISE_RESERVATIONS_TRANSFER_VEHICLE_SUBMIT,
  CRUISE_RESERVATIONS_DELETE_SUBMIT,
  CRUISE_RESERVATIONS_MAIN_TRAILER_VEHICLE_SUBMIT,
  CRUISE_RESERVATIONS_MAIN_BACKUP_VEHICLE_SUBMIT, FETCH_VEHICLE, CRUISE_RESERVATIONS_EDIT
} from "@/store/actions.type";
import Vue from "vue";
import {required} from "vuelidate/lib/validators";

export default {
  name: 'Cruiseplanningeditstep3',

  components: {
    RomfourVehicleAutosuggest,
    CruisePlanningEditStep4
  },

  mixins: [],

  props: {
    slug: Number | String,
    reservations: Object | Array,
    savedCruiseId: Number,
    formDataStep1: Object,
    formDataStep2: Object,
    routeId: Number | String,
    routeTypeId: Number | String,
  },

  data: () => ({
    show: {
      createRoute: false,
      selectReservation: false,
      createRoutesStep2: false,
      createRoutesStep3: true,
    },
    selectedMainVehicles: {},
    selectedTrailers: {},
    selectMainBackupVehicles: {},
    selectedTransferVehicles: [],
    selectedVehicle: null,
    selectedVehicleArr: [],
    cruiseId: null,
    submitStatus: false,
    type: 'trailer',
  }),
  mounted() {
    this.initSelectedVehicles();
  },

  methods: {
    //Display vehicles for each country
    initSelectedVehicles() {
      const keys = Object.keys(this.reservations);
      for (let key of keys) {
        this.selectedMainVehicles[key] = {};
        this.selectMainBackupVehicles[key] = {};
        this.selectedTrailers[key] = {};
      }
      let mainVehicleR = {};
      this.selectedTransferVehicles = {};
      let store = this.$store;
      let log = this.$log;
      //Get the number plate of the cars for each country and display it on input
      _.each(this.reservations, function (value, key) {
        value.forEach(reservation => {
          if (reservation.mainVehicleId !== null) {
            mainVehicleR[key] = "" + reservation.mainVehicle + "";
          }
        })
      });
      this.selectedMainVehicles = mainVehicleR;
      const ReservationValues = Object.values(this.reservations);
      ReservationValues.forEach(reservations => {
        reservations.forEach(reservation => {
          this.selectedTransferVehicles[reservation.id] = reservation.transferVehicle;
        })
      })
    },
    //Change the template for each step
    changeDisplay(createRoute, reservation, createRouteStep2, createRouteStep3, createRouteStep4) {
      this.show = {
        createRoute: createRoute,
        selectReservation: reservation,
        createRoutesStep2: createRouteStep2,
        createRoutesStep3: createRouteStep3,
        createRoutesStep4: createRouteStep4,
      }
    },
    //Get the current step
    onSelectSteps(item) {
      this.selectedReservations = item;
      this.show = {
        createRoute: false,
        createRouteStep2: false,
        createRouteStep3: true,
        createRoutesStep4: false,
      }
    },
    //Deletes the saved reservation when the step back from step 3 is clicked
    onSelectPreviousStep() {
      this.cruiseId = this.savedCruiseId;
      this.$emit('onSelectSteps', false, false, true, false);
    },
    //Save the main vehicle for each country
    selectMainVehicle(item) {
      this.selectedMainVehicles[item.country] = {
        'vehicle': item.numarInmatriculare,
      };
      this.selectedVehicleArr[item.country] = item ? item.codAutovehicul : null;
      this.$store.dispatch(CRUISE_RESERVATIONS_MAIN_VEHICLE_SUBMIT, item).then((response) => {
        Vue.notify({
          group: 'success',
          type: 'success',
          title: 'Autovehiculul Principal a fost salvat!',
        })
        this.$log.info('CRUISE_RESERVATIONS_MAIN_VEHICLE_SUBMIT', response);
      }).catch((error) => {
        this.$log.error('CRUISE_RESERVATIONS_MAIN_VEHICLE_SUBMIT', error);
      });
    },
    //Save the transfer vehicle on each reservation
    selectTransferVehicle(item) {
      this.selectedVehicleArr[item.country] = item ? item.codAutovehicul : null;
      this.$store.dispatch(CRUISE_RESERVATIONS_TRANSFER_VEHICLE_SUBMIT, item).then((response) => {
        Vue.notify({
          group: 'success',
          type: 'success',
          title: 'Autovehiculul de transfer a fost salvat!',
        })
        this.$log.info('CRUISE_RESERVATIONS_TRANSFER_VEHICLE_SUBMIT', response);
      }).catch((error) => {
        this.$log.error('CRUISE_RESERVATIONS_TRANSFER_VEHICLE_SUBMIT', error);
      });
    },
    onSubmitCruiseReservationDefinitionCar(e) {
      this.submitStatus = false;
      Object.entries(this.selectedMainVehicles).forEach(mainVehicle => {
        if (Object.keys(mainVehicle[1]).length === 0) {
          this.submitStatus = true;
        }
      });
      if (this.submitStatus === false) {
        this.checkIfReservationHasMainVehicle(this.routeId);
        this.changeDisplay(false, false, false, false, true);
      }
    },
    checkIfReservationHasMainVehicle(routeId) {
      let mainVehicle = [];
      let reservationsCopy = this.reservations;
      _.each(this.reservations, function (value, key) {
        value.forEach(reservation => {
          if (reservation.mainVehicleId !== null) {
            mainVehicle[key] = reservation.mainVehicleId;
          } else {
            reservationsCopy[key]['mainVehicleId'] = mainVehicle[key];
          }
        })
      });
      _.each(reservationsCopy, function (value, key) {
        if (value.mainVehicleId) {
          value.forEach(reservation => {
            if (reservation.mainVehicleId == null) {
              reservation.mainVehicleId = value.mainVehicleId;
              reservation.cruiseId = routeId;
            }
          })
        }
      });
      this.$store.dispatch(CRUISE_RESERVATIONS_EDIT, reservationsCopy).then((response) => {
        Vue.notify({
          group: 'success',
          type: 'success',
          title: 'Rezervarea a fost editata!',
        })
        this.$log.info('CRUISE_RESERVATIONS_EDIT', response);
      }).catch((error) => {
        this.$log.error('CRUISE_RESERVATIONS_EDIT', error);
      });
    }
  },
  watch: {
    slug: {
      handler(val) {
        this.initPageData(val);
      },
    },
  },
}
