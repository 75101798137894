import Datepicker from 'vuejs-datepicker';
import {required, maxLength} from 'vuelidate/lib/validators'
import Multiselect from 'vue-multiselect'
import {FETCH_HUB_ROUTES, FETCH_ROUTEDEFINITION, FETCH_TIPURI_RUTE, ROUTEDEFINITION_EDIT_SUBMIT,} from '@/store/actions.type';
import daysCalendar from '@/enums/calendar';
import { VueAutosuggest } from 'vue-autosuggest'
import RomfourLocalityAutosuggest from "@/components/autosuggest/RomfourInternalLocality";
import Vue from "vue";

export default {

  name: 'RouteDefinitionEditPage',
  components: {
    Datepicker,
    Multiselect,
    RomfourLocalityAutosuggest,
    VueAutosuggest
  },
  props: {
    slug: Number | String,
  },
  data: () => ({
    formData: {
      id: null,
      name: null,
      transport_type: 'TR_PERS',
      route_type: 'internal',
      track_type: 'tur',
      calendar: [],
      tbl_tipuri_curse_id: null,
      country_start: null,
      country_end: null,
      middle_countries: [],
    },
    tipuriRuteList: [],
    countryList: [],
    submitStatus: null,
    days: daysCalendar,
    sugestionsProps: {
      name: "autosuggest__input__localities",
      class: 'form-control autosuggest__input',
      placeholder: '....'
    },
  }),
  validations: {
    formData: {
      name: {required},
      transport_type: {required},
      route: {required},
      route_type: {required},
      track_type: {required},
      calendar: {required},
      tbl_tipuri_curse_id: {required},
      country_start: {required},
      country_end: {required},
      middle_countries: {required},
    },
  },
  computed: {},
  mounted() {
    this.initRouteDefinition();
    this.initHubRoutes();
    this.initTipuriRute();
  },
  methods: {
    //Update the state of multiselect when an item is removed
    removeElement() {
      this.$forceUpdate();
    },
    //Update the state of multiselect when an item is added
    selectionChange() {
      this.$forceUpdate();
    },
    goToRoute(route) {
      this.$router.push({name: route});
    },
    initRouteDefinition() {
      this.$store.dispatch(FETCH_ROUTEDEFINITION, this.slug).then((response) => {
        response = response.data;
        var country_name = response.country_name != null && response.middle_countries != null ? response.country_name.split(' - ') : response.country_code.split(' - ') ; // split the calendar value
        var countrycode = response.country_code.split(' - '); // split the calendar value
        var firstCountryCode = countrycode.shift(); //Get the first country
        var lastCountryCode = countrycode.slice(-1).pop(); // Get the last country

        var remainingCountries = country_name.slice(1, -1); //Save the remaining countries to populate the multiselect (check the code from below)

        //Populate the form elements with data
        this.formData = {
          id: response.id,
          name: response.numeTipRuta,
          transport_type: response.transportType,
          route_type: response.routeType,
          track_type: response.trackType,
          tbl_tipuri_curse_id: response.tblTipuriRuteId,
          country_start: firstCountryCode,
          country_end: lastCountryCode,
        }
        // Select the selected days in calendar multiselect
        var calendarArr = response.calendar.split(', '); // split the calendar value
        this.formData.calendar = []; //we need to define the array again
        for (var i = 0; i < calendarArr.length; i++) { // loop on calendar items from response
          for (var j = 0; j < daysCalendar.length; j++) { //loop on dayscalendar saved into calendar.js file
            if (calendarArr[i] == daysCalendar[j].value) { //Find each respojnse calendar item into daycalendar
              this.formData.calendar.push(this.days.find(option => option.value === daysCalendar[j].value)); //Push the results into calendar multiselect array
            }
          }
        }

        //Add the countries into multiselect
        this.formData.middle_countries = []; //we need to define the array again
        var countries = this.countryList; // get the countrylist generated by initHubRoutes()
        for (var i = 0; i < remainingCountries.length; i++) { //loop on remaining countries
          for (var j = 0; j < countries.length; j++) { //loop into the country list
            if (remainingCountries[i] == countries[j].countryName) { //Find each remaining country into country list
              this.formData.middle_countries.push(countries.find(option => option.countryName === countries[j].countryName)); //Get the entire obj of each country found and add it to multiselect array
            }
          }
        }

        this.$log.info('FETCH_ROUTEDEFINITION', response);
      }).catch((error) => {
        this.$log.error('FETCH_ROUTEDEFINITION', error);
      });
    },
    initTipuriRute() {
      this.$store.dispatch(FETCH_TIPURI_RUTE).then((response) => {
        this.tipuriRuteList = response.data;
        this.$log.info('FETCH_TIPURI_RUTE', response);
      }).catch((error) => {
        this.$log.error('FETCH_TIPURI_RUTE', error);
      });
    },
    initHubRoutes() {
      this.$store.dispatch(FETCH_HUB_ROUTES).then((response) => {
        this.countryList = response.data;
        this.$log.info('FETCH_HUB_ROUTES', response);
      }).catch((error) => {
        this.$log.error('FETCH_HUB_ROUTES', error);
      });
    },
    onChange(e) {
      const theTarget = e.target.options[e.target.options.selectedIndex].dataset;
      this.formData.transport_type = theTarget.facturat;
      this.formData.name = theTarget.name;
    },
    computedActiveClass(actualValue, expectedValue) {
      return actualValue === expectedValue ? 'active' : '';
    },
    selectLocalityNameStartHandler(item) {
      this.formData.country_start = item ? item.numeLocalitate : null;
    },
    selectLocalityNameEndHandler(item) {
      this.formData.country_end = item ? item.numeLocalitate : null;
    },
    onSubmitRouteDefinition(e) {
      this.$v.$touch();

      // console.log(this.formData);
      // if (this.$v.$invalid) {
      //   console.log('error');
      //   this.submitStatus = 'ERROR';
      //   return;
      // }
      this.submitStatus = 'PENDING';

      let formData = new FormData();
      formData.append('data', JSON.stringify(this.formData));
      this.$store.dispatch(ROUTEDEFINITION_EDIT_SUBMIT, {id: this.slug, formData}).then((response) => {
        this.submitStatus = null;
        Vue.notify({
          group: 'success',
          type: 'success',
          title: 'Ruta a fost editata cu success!',
        })
        this.$log.info('ROUTEDEFINITION_EDIT_SUBMIT', response);
        this.$router.push({name: 'routeDefinitions'});
      }).catch((error) => {
        this.submitStatus = null;
        if (error.response && error.response.status === 400) {
          this.invalidFieldsErrors = error.response.data || {};
        }
        this.$log.error('ROUTEDEFINITION_EDIT_SUBMIT', error);
      });
    },
  },
  watch: {
    slug: {
      handler(val) {
        this.initPageData(val);
      },
    },
  },
};
