<template>
<div>
  <form id="routesForm" v-if="show.create" novalidate class="needs-validation"  @submit="onSubmitRoute" @submit.prevent="onSubmit">
    <div class="row pt-5 pb-3">
      <div class="col">
        <h1 class="text-purple pl-1">Dosar</h1>
      </div>
    </div>

    <div class="card rounded shadow-lg">
      <div class="card-header">
        <h4 class="text-purple">Detalii Dosar</h4>
      </div>
      <div>
        <div class="card-body">
          <div class="row">

            <div class="col">
              <div class="form-group">
                <label class="text-purple pl-1">Numar Dosar</label>
                <input id="ct" type="text" class="form-control" placeholder="" required v-model="formData.routeFileNumber"/>
                <small class="form-text pl-1 invalid-feedback">Acest camp este obligatoriu</small>
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label class="text-purple pl-1">Data Plecare</label>
                <datepicker :clear-button="true" :bootstrap-styling="true" input-class="form-control" v-model="formData.departureTime" name="departureTime"></datepicker>
                <small class="form-text pl-1 invalid-feedback">Acest camp este obligatoriu</small>
              </div>
              <div class="form-group">
                <label class="text-purple pl-1">Data Sosire</label>
                <datepicker :clear-button="true" :bootstrap-styling="true" input-class="form-control" v-model="formData.arrivalTime" name="arrivalTime"></datepicker>
                <small class="form-text pl-1 invalid-feedback">Acest camp este obligatoriu</small>
              </div>
            </div>

            <div class="col">
              <div class="form-group">
                <label class="text-purple pl-1">Localitate Plecare</label>
                <romfour-route-station-autosuggest
                  v-model="departureRegion"
                  @onSelectSuggestion="selectDepRegionHandler"
                />
                <small class="form-text pl-1 invalid-feedback">Acest camp este obligatoriu</small>
              </div>

              <div class="form-group">
                <label class="text-purple pl-1">Localitate Destinatie</label>

                <romfour-route-station-autosuggest
                  v-model="arrivalRegion"
                  @onSelectSuggestion="selectArrivalRegionHandler"
                />

                <small class="form-text pl-1 invalid-feedback">Acest camp este obligatoriu</small>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="form-group col">
              <label class="text-purple pl-1">Observatii</label>
              <textarea id="observatii" class="form-control" rows="4" cols="100" required></textarea>
              <small class="form-text pl-1 invalid-feedback">Acest camp este obligatoriu</small>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="row">
          <div class="col-4">
            <!-- Vehicul -->
            <div class="card rounded shadow-lg">
              <div class="card-header text-purple">
                <strong class="pl-1">Vehicul</strong>
              </div>
              <div class="card-body">
                <div class="form-group">

                  <div class="font-weight-bold pb-3" id="selected-cars" v-if="selectedVehicle">
                    <div class="row">
                      <div class="col text-purple">
                        {{selectedVehicle.numarInmatriculare}}
                      </div>

                      <div class="col text-purple">
                        {{selectedVehicle.numeTipAutovehicul}}
                      </div>
                    </div>

                  </div>
                  <button type="button" class="btn btn-purple btn-block shadow" v-on:click="changeDisplay(false, true, false, false)">
                    Adauga Autovehicul
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-5">
            <!-- Soferi -->
            <div class="card rounded shadow-lg">
              <div class="card-header text-purple">
                <strong class="pl-1">Soferi</strong>
              </div>
              <div class="card-body">
                <div class="form-group flex-column flex">
                  <div class="font-weight-bold pb-3" id="selected-drivers" v-if="selectedDrivers">
                    <div class="row" v-for="selectedDriver in selectedDrivers">
                      <div class="col">
                        {{selectedDriver.nume}} {{selectedDriver.prenume}}
                      </div>
                      <div class="col">
                        {{selectedDriver.position}}
                      </div>
                    </div>
                  </div>
                  <button type="button" class="btn btn-purple btn-block shadow" v-on:click="changeDisplay(false, false, true, false)">
                    Adauga Soferi
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-3">
            <!-- Vehicul -->
            <div class="card rounded shadow-lg">
              <div class="card-header text-purple">
                <strong class="pl-1">Remorca</strong>
              </div>
              <div class="card-body">
                <div class="form-group">

                  <div class="font-weight-bold pb-3" id="selected-trail" v-if="selectedTrail">
                    <div class="row">
                      <div class="col text-purple">
                        {{selectedTrail.numarInmatriculare}}
                      </div>

                      <div class="col text-purple">
                        {{selectedTrail.numeTipAutovehicul}}
                      </div>
                    </div>

                  </div>
                  <button type="button" class="btn btn-purple btn-block shadow" v-on:click="changeDisplay(false, false, false, true)">
                    Adauga Remorca
                  </button>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>

    <div class="row">
      <div class="col-md-8">
        <div class="row">
          <div class="col">
            <a class="btn btn-purple btn-block shadow" @click="goToRoute('routeFiles')">Renunta</a>
          </div>
          <div class="col">
            <button class="btn  btn-purple btn-block shadow" type="submit" @click.prevent="onSubmitRoute">Salveaza</button>
          </div>
        </div>
      </div>
    </div>
  </form>
  <SelectVehicles
    v-if="show.selectVehicles"
    @onSelectVehicle="onSelectVehicle"
  ></SelectVehicles>
  <SelectTrails
    v-if="show.selectTrail"
    @onSelectTrail="onSelectTrail"
  ></SelectTrails>
  <SelectDrivers
    v-if="show.selectDrivers"
    :selectedItemsWithPositions="selectedDrivers || {driver1: {}, driver2: {}}"
    @onSelectDrivers="onSelectDrivers"
  ></SelectDrivers>
</div>
</template>

<script>
  import { VueAutosuggest } from 'vue-autosuggest';

  import { ROUTE_FILES_CREATE_SUMBIT,FETCH_REGIONS } from '@/store/actions.type';
import RomPage from '@/components/page-templates/RomPage.vue';
import RomPageSidebar from '@/components/page-templates/RomPageSidebar.vue';
import PageComponent from '@/components/common/PageComponent.vue';
import SelectVehicles from '@/components/page-templates/VehiceListPage.vue';
import SelectDrivers from '@/components/page-templates/DriverListPage.vue';
import SelectTrails from '@/components/page-templates/TrailsListPage.vue';
import Datepicker from 'vuejs-datepicker';
  import RomfourRouteStationAutosuggest from "../../components/autosuggest/RomfourRouteStations";

export default {
  name: 'Route',
  components: {
    RomfourRouteStationAutosuggest,
    VueAutosuggest,
    RomPage,
    PageComponent,
    RomPageSidebar,
    Datepicker,
    SelectVehicles,
    SelectDrivers,
    SelectTrails,
  },
  data: () => ({
    show: {
      create: true,
      selectDrivers: false,
      selectVehicles: false,
      selectTrail: false,
    },
    departureProps: {
      id: "autosuggest__input__dep",
      name: "autosuggest__input__dep",
      class: 'form-control',
      placeholder: 'De ex: Cluj'
    },
    arrivalProps: {
      id: "autosuggest__input__arr",
      name: "autosuggest__input__arr",
      class: 'form-control',
      placeholder: 'De ex: Bucuresti'
    },
    suggestions: [],
    arrivalSuggestions: [],
    departureSuggestions: [],
    departureRegion: null,
    arrivalRegion: null,
    timeout: null,
    debounceMilliseconds: 500,
    selected: null,
    formData: {
      "vehicleId": null,
      "trailerId": null,
      "mainDriverId": null,
      "backupDriverId": null,
      "thirdDriverId": null,
      "departureTime": null,
      "arrivalTime": null,
      "departureRegionId": null,
      "arrivalRegionId": null,
      "observations": null,
      "routeFileNumber": null
    },
    selectedVehicle: {},
    selectedDrivers: {},
    selectedTrail: null,
  }),
  computed: {
  },
  mounted() {
  },
  methods: {
    goToRoute(route) {
      this.$router.push({ name: route});
    },
    fetchRegionsDeparture(text) {
      this.fetchRegions('departure', text);
    },
    selectDepRegionHandler(item) {
      this.formData.departureRegionId = item.id;
    },
    selectArrivalRegionHandler(item) {
      this.formData.arrivalRegionId = item.id;
    },
    fetchRegionsArrival(text) {
      this.fetchRegions('arrival', text);
    },
    fetchRegions(type, text) {
      clearTimeout(this.timeout);
      if (!text) {
        return;
      }
      this.timeout = setTimeout(() => {
        this.$store.dispatch(FETCH_REGIONS, {regionName: text}).then((response) => {

          if (type == 'arrival') {
            this.arrivalSuggestions.push(response);
          } else {
            this.departureSuggestions.push(response);
          }
          this.$log.info('FETCH_REGIONS', response);
        }).catch((error) => {
          this.$log.error('FETCH_REGIONS', error);
        });
      }, this.debounceMilliseconds)
    },
    renderSuggestion(suggestion) {
      return suggestion.item.numeLocalitate;
    },
    getSuggestionValue(suggestion) {
      return suggestion.item.numeLocalitate;
    },
    onSelectVehicle(item) {
      this.selectedVehicle = item;
      this.show = {
        create: true,
        selectDrivers: false,
        selectVehicles: false,
        selectTrail: false,
      }
      if (!this.selectedTrail && item.trailerId) {
        this.formData.trailerId = item.trailerId;
        this.selectedTrail = {
          codAutovehicul: item.trailerId,
          numarInmatriculare: item.trailerNumarInmatriculare
        }
      }

      if (!this.selectedDrivers.driver1 && item.mainDriverId) {
        this.formData.mainDriverId = item.mainDriverId;
        this.selectedDrivers.driver1 = {
          codAng: item.mainDriverId,
          nume: item.mainDriverLastName || null,
          prenume: item.mainDriverFirstName || null,
          position: 'principal',
        };
      }

      if (!this.selectedDrivers.driver2 && item.backupDriverId) {
        this.formData.backupDriverId = item.backupDriverId;
        this.selectedDrivers.driver2 = {
          codAng: item.backupDriverId,
          nume: item.backupDriverLastName || null,
          prenume: item.backupDriverFirstName || null,
          position: 'rezerva',
        };
      }
      if (!this.selectedDrivers.driver3 && item.thirdDriverId) {
        this.formData.thirdDriverId = item.thirdDriverId;
        this.selectedDrivers.driver2 = {
          codAng: item.thirdDriverId,
          nume: item.thirdDriverLastName || null,
          prenume: item.thirdDriverFirstName || null,
          position: 'suplimentar',
        };
      }
    },
    onSelectTrail(item) {
      this.selectedTrail = item;
      this.show = {
        create: true,
        selectDrivers: false,
        selectVehicles: false,
        selectTrail: false,
      }
    },
    changeDisplay(create, vehicle, drivers, trail) {
      this.show = {
        create: create,
        selectDrivers: drivers,
        selectVehicles: vehicle,
        selectTrail: trail,
      }
    },
    onSelectDrivers(item) {
      this.selectedDrivers = item;
      this.show = {
        create: true,
        selectDrivers: false,
        selectVehicles: false,
        selectTrail: false,
      }
    },
    onSubmitRoute() {
      // let mainDriver, backupDriver, thirdDriver;
      //
      // if (this.selectedDrivers.driver1.position == 'principal') {
      //   mainDriver= this.selectedDrivers.driver1.codAng;
      //   backupDriver= this.selectedDrivers.driver2 ? this.selectedDrivers.driver2.codAng : null;
      // } else if (this.selectedDrivers.driver2.position == 'rezerva') {
      //   mainDriver= this.selectedDrivers.driver2.codAng;
      //   backupDriver= this.selectedDrivers.driver1 ? this.selectedDrivers.driver1.codAng : null;
      // } else {
      //   mainDriver= this.selectedDrivers.driver3.codAng;
      //   thirdDriver= this.selectedDrivers.driver1 ? this.selectedDrivers.driver1.codAng : null;
      // }
      let mainDriver = null;
      let backupDriver = null;
      let thirdDriver = null;
      var drivers = [this.selectedDrivers.driver1, this.selectedDrivers.driver2, this.selectedDrivers.driver3];
      //Assign driver to his correct position
      drivers.forEach(function(driver, idx, sourceArr) {
        if(driver.position == 'principal') {
          mainDriver = driver.codAng;
        } else if(driver.position == 'rezerva') {
          backupDriver = driver.codAng;
        } else if(driver.position == 'suplimentar') {
          thirdDriver = driver.codAng;
        }
      });
      const data = {
        "vehicleId": this.selectedVehicle.codAutovehicul,
        "trailerId": this.selectedTrail ? this.selectedTrail.codAutovehicul : null,
        "mainDriverId": mainDriver,
        "backupDriverId": backupDriver,
        "thirdDriverId": thirdDriver,
        "departureTime": this.formData.departureTime,
        "arrivalTime": this.formData.arrivalTime,
        "departureRegionId": this.formData.departureRegionId,
        "arrivalRegionId": this.formData.arrivalRegionId,
        "observations": this.formData.observations,
        "routeFileNumber": this.formData.routeFileNumber
      };
      this.$store.dispatch(ROUTE_FILES_CREATE_SUMBIT, data).then((response) => {
        this.$log.info('ROUTE_FILES_CREATE_SUMBIT', response);
        this.$router.push({ name: 'routeFiles'});
      }).catch((error) => {
        this.$log.error('ROUTE_FILES_CREATE_SUMBIT', error);
      });
    },
  },
};
</script>


<style>
  #autosuggest__input__dep, #autosuggest__input__arr {
    outline: none;
    position: relative;
    display: block;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
  }

  #autosuggest__input__dep.autosuggest__input-open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .autosuggest__results-container {
    position: relative;
    width: 100%;
  }

  .autosuggest__results {
    font-weight: 300;
    margin: 0;
    position: absolute;
    z-index: 10000001;
    width: 100%;
    border: 1px solid #e0e0e0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background: white;
    padding: 0px;
    max-height: 400px;
    overflow-y: scroll;
  }

  .autosuggest__results ul {
    list-style: none;
    padding-left: 0;
    margin: 0;
  }

  .autosuggest__results .autosuggest__results-item {
    cursor: pointer;
    padding: 15px;
  }

  #autosuggest ul:nth-child(1) > .autosuggest__results_title {
    border-top: none;
  }

  .autosuggest__results .autosuggest__results-before {
    color: gray;
    font-size: 11px;
    margin-left: 0;
    padding: 15px 13px 5px;
    border-top: 1px solid lightgray;
  }

  .autosuggest__results .autosuggest__results-item:active,
  .autosuggest__results .autosuggest__results-item:hover,
  .autosuggest__results .autosuggest__results-item:focus,
  .autosuggest__results
  .autosuggest__results-item.autosuggest__results-item--highlighted {
    background-color: #f6f6f6;
  }
</style>
