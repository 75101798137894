<template>
    <div :id="id" class="rom-page">

        <div class="md-layout">
            <div id="rom-page-breadcrumbs" v-if="hasBreadcrumbs" class="md-layout-item md-size-100">
                <slot name="breadcrumbs">
                    {{ currentRouteName }}
                </slot>
            </div>

            <div id="rom-page-topbar" v-if="hasTopBar" class="md-layout-item md-size-100">
                <slot name="topbar">
                    <h2>REC TOPBAR</h2>
                </slot>
            </div>
        </div>

        <div class="md-layout">
            <div id="rom-page-sidebar" v-if="hasSidebar" class="md-layout-item md-size-25">
                <slot name="sidebar">
                    <h2>REC SIDEBAR</h2>
                </slot>
            </div>

            <div id="rom-page-content" class="md-layout-item">
                <slot name="content">
                    <h2>REC CONTENT</h2>
                </slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'RomPage',
  props: {
    id: {
      type: String,
      required: true,
    },
    hasBreadcrumbs: {
      type: Boolean,
      default: false,
    },
    hasTopBar: {
      type: Boolean,
      default: false,
    },
    hasSidebar: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    currentRouteName() {
      return this.$i18n.t(`${this.$route.name}`);
    },
  },
};
</script>

<style lang="scss">
    @import '@/assets/scss/layout/rom-page.scss';
</style>
