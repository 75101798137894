// @vue/component
import {
  FETCH_RESERVATIONS_CRUISE,
  CRUISE_RESERVATIONS_CREATE_SUBMIT,
  FETCH_SAVED_RESERVATIONS,
  FETCH_SAVED_RESERVATIONS_WITH_ID,
} from '@/store/actions.type';
import SelectReservation from '@/components/page-templates/ReservationListPage.vue';
import CruisePlanningEditStep3 from '@/views/CruisePlanningEdit/CruisePlanningEditStep3/CruisePlanningEditStep3.vue';
import Vue from "vue";

export default {
  name: 'Cruiseplanningeditstep2',

  components: {
    SelectReservation,
    CruisePlanningEditStep3
  },

  mixins: [],

  props: {
    slug: Number | String,
    country_code_component: String,
    country_name_component: String,
    route_type_component: String,
    formDataStep1: Object,
    savedCruiseID: Number,
    routeId: Number | String,
    routeTypeId: Number | String,
  },
  data: () => ({
    show: {
      createRoute: false,
      selectReservation: false,
      createRoutesStep2: true,
      createRoutesStep3: false,
    },
    selectedReservations: [],
    countryCode: null,
    addedReservations: [],
    countryRo: {
      RO: 'Romania',
    },
    currentCountryCode: null,
    externalCountries: [],
    reservationCountryCodes: null,
    reservationCountries: [],
    ReservationCountriesWithCountryName: [],
  }),
  mounted() {
    //Get the country codes for EXTERNAL ROUTE
    var countryCodeArr = this.country_code_component.split(' - ');
    var countryNameArr = this.country_name_component.split(' - ');
    this.externalCountries = countryCodeArr.reduce(function (previous, key, index) {
      previous[key] = countryNameArr[index];
      return previous
    }, {})
    this.fetchReservations(this.externalCountries);
  },
  methods: {
    //Display the reservation based on selected rout from step 1
    fetchReservations(params) {
      params.date = this.formDataStep1.date;
      let savedReservations = [];
      this.$store.dispatch(FETCH_RESERVATIONS_CRUISE, params).then((response) => {
        let results = [];
        _.each(response.data[0], function(value, key) {
          if(value.cruiseId == null) {
            response.data[0][key]['reservationStatus'] = 'new';
          } else {
            response.data[0][key]['reservationStatus'] = 'old';
          }
        });
        this.reservationCountries = _.groupBy(response.data[0], 'codTara2'); //Group Reservation Based on destination countries
        //Change the Country Codes into Country Names
        const map = {
          AT: 'Austria',
          FR: 'Franta',
          IT: 'Italia',
          RO: 'Romania',
          DE: 'Germania',
          NL: 'Olanda',
          BE: 'Belgia',
          GB: 'Marea Britanie',
          CH: 'Elvetia',
          DK: 'Danemarca',
        }
        var newReservationArr = [];
        _.each(this.reservationCountries, function(value, key) {
          key = map[key] || key;
          newReservationArr[key] = value;
        });
        this.ReservationCountriesWithCountryName = _.omit(newReservationArr, Object.keys(map));
        this.$log.info('FETCH_RESERVATIONS_CRUISE', response);
      }).catch((error) => {
        this.$log.error('FETCH_RESERVATIONS_CRUISE', error);
      });
    },
    //This is the method that reinitialize the reservation list
    refreshReservationList() {
      this.fetchReservations(this.externalCountries);
      Vue.notify({
        group: 'success',
        type: 'success',
        title: 'Lista de rezervari a fost reimprospatata! Rezervarile sterse au revenit.',
      })
    },
    //Change the steps ->
    changeDisplay(createRoute, reservation, createRouteStep2, createRouteStep3, createRouteStep4) {
      this.show = {
        createRoute: createRoute,
        selectReservation: reservation,
        createRoutesStep2: createRouteStep2,
        createRoutesStep3: createRouteStep3,
        createRoutesStep4: createRouteStep4,
      }
    },
    //Change the steps <-
    onSelectSteps(item) {
      this.selectedReservations = item;
      this.show = {
        createRoute: false,
        selectReservation: false,
        createRouteStep2: true,
        createRouteStep3: false,
        createRouteStep4: false,
      }
    },
    //Delete cruise when previous step is active
    onSelectPreviousStep() {
      this.$emit('onSelectSteps', true,false,false);
    },
    //Delete Item from list
    deleteReservation(item, index) {
      _.each(this.ReservationCountriesWithCountryName, function(value, key) {
        value.filter(function (reservation, index) {
          if(reservation.id == item.id) {
            value.splice(index, 1);
          }
        });
      });
    },
    onSubmitCruiseReservationDefinition(e) {
      var reservationIDS = [];
      Object.keys(this.ReservationCountriesWithCountryName).forEach(key => {
        const country = this.ReservationCountriesWithCountryName[key]
        country.forEach(reservation => {
          reservationIDS.push(reservation.id);
        })

      })
      let data = {
        reservations: reservationIDS,
        savedCruiseId: this.savedCruiseID
      }

      let formData = new FormData();
      formData.append('data', JSON.stringify(data));
      this.$store.dispatch(CRUISE_RESERVATIONS_CREATE_SUBMIT, formData).then((response) => {
        this.submitStatus = null;
        Vue.notify({
          group: 'success',
          type: 'success',
          title: 'Rezervarile au fost salvate! Se trece la urmatorul pas!',
        })
        this.changeDisplay(false, false,  false, true, false);
        this.$log.info('CRUISE_RESERVATIONS_CREATE_SUBMIT', response);
      }).catch((error) => {
        Vue.notify({
          group: 'errors',
          type: 'error',
          title: 'Rezervarile nu au putut fi salvate! Incercati din Nou!',
        })
        this.submitStatus = null;
        if (error.response && error.response.status === 400) {
          this.invalidFieldsErrors = error.response.data || {};
        }
        this.$log.error('CRUISE_RESERVATIONS_CREATE_SUBMIT', error);
      });
    },
  },
  watch: {
    slug: {
      handler(val) {
        this.initPageData(val);
      },
    },
  },
}
