<template>
<div>
  <form id="routesLogsForm" novalidate class="needs-validation"  @submit="onSubmitRouteFileLogbook" @submit.prevent="onSubmit">
    <div class="row pt-5 pb-3">
      <div class="col">
        <h1 class="text-purple pl-1">Dosar</h1>
      </div>
    </div>

    <div class="card rounded shadow-lg">
      <div class="card-header">
        <h4 class="text-purple">Foi de parcurs</h4>
      </div>
      <div class="card-body">
        <div class="row">
            <div class="col">
              <div class="form-group">
                <label class="text-purple pl-1">Numar</label>
                <input type="text" class="form-control" placeholder="" required v-model="formData.l1.number"
                  :class="getLogbookColorBasedOnType(formData.l1)"
                />
                <small class="form-text pl-1 invalid-feedback">Acest camp este obligatoriu</small>
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label class="text-purple pl-1">Serie</label>
                <input type="text" class="form-control" placeholder="" required v-model="formData.l1.series"
                       :class="getLogbookColorBasedOnType(formData.l1)"/>
                <small class="form-text pl-1 invalid-feedback">Acest camp este obligatoriu</small>
              </div>
            </div>
            <div class="col">

            <div class="form-group">
                <label class="text-purple pl-1">Predat la</label>
                <datepicker :clear-button="true" :bootstrap-styling="true" input-class="form-control" v-model="formData.l1.handedOverAt"></datepicker>
                <small class="form-text pl-1 invalid-feedback">Acest camp este obligatoriu</small>
              </div>
            </div>

          <div class="col">
            <div class="form-group">
              <label class="text-purple pl-1">Preluat de catre</label>
              <romfour-employee-autosuggest
                v-model="formData.l1.takenOverBy"
                @onSelectSuggestion="onSelectTakenOverLogbook1"
              />
              <small class="form-text pl-1 invalid-feedback">Acest camp este obligatoriu</small>
            </div>
          </div>
          </div>

        <div class="row">
          <div class="col">
            <div class="form-group">
              <label class="text-purple pl-1">Numar</label>
              <input type="text" class="form-control" placeholder="" required v-model="formData.l2.number"
                     :class="getLogbookColorBasedOnType(formData.l2)"
              />
              <small class="form-text pl-1 invalid-feedback">Acest camp este obligatoriu</small>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label class="text-purple pl-1">Serie</label>
              <input type="text" class="form-control" placeholder="" required v-model="formData.l2.series"
                     :class="getLogbookColorBasedOnType(formData.l2)"/>
              <small class="form-text pl-1 invalid-feedback">Acest camp este obligatoriu</small>
            </div>
          </div>
          <div class="col">

            <div class="form-group">
              <label class="text-purple pl-1">Predat la</label>
              <datepicker :clear-button="true" :bootstrap-styling="true" input-class="form-control" v-model="formData.l2.handedOverAt"></datepicker>
              <small class="form-text pl-1 invalid-feedback">Acest camp este obligatoriu</small>
            </div>
          </div>

          <div class="col">
            <div class="form-group">
              <label class="text-purple pl-1">Preluat de catre</label>
              <romfour-employee-autosuggest
                v-model="formData.l2.takenOverBy"
                @onSelectSuggestion="onSelectTakenOverLogbook2"
              />
              <small class="form-text pl-1 invalid-feedback">Acest camp este obligatoriu</small>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="form-group">
              <label class="text-purple pl-1">Numar</label>
              <input type="text" class="form-control" placeholder="" required v-model="formData.l3.number"
                     :class="getLogbookColorBasedOnType(formData.l3)"
              />
              <small class="form-text pl-1 invalid-feedback">Acest camp este obligatoriu</small>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label class="text-purple pl-1">Serie</label>
              <input type="text" class="form-control" placeholder="" required v-model="formData.l3.series"
                     :class="getLogbookColorBasedOnType(formData.l3)"/>
              <small class="form-text pl-1 invalid-feedback">Acest camp este obligatoriu</small>
            </div>
          </div>
          <div class="col">

            <div class="form-group">
              <label class="text-purple pl-1">Predat la</label>
              <datepicker :clear-button="true" :bootstrap-styling="true" input-class="form-control" v-model="formData.l3.handedOverAt"></datepicker>
              <small class="form-text pl-1 invalid-feedback">Acest camp este obligatoriu</small>
            </div>
          </div>

          <div class="col">
            <div class="form-group">
              <label class="text-purple pl-1">Preluat de catre</label>
              <romfour-employee-autosuggest
                v-model="formData.l3.takenOverBy"
                @onSelectSuggestion="onSelectTakenOverLogbook3"
              />
              <small class="form-text pl-1 invalid-feedback">Acest camp este obligatoriu</small>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="form-group">
              <label class="text-purple pl-1">Numar</label>
              <input type="text" class="form-control" placeholder="" required v-model="formData.l4.number"
                     :class="getLogbookColorBasedOnType(formData.l4)"
              />
              <small class="form-text pl-1 invalid-feedback">Acest camp este obligatoriu</small>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label class="text-purple pl-1">Serie</label>
              <input type="text" class="form-control" placeholder="" required v-model="formData.l4.series"
                     :class="getLogbookColorBasedOnType(formData.l4)"/>
              <small class="form-text pl-1 invalid-feedback">Acest camp este obligatoriu</small>
            </div>
          </div>
          <div class="col">

            <div class="form-group">
              <label class="text-purple pl-1">Predat la</label>
              <datepicker :clear-button="true" :bootstrap-styling="true" input-class="form-control" v-model="formData.l4.handedOverAt"></datepicker>
              <small class="form-text pl-1 invalid-feedback">Acest camp este obligatoriu</small>
            </div>
          </div>

          <div class="col">
            <div class="form-group">
              <label class="text-purple pl-1">Preluat de catre</label>
              <romfour-employee-autosuggest
                v-model="formData.l4.takenOverBy"
                @onSelectSuggestion="onSelectTakenOverLogbook4"
              />
              <small class="form-text pl-1 invalid-feedback">Acest camp este obligatoriu</small>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="form-group">
              <label class="text-purple pl-1">Numar</label>
              <input type="text" class="form-control" placeholder="" required v-model="formData.l5.number"
                     :class="getLogbookColorBasedOnType(formData.l5)"
              />
              <small class="form-text pl-1 invalid-feedback">Acest camp este obligatoriu</small>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label class="text-purple pl-1">Serie</label>
              <input type="text" class="form-control" placeholder="" required v-model="formData.l5.series"
                     :class="getLogbookColorBasedOnType(formData.l5)"/>
              <small class="form-text pl-1 invalid-feedback">Acest camp este obligatoriu</small>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label class="text-purple pl-1">Predat la</label>
              <datepicker :clear-button="true" :bootstrap-styling="true" input-class="form-control" v-model="formData.l5.handedOverAt"></datepicker>
              <small class="form-text pl-1 invalid-feedback">Acest camp este obligatoriu</small>
            </div>
          </div>

          <div class="col">
            <div class="form-group">
              <label class="text-purple pl-1">Preluat de catre</label>
              <romfour-employee-autosuggest
                v-model="formData.l5.takenOverBy"
                @onSelectSuggestion="onSelectTakenOverLogbook5"
              />
              <small class="form-text pl-1 invalid-feedback">Acest camp este obligatoriu</small>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="row">

          <div class="col">
            <button class="btn  btn-purple btn-block shadow" type="submit" @click.prevent="onSubmitRouteFileLogbook">Salveaza</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
</template>

<script>

  import { ROUTE_FILES_LOGBOOKS_CREATE_SUMBIT, ROUTE_FILES_LOGS_INIT_EDIT_DATA, ROUTE_FILES_LOGBOOKS_EDIT_SUMBIT } from '@/store/actions.type';
import RomPage from '@/components/page-templates/RomPage.vue';
import RomPageSidebar from '@/components/page-templates/RomPageSidebar.vue';
import PageComponent from '@/components/common/PageComponent.vue';
import SelectVehicles from '@/components/page-templates/VehiceListPage.vue';
import SelectDrivers from '@/components/page-templates/DriverListPage.vue';
import SelectTrails from '@/components/page-templates/TrailsListPage.vue';
import Datepicker from 'vuejs-datepicker';
  import RomfourEmployeeAutosuggest from "../../components/autosuggest/RomfourEmployee";

export default {
  name: 'Route',
  components: {
    RomfourEmployeeAutosuggest,
    RomPage,
    PageComponent,
    RomPageSidebar,
    Datepicker,
    SelectVehicles,
    SelectDrivers,
    SelectTrails,
  },
  props: {
    slug: String|Number,
  },
  data: () => ({
    formData: {
      l1: {
        "number": null,
        "series": null,
        "type": "verde",
        "takenOverBy": null,
        "handedOverAt": null,
      },
      l2: {
        "number": null,
        "series": null,
        "type": "verde",
        "takenOverBy": null,
        "handedOverAt": null,
      },
      l3: {
        "number": null,
        "series": null,
        "type": "verde",
        "takenOverBy": null,
        "handedOverAt": null,
      },
      l4: {
        "number": null,
        "series": null,
        "type": "albastru",
        "takenOverBy": null,
        "handedOverAt": null,
      },
      l5: {
        "number": null,
        "series": null,
        "type": "albastru",
        "takenOverBy": null,
        "handedOverAt": null,
      }
    }
  }),
  computed: {
  },
  mounted() {
    this.initPageData(this.slug);
  },
  methods: {
    initPageData(id) {
      this.$store.dispatch(ROUTE_FILES_LOGS_INIT_EDIT_DATA, id).then((response) => {
        response = response['data'];
        if (response.length) {
          this.formData = {
            l1: response[0],
            l2: response[1],
            l3: response[2],
            l4: response[3],
            l5: response[4],
          };
        }
        this.$log.info('ROUTE_FILES_LOGS_INIT_EDIT_DATA', response);
      }).catch((error) => {
        this.$log.error('ROUTE_FILES_LOGS_INIT_EDIT_DATA', error);
      });
    },
    getLogbookColorBasedOnType(logbook) {
      return logbook.type === 'verde' ? 'green-logbook' : 'blue-logbook';
    },

    onSubmitRouteFileLogbook() {
      const params = {
        routeFileId: this.slug,
        data: this.formData
      };
      if (this.formData.l1 && this.formData.l1.id) {
        this.$store.dispatch(ROUTE_FILES_LOGBOOKS_EDIT_SUMBIT, params).then((response) => {
          this.$log.info('ROUTE_FILES_LOGBOOKS_EDIT_SUMBIT', response);
          this.$router.push({ name: 'routeFiles'});
        }).catch((error) => {
          this.$log.error('ROUTE_FILES_LOGBOOKS_EDIT_SUMBIT', error);
        });
      } else {
        this.$store.dispatch(ROUTE_FILES_LOGBOOKS_CREATE_SUMBIT, params).then((response) => {
          this.$log.info('ROUTE_FILES_LOGBOOKS_CREATE_SUMBIT', response);
          this.$router.push({ name: 'routeFiles'});
        }).catch((error) => {
          this.$log.error('ROUTE_FILES_LOGBOOKS_CREATE_SUMBIT', error);
        });
      }
    },
    onSelectTakenOverLogbook1(employee) {
      this.formData.l1.takenOverBy = employee ? `${employee.nume} ${employee.prenume}` : null;
    },
    onSelectTakenOverLogbook2(employee) {
      this.formData.l2.takenOverBy = employee ? `${employee.nume} ${employee.prenume}` : null;
    },
    onSelectTakenOverLogbook3(employee) {
      this.formData.l3.takenOverBy = employee ? `${employee.nume} ${employee.prenume}` : null;
    },
    onSelectTakenOverLogbook4(employee) {
      this.formData.l4.takenOverBy = employee ? `${employee.nume} ${employee.prenume}` : null;
    },
    onSelectTakenOverLogbook5(employee) {
      this.formData.l5.takenOverBy = employee ? `${employee.nume} ${employee.prenume}` : null;
    },
  },
  watch: {
    slug: {
      handler(val) {
        this.initPageData(val);
      },
    },
  },
};
</script>
