<template>
  <div>
    <div class="row pt-5 pb-3">
      <div class="col">
        <h1 class="text-purple pl-1">Vehicul {{ licencePlate }}</h1>
      </div>
    </div>

    <form v-on:submit.prevent action="">
      <div class="form-row">
        <FlashMessage></FlashMessage>
        <md-card class="card" style="z-index: 2; display: block;width: 21%; height:auto;">
          <md-card-content class="card-body rounded shadow-lg" style="height: 100%;">
            <div class="card-header text-purple" style="padding-top:0;">
              <strong class="pl-1  font-weight-bold">Situatie Autovehicul</strong>
            </div>
            <div class="card-header">
              <div class="form-row" style="margin-top:5px;">
                <label class="text-purple pl-1">Status</label>
                <select id="vehicleStatus"
                        class="form-control select2-hidden-accessible select2-dropdown d-block"
                        tabindex="-1"
                        aria-hidden="true"
                        required
                        v-model="vehicleStatus"
                        @change="selectVehicleStatus(vehicleStatus)"
                >
                  <option :value="vehicleStatusVal.transfer">{{ $t('transfer') }}</option>
                  <option :value="vehicleStatusVal.course">{{ $t('course') }}</option>
                  <option :value="vehicleStatusVal.transferAndCourse">{{ $t('transferAndCourse') }}</option>
                  <option :value="vehicleStatusVal.unavailable">{{ $t('unavailable') }}</option>
                </select>
                <small class="form-text pl-1 error" v-if="warning === 1">Vehiculul nu are un hub intern
                  selectat!</small>
                <small class="form-text pl-1 error" v-if="warning === 2">Vehiculul nu are un hub extern
                  selectat!</small>
                <small class="form-text pl-1 error" v-if="warning === 3">Vehiculul nu are un hub intern si extern
                  selectat!</small>
              </div>
            </div>
            <div class="card-header" v-if="vehicleStatus == 'unavailable'">
              <div class="form-row" style="margin-top:5px;">
                <label class="text-purple pl-1">Motiv Indisponibil:</label>
                <select id="unavailableReason"
                        class="form-control select2-hidden-accessible select2-dropdown d-block"
                        tabindex="-1"
                        aria-hidden="true"
                        required
                        v-model="unavailableVehicleStatusReason"
                        @change="selectVehicleStatus(unavailableVehicleStatusReason)"
                >
                  <option :value="vehicleStatusVal.damaged">{{ $t('cars.damaged') }}</option>
                  <option :value="vehicleStatusVal.broken">{{ $t('cars.broken') }}</option>
                  <option :value="vehicleStatusVal.withoutDriver">{{ $t('cars.withoutDriver') }}</option>
                  <option :value="vehicleStatusVal.withoutDocuments">{{ $t('cars.withoutDocuments') }}</option>
                  <option :value="vehicleStatusVal.service">{{ $t('cars.service') }}</option>
                  <option :value="vehicleStatusVal.ontrack">{{ $t('cars.ontrack') }}</option>
                </select>
                <!--              <small class="form-text pl-1 error" v-if="submitStatus && !$v.formData.employee.companyId.required">Acest camp este obligatoriu</small>-->
              </div>
            </div>
            <div class="card-header">
              <div class="form-row" style="margin-top:5px;">
                <button class="btn btn-purple btn-block shadow" :disabled="saveButtonDisabled"
                        @click="saveVehicleStatusManually()">
                  Salveaza
                </button>
              </div>
            </div>
          </md-card-content>
        </md-card>
        <md-card class="card" style="z-index: 2; display: block;width: 74%;">
          <md-card-content class="card-body rounded shadow-lg">
            <div class="card-header text-purple" style="padding-top:0;">
              <strong class="pl-1  font-weight-bold">Hub-uri</strong>
            </div>
            <!-- ======================== ============================ HUB INTERN =============================================-->
            <div class="card-header">
              <div class="form-row">
                <label class="pt-2">Vehiculul este disponibil pentru curse interne:</label>
                <div class="col-1 pl-1">
                  <div class="btn-group custom-btn-group btn-group-toggle" data-toggle="buttons">
                    <label class="btn"
                           :class="computedActiveClass(typeInternal, 'intern')"
                    > Da
                      <input type="radio" name="typeInternal" value="intern" autocomplete="off" v-model="typeInternal"
                             @change="saveOptionForInternalType">
                    </label>
                    <label class="btn" :class="computedActiveClass(typeInternal, null)">Nu
                      <input type="radio" name="typeInternal" :value="null" autocomplete="off" v-model="typeInternal"
                             @change="saveOptionForInternalType">
                    </label>
                  </div>
                </div>
              </div>
              <h6
                v-if="typeInternal && (internalHubs.length === 0 || (internalHubs.length === 1 && internalHubs[0].name == null))"
                class="text-blue"
              >(Nu exista puncte de transfer asociate)</h6>
              <div class="form-row" v-if="typeInternal">
                <div class="form-group pr-2" v-for="(hub, index) in internalHubs" :key="hub.courseTypeId">
                  <romfour-hub-autosuggest
                    v-model="hub.name"
                    type="intern"
                    @onSelectSuggestion="selecthubHandler"
                    :disabled="hub !== '' && hub.name != null"
                    :vehicle-id="vehicleId"
                  />
                  <a class="btn bg-transparent" @click="clearField(index, 'intern', hub.courseTypeId, hub.name)"
                     v-if="hub !== ''"
                     style="margin-left: -35px; z-index: 100; position:absolute;
                     padding-top:10px; padding-right: 10px; padding-bottom: 8px;">
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <!--Adds an empty input for the next hub-->
                <div class="col-1">
                  <a class="btn btn-md btn-raised btn-wave btn-icon mt-2 btn-rounded text-white"
                     style="background-color:#603084; width:20px; height: 20px; margin-left: 6px;"
                     @click="addNewInput('NewInternalCourseType')"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
                         stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                         class="feather feather-plus">
                      <line x1="12" y1="5" x2="12" y2="19"></line>
                      <line x1="5" y1="12" x2="19" y2="12"></line>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
            <!-- ======================== ============================ HUB EXTERN =============================================-->
            <div class="card-header">
              <div class="form-row">
                <label class="pt-2">Vehiculul este disponibil pentru curse externe:</label>
                <div class="col-1 pl-1">
                  <div class="btn-group custom-btn-group btn-group-toggle" data-toggle="buttons">
                    <label class="btn"
                           :class="computedActiveClass(typeExternal, 'extern')"
                    > Da
                      <input type="radio" name="typeExternal" value="extern" autocomplete="off" v-model="typeExternal"
                             @change="saveOptionForExternalType">
                    </label>
                    <label class="btn" :class="computedActiveClass(typeExternal, null)">Nu
                      <input type="radio" name="typeExternal" :value="null" autocomplete="off" v-model="typeExternal"
                             @change="saveOptionForExternalType">
                    </label>
                  </div>
                </div>
              </div>
              <h6
                v-if="typeExternal && (externalHubs.length === 0 || (externalHubs.length === 1 && externalHubs[0].name == null))"
                class="text-blue"
              >(Nu exista HUB-uri asociate)</h6>
              <div class="form-row" v-if="typeExternal">
                <div class="form-group pr-2" v-for="(hub, index) in externalHubs" :key="hub.courseTypeId">
                  <romfour-hub-autosuggest
                    v-model="hub.name"
                    type="extern"
                    @onSelectSuggestion="selecthubHandler"
                    :disabled="hub !== '' && hub.name != null"
                    :vehicle-id="vehicleId"
                  />
                  <a class="btn bg-transparent" @click="clearField(index, 'extern', hub.courseTypeId, hub.name)"
                     v-if="hub !== ''"
                     style="margin-left: -35px; z-index: 100; position:absolute;
                     padding-top:10px; padding-right: 10px; padding-bottom: 8px;">
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <!--Adds an empty input for the next hub-->
                <div class="col-1">
                  <a class="btn btn-md btn-raised btn-wave btn-icon mt-2 btn-rounded text-white"
                     style="background-color:#603084; width:20px; height: 20px; margin-left: 6px;"
                     @click="addNewInput('NewExternalCourseType')"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
                         stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                         class="feather feather-plus">
                      <line x1="12" y1="5" x2="12" y2="19"></line>
                      <line x1="5" y1="12" x2="19" y2="12"></line>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
            <div class="card-header">
              <div class="form-row">
                <label class="pt-2">Tipul de transport:</label>
                <div class="col-1 pl-1">
                  <div class="btn-group custom-btn-group btn-group-toggle" data-toggle="buttons">
                    <label class="btn"
                           :class="computedActiveClass(transportType, 'TR_PERS')" > Transport Persoane
                      <input type="radio" name="typeExternal" value="TR_PERS" autocomplete="off" v-model="transportType"
                             @change="saveTransportType()">
                    </label>
                    <label class="btn" :class="computedActiveClass(transportType, 'TR_COL')">Transport Colete
                      <input type="radio" name="typeExternal" value="TR_COL" autocomplete="off" v-model="transportType"
                             @change="saveTransportType()">
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </md-card-content>
        </md-card>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="row" style="margin-right: 0;">
            <div class="col">
              <a class="btn btn-purple btn-block shadow" @click="goToRoute('vehiclesCourseType')">Inapoi catre Lista</a>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import {VueAutosuggest} from 'vue-autosuggest';
import {
  FETCH_VEHICLECOURSETYPE, VEHICLE_COURSE_TYPE_EDIT_SUBMIT, ADD_VEHICLE_STATUS,
  VEHICLE_COURSE_TYPE_DELETE, VEHICLE_COURSE_TYPE_CREATE_SUBMIT, VEHICLE_COURSE_TYPE_UPDATE_TRANSPORTYPE
} from '@/store/actions.type';
import Datepicker from 'vuejs-datepicker';
import {API_URL} from '@/config';
import RfrFilePreviewer from "../../components/fields/RfrFilePreviewer";
import RomfourHubAutosuggest from "@/components/autosuggest/RomfourHub";
import {required} from 'vuelidate/lib/validators'
import vehicleCourseTypeMethods from '@/store/vehiclesCourseType/vehicleCourseTypeCustomMethods.js';
import FlashMessage from '@smartweb/vue-flash-message';
import vehicleStatusValues from '@/enums/vehicleStatusValues';

export default {
  name: 'vehiclesCourseTypeEditPage',
  components: {
    RomfourHubAutosuggest,
    RfrFilePreviewer,
    Datepicker,
    VueAutosuggest,
    vehicleCourseTypeMethods,
    vehicleStatusValues,
  },
  props: {
    slug: Number | String,
  },
  data: () => ({
    licencePlate: null,
    vehicleId: null,
    hub: null,
    typeInternal: null,
    typeExternal: null,
    transportType: 'TR_PERS',
    formData: {
      preferences: {
        hubId: null,
        vehicleId: null,
        availableFor: null,
        internalHubNames: null,
        externalHubNames: null,
      },
    },
    vehicleStatusVal: vehicleStatusValues,
    internalHubs: [],
    externalHubs: [],
    submitStatus: null,
    vehicleStatus: null,
    unavailableVehicleStatusReason: 'unavailable/damaged',
    saveButtonDisabled: true,
    warning: 0,
    currentVehicleStatus: false,

  }),
  validations: {},
  mounted() {
    this.initVehicleWithHubs();
    this.initStatus();
    this.$i18n.locale = 'ro';
  },
  methods: {
    goToRoute(route) {
      this.$router.push({name: route});
    },

    initStatus() {
      vehicleCourseTypeMethods.getCurrentVehicleStatus.call(this, this.slug);
    },
    /**
     * Fetch hubs for current vehicle
     */
    initVehicleWithHubs() { // Fetch the hubs relation with current vehicle
      this.$store.dispatch(FETCH_VEHICLECOURSETYPE, this.slug).then((response) => {
        response = response.data;
        this.licencePlate = response.numarInmatriculare;
        this.vehicleId = response.codAutovehicul;
        //Create separate hubs arrays based on location type
        let internalHubs = [];
        let externalHubs = [];

        if(response.transport_type) {
          this.transportType = response.transport_type;
        }
        //Group the hubs
        $.each(response.hubs, function (key, value) {
          if (value.availableFor === "intern") {
            internalHubs.push(value);
          } else if (value.availableFor === "extern") {
            externalHubs.push(value);
          }
        });
        this.internalHubs = internalHubs || [];
        this.externalHubs = externalHubs || [];

        if (internalHubs.length) {
          this.typeInternal = 'intern';
        }
        if (externalHubs.length) {
          this.typeExternal = 'extern';
        }
        //Response of Vehicle Crouse type
        this.$log.info('FETCH_VEHICLECOURSETYPE', response);

        this.initStatus();

      }).catch((error) => {
        this.$log.error('FETCH_VEHICLECOURSETYPE', error);
      });
    },

    /**
     * Set active/inactive class
     */
    computedActiveClass(actualValue, expectedValue) {
      return actualValue === expectedValue ? 'active' : '';
    },

    /**
     * Updates the transport type when the button is changed
     */
    saveTransportType(){
      this.$store.dispatch(VEHICLE_COURSE_TYPE_UPDATE_TRANSPORTYPE, {
        vehicleId: this.slug,
        item: {transportType : this.transportType},
      }).then((response) => {
        this.$log.info('VEHICLE_COURSE_TYPE_CREATE_SUBMIT', response);
        this.flashMessage.info({message: 'Tipul Transportului a fost actualizat', time: 4000});
        this.warning = 0;
      }).catch((error) => {
        this.submitStatus = null;
        this.$log.error('VEHICLE_COURSE_TYPE_CREATE_SUBMIT', error);
      });
    },

    /**
     * Save or delete the record in DB when the internal hub button ("DA" or "NU") is changed
     */
    saveOptionForInternalType() {
      if (this.typeInternal === null) { // Delete all hubs for internal type
        this.$confirm("Esti sigur ca vrei sa dezactivezi disponibilitatea ?").then(() => {
          //Delete the last input from page
          for (let key in this.internalHubs) {
            key = parseInt(key);
            let internalHub = this.internalHubs[key];
            if (internalHub['courseTypeId']) {
              this.$store.dispatch(VEHICLE_COURSE_TYPE_DELETE, {
                vehicleId: this.slug,
                courseTypeId: internalHub['courseTypeId']
              })
                .then((response) => {
                  //Update vehicle status
                  if (this.currentVehicleStatus !== 'unavailable') {
                    if (this.internalHubs.length === 0 && this.externalHubs.length === 0) {
                      if (this.currentVehicleStatus) {
                        vehicleCourseTypeMethods.updateStatusToVehicle.call(this, this.slug);
                      }
                    } else if (this.internalHubs.length === 0 && this.externalHubs.length > 0) {
                      if (this.currentVehicleStatus) {
                        vehicleCourseTypeMethods.updateStatusToVehicle.call(this, this.slug);
                      }
                      vehicleCourseTypeMethods.addNewStatusToVehicle.call(this, this.slug, 'extern'); // add new status record
                    }
                  }
                  this.flashMessage.info({message: 'Situatia Autovehicului a fost setata automat', time: 4000});
                  this.saveButtonDisabled = true;
                  this.warning = 0;
                  this.$log.info('VEHICLE_COURSE_TYPE_DELETE', response);
                }).catch((error) => {
                this.$log.error('VEHICLE_COURSE_TYPE_DELETE', error);

              });
            }
          }
          this.internalHubs = []; // Delete the hub from array
        })
          .catch(() => {
            this.typeInternal = 'intern'
          });
      } else { //Save and insert the record into DB
        this.$store.dispatch(VEHICLE_COURSE_TYPE_CREATE_SUBMIT, {
          vehicleId: this.slug,
          item: {type: 'intern',  transportType : this.transportType},
        }).then((response) => {
          this.initVehicleWithHubs();

          //update vehicle status
          if (this.externalHubs.length === 0) {
            if (this.currentVehicleStatus) {
              vehicleCourseTypeMethods.updateStatusToVehicle.call(this, this.slug);
            }
            vehicleCourseTypeMethods.addNewStatusToVehicle.call(this, this.slug, 'intern'); // add new status record
          } else if (this.externalHubs.length > 0) {
            if (this.currentVehicleStatus) {
              vehicleCourseTypeMethods.updateStatusToVehicle.call(this, this.slug);
            }
            vehicleCourseTypeMethods.addNewStatusToVehicle.call(this, this.slug, 'intern/extern'); // add new status record
          }
          this.$log.info('VEHICLE_COURSE_TYPE_CREATE_SUBMIT', response);
          this.flashMessage.info({message: 'Situatia Autovehicului a fost setata automat', time: 4000});
          this.saveButtonDisabled = true;
          this.warning = 0;
        }).catch((error) => {
          this.submitStatus = null;
          this.$log.error('VEHICLE_COURSE_TYPE_CREATE_SUBMIT', error);
        });
      }
      // setTimeout(function () { this.initStatus() }.bind(this), 1000)

    },

    /**
     * Save or delete the record in DB when the external hub button ("DA" or "NU") is changed
     */
    saveOptionForExternalType() {
      if (this.typeExternal === null) { //Delete all hub for external type
        this.$confirm("Esti sigur ca vrei sa dezactivezi disponibilitatea ?").then(() => {
          //Delete the last input from page
          for (let key in this.externalHubs) {
            key = parseInt(key);
            let externalHub = this.externalHubs[key];
            if (externalHub['courseTypeId']) {
              this.$store.dispatch(VEHICLE_COURSE_TYPE_DELETE, {
                vehicleId: this.slug,
                courseTypeId: externalHub['courseTypeId']
              })
                .then((response) => {
                  this.externalHubs = [];
                  //update vehicles status
                  if (this.currentVehicleStatus !== 'unavailable') {
                    if (this.externalHubs.length === 0 && this.internalHubs.length === 0) {
                      if (this.currentVehicleStatus) {
                        vehicleCourseTypeMethods.updateStatusToVehicle.call(this, this.slug);
                      }
                    } else if (this.externalHubs.length === 0 && this.internalHubs.length > 0) {
                      if (this.currentVehicleStatus) {
                        vehicleCourseTypeMethods.updateStatusToVehicle.call(this, this.slug);
                      }
                      vehicleCourseTypeMethods.addNewStatusToVehicle.call(this, this.slug, 'intern'); // add new status record
                    }
                  }
                  this.flashMessage.info({message: 'Situatia Autovehicului a fost setata automat', time: 4000});
                  this.saveButtonDisabled = true;
                  this.warning = 0;
                  this.$log.info('VEHICLE_COURSE_TYPE_DELETE', response);
                  // this.initVehicleWithHubs();
                }).catch((error) => {
                this.$log.error('VEHICLE_COURSE_TYPE_DELETE', error);
              });

            }
          }
        })
          .catch(() => {
            this.typeInternal = 'intern'
          });
      } else { // Save the last external hub record
        this.$store.dispatch(VEHICLE_COURSE_TYPE_CREATE_SUBMIT, {
          vehicleId: this.slug,
          item: {type: 'extern', transportType : this.transportType},

        }).then((response) => {
          this.initVehicleWithHubs();

          //Update the vehicle status
          if (this.internalHubs.length === 0) {
            if (this.currentVehicleStatus) {
              vehicleCourseTypeMethods.updateStatusToVehicle.call(this, this.slug);
            }
            vehicleCourseTypeMethods.addNewStatusToVehicle.call(this, this.slug, 'extern'); // add new status record
          } else if (this.internalHubs.length > 0) {
            if (this.currentVehicleStatus) {
              vehicleCourseTypeMethods.updateStatusToVehicle.call(this, this.slug);
            }
            vehicleCourseTypeMethods.addNewStatusToVehicle.call(this, this.slug, 'intern/extern'); // add new status record
          }
          this.$log.info('VEHICLE_COURSE_TYPE_CREATE_SUBMIT', response);
          this.flashMessage.info({message: 'Situatia Autovehicului a fost setata automat', time: 4000});
          this.saveButtonDisabled = true;
          this.warning = 0;
        }).catch((error) => {
          this.$log.error('VEHICLE_COURSE_TYPE_CREATE_SUBMIT', error);
        });
      }
    },

    /**
     * Save the record in db after the autocomplete option is selected
     */
    selecthubHandler(item) {
      //The item cames from RomfourHub.vue, is the autosugestion selected item
      if (!item) { // The item is false if the selected hub is already associated with the current vehicle
        this.$alert('Hub-ul selectat este deja asociat vehicului ' + this.licencePlate + '!');
      } else {
        //Add the new item into the correct array based on the location type
        if (item.type === "intern") {
          let oldHub = this.internalHubs.pop();
          if (oldHub !== '') {
            item.courseTypeId = oldHub.courseTypeId;
          }
          this.internalHubs.push(item);
        } else if (item.type === "extern") {
          let oldHub = this.externalHubs.pop();
          if (oldHub !== '') {
            item.courseTypeId = oldHub.courseTypeId;
          }
          this.externalHubs.push(item);
        }

        item.transportType = this.transportType;
        //Check if a record is found in the db based on the vehicleId and hubId
        this.$store.dispatch(VEHICLE_COURSE_TYPE_CREATE_SUBMIT, {vehicleId: this.slug, item}).then((response) => {
          this.submitStatus = null;
          this.$log.info('VEHICLE_COURSE_TYPE_CREATE_SUBMIT', response);
          this.initVehicleWithHubs();
          this.$alert('Modificarile au fost salvate!');
        }).catch((error) => {
          this.submitStatus = null;
          this.$log.error('VEHICLE_COURSE_TYPE_CREATE_SUBMIT', error);
        });
      }
    },
    /**
     * Delete record (hub)
     */
    clearField(index, type, courseTypeId, hubName) {
      this.$confirm("Esti sigur ca vrei sa stergi " + hubName + "?").then(() => {
        //Delete the last input from page
        if (type === 'intern') {
          this.internalHubs.splice(index, 1);
          //this statement is used to deactivate the entire internal hub because the row from db is deleted anyway
          if(this.internalHubs.length == 0) {
            this.typeInternal = null;
            this.saveOptionForInternalType();
          }
        } else if (type === 'extern') {
          this.externalHubs.splice(index, 1);
          //this statement is used to deactivate the entire internal hub because the row from db is deleted anyway
          if(this.externalHubs.length == 0) {
            this.typeExternal = null
            this.saveOptionForExternalType();
          }
        }
        if (typeof courseTypeId !== "undefined") {
          this.$store.dispatch(VEHICLE_COURSE_TYPE_DELETE, {vehicleId: this.slug, courseTypeId})
            .then((response) => {
              this.submitStatus = null;
              this.$log.info('VEHICLE_COURSE_TYPE_DELETE', response);
              this.initVehicleWithHubs();
            }).catch((error) => {
            this.submitStatus = null;
            this.$log.error('VEHICLE_COURSE_TYPE_DELETE', error);
          });
        }
      });
    },
    /**
     * The method is used to add new empty input for a new record
     */
    addNewInput(type) {
      if (type === 'NewInternalCourseType' && this.internalHubs.slice(-1)[0] !== '') {
        this.internalHubs.push('');
      } else if (type === 'NewExternalCourseType' && this.externalHubs.slice(-1)[0] !== '') {
        this.externalHubs.push('');
      }
    },

    /**
     * Change manually the vehicle status from Edit Page
     */
    saveVehicleStatusManually() {
      var optionReason = this.unavailableVehicleStatusReason;
      var option = this.vehicleStatus;
      //Available status
      if (option === 'available for transfer') {
        if (this.currentVehicleStatus) {
          vehicleCourseTypeMethods.updateStatusToVehicle.call(this, this.slug);
        }
        vehicleCourseTypeMethods.addNewStatusToVehicle.call(this, this.slug, 'intern');
        this.flashMessage.success({
          message: 'Situatia Autovehicului a fost setata ca "Disponibil pentru transfer"',
          time: 4000
        });
        setTimeout(function () {
          this.warning = 0
        }.bind(this), 4000)
      } else if (option === 'available for course') {
        if (this.currentVehicleStatus) {
          vehicleCourseTypeMethods.updateStatusToVehicle.call(this, this.slug);
        }
        vehicleCourseTypeMethods.addNewStatusToVehicle.call(this, this.slug, 'extern');
        this.flashMessage.success({
          message: 'Situatia Autovehicului a fost setata ca "Disponibil pentru curse"',
          time: 4000
        });
        setTimeout(function () {
          this.warning = 0
        }.bind(this), 4000)
      } else if (option === 'available for transfer and course') {
        if (this.currentVehicleStatus) {
          vehicleCourseTypeMethods.updateStatusToVehicle.call(this, this.slug);
        }
        vehicleCourseTypeMethods.addNewStatusToVehicle.call(this, this.slug, 'intern/extern');
        this.flashMessage.success({
          message: 'Situatia Autovehicului a fost setata ca "Disponibil pentru transfer & curse"',
          time: 4000
        });
        setTimeout(function () {
          this.warning = 0
        }.bind(this), 4000)
        //Unavailable status
      } else if (option === 'unavailable') {
        if (optionReason) { //Check the reason of unavailable status
          var reasonSelectedText = $('#unavailableReason option:selected').text();
          if (this.currentVehicleStatus) {
            vehicleCourseTypeMethods.updateStatusToVehicle.call(this, this.slug);
          }
          vehicleCourseTypeMethods.addNewStatusToVehicle.call(this, this.slug, optionReason);
          this.flashMessage.success({
            message: 'Situatia Autovehicului a fost setata ca "Indisponibil cu motivul: ' + reasonSelectedText + '"',
            time: 4000
          });
          setTimeout(function () {
            this.warning = 0
          }.bind(this), 4000)
        }
      } else {
        this.warning = 3;
      }
    },
    /**
     * Enable the save button to change the status manually
     * Display a warning when the vehicle doesn't have hubs selected for that specific status
     * (the warning messages are in html above the select box)
     * @param option
     */
    selectVehicleStatus(option) {
      if (option === 'available for transfer' && this.internalHubs.length === 0) {
        this.warning = 1;
      } else if (option === 'available for course' && this.externalHubs.length === 0) {
        this.warning = 2;
      } else if (option === 'available for transfer and course' && (this.internalHubs.length === 0 || this.externalHubs.length === 0)) {
        this.warning = 3;
      } else {
        this.warning = 0;
      }
      this.saveButtonDisabled = false;
    }
  },

  watch: {
    slug: {
      handler(val) {
        this.initPageData(val);
      },
    },
  },
};
</script>


<style>
._vue-flash-msg-body {
  position: absolute !important;
  bottom: 75% !important;
  right: 30% !important;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}

.autosuggest__input {
  outline: none;
  position: relative;
  display: block;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

#autosuggest {
  display: inline-block;
}

.autosuggest__input.autosuggest__input-open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

.autosuggest__results {
  font-weight: 300;
  margin: 0;
  position: absolute;
  z-index: 10000001;
  width: 100%;
  border: 1px solid #e0e0e0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background: white;
  padding: 0px;
  max-height: 400px;
  overflow-y: scroll;
}

.autosuggest__results ul {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.autosuggest__results .autosuggest__results-item {
  cursor: pointer;
  padding: 15px;
}

#autosuggest ul:nth-child(1) > .autosuggest__results_title {
  border-top: none;
}

.autosuggest__results .autosuggest__results-before {
  color: gray;
  font-size: 11px;
  margin-left: 0;
  padding: 15px 13px 5px;
  border-top: 1px solid lightgray;
}

.autosuggest__results .autosuggest__results-item:active,
.autosuggest__results .autosuggest__results-item:hover,
.autosuggest__results .autosuggest__results-item:focus,
.autosuggest__results,
.autosuggest__results-item.autosuggest__results-item--highlighted {
  background-color: #f6f6f6;
}
</style>
