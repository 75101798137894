import {
  ADD_VEHICLE_STATUS, FETCH_VEHICLE_STATUS,
  UPDATE_VEHICLE_STATUS,
  VEHICLE_COURSE_TYPE_CREATE_SUBMIT,
  VEHICLE_COURSE_TYPE_DELETE
} from "@/store/actions.type";
import Vue from 'vue';


export default {
  /**
   * Add new vehicle status record
   * @param vehicleId -used to get the current vehicle id
   * @param item - the current option selected
   * @param reason - this param is used when the item is "unavailable", there will be a second option named reason
   */
  addNewStatusToVehicle(vehicleId, item, reason) {
    //Add vehicle status
    this.$store.dispatch(ADD_VEHICLE_STATUS, {vehicleId: vehicleId, item, reason})
      .then((response) => {
        // this.initVehicleWithHubs();
        this.$log.info('ADD_VEHICLE_STATUS', response);
        this.initStatus();
      }).catch((error) => {
      this.submitStatus = null;
      this.$log.error('ADD_VEHICLE_STATUS', error);
    });
  },

  /**
   * Update the latest vehicle status of the current vehicle
   * @param vehicleId
   */
  updateStatusToVehicle(vehicleId) {
    //Add vehicle status
    this.$store.dispatch(UPDATE_VEHICLE_STATUS, {vehicleId: vehicleId})
      .then((response) => {
        // this.initVehicleWithHubs();
        this.$log.info('UPDATE_VEHICLE_STATUS', response);
        this.initStatus();
      }).catch((error) => {
      this.submitStatus = null;
      this.$log.error('UPDATE_VEHICLE_STATUS', error);
    });
  },

  /**
   * Get the current vehicle status and Update the status
   * @param vehicleId
   */
  getCurrentVehicleStatus(vehicleId) {
    this.$store.dispatch(FETCH_VEHICLE_STATUS, vehicleId).then((response) => {
      response = response.data;
      if(response !== null) {
        //Check if the status is available or unavailable
        if (response.includes("unavailable")) {
          //Explode the string to set the value for status select box and for substatus(reason) select box from editpage
          var vehicleStatusArr = response.split('/');
          if (vehicleStatusArr[0]) {
            this.vehicleStatus = vehicleStatusArr[0];
            if (vehicleStatusArr[1]) {
              this.unavailableVehicleStatusReason = response;
            }
          }
        } else {
          this.vehicleStatus = response; //This sets the vehicle status from EditPage
        }
      }
      if(response == null || response.data == 'undefined') {
        this.currentVehicleStatus = false;
      } else if (response.includes("unavailable")) {
        this.currentVehicleStatus = 'unavailable';
      } else {
        this.currentVehicleStatus = true;
      }
    }).catch((error) => {
      this.$log.error('FETCH_VEHICLE', error);
    });
  },
}


