<template>
  <vue-autosuggest
    ref="autocomplete"
    :value="value"
    :suggestions="suggestions"
    :inputProps="{...sugestionsProps, disabled}"
    :renderSuggestion="renderSuggestion"
    :getSuggestionValue="getSuggestionValue"
    :vehicleId="vehicleId"
    @input="fetchSuggestions"
    @selected="onSelectSuggestion"
  />
</template>

<script>
  import { VueAutosuggest } from 'vue-autosuggest';
  import {FETCH_HUBS, GET_COURSE_TYPE_RELATION} from '@/store/actions.type';

  export default {
    name: 'romfour-hub-autosuggest',
    components: {
      VueAutosuggest,
    },
    props: {
      value: String,
      vehicleId: Number,
      type: String,
      disabled: Boolean,
      sugestionsProps: {
        type: Object,
        default() {
          return {
            name: "autosuggest__input__hub",
            class: 'form-control autosuggest__input',
            placeholder: 'Selecteaza nod transfer',
          };
        },
      },
    },
    data: () => ({
      timeout: null,
      debounceMilliseconds: 500,
      selected: null,
      suggestions: [{data: []}],
    }),
    computed: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      }
    },
    methods: {
      onSelectSuggestion(suggestion) {
        let selectdHubId = suggestion.item['id'];
        //Check if the hub already exists in the records
        this.$store.dispatch(GET_COURSE_TYPE_RELATION, {vehicleId: this.vehicleId, hubId: selectdHubId})
          .then((response) => {
            if(response > 0) {
              this.$emit('onSelectSuggestion', false);
            } else {
              this.$emit('onSelectSuggestion', suggestion.item);
            }
            this.$log.info('GET_COURSE_TYPE_RELATION', response);
          }).catch((error) => {
          this.$log.error('GET_COURSE_TYPE_RELATION', error);
        });
      },

      fetchSuggestions(text) {
        clearTimeout(this.timeout);

        if (!text) {
          this.$emit('onSelectSuggestion', null);
          return;
        }

        const filters = {
          status: 1,
          name: text,
          type: this.type,
        };

        this.timeout = setTimeout(() => {
          this.$store.dispatch(FETCH_HUBS, {filters: filters}).then((response) => {
            this.suggestions = [{ data:  response.data}];
            this.$log.info('FETCH_HUBS', response);
          }).catch((error) => {
            this.$log.error('FETCH_HUBS', error);
          });
        }, this.debounceMilliseconds)
      },

      renderSuggestion(suggestion) {
        return `${suggestion.item.name}`;
      },

      getSuggestionValue(suggestion) {
        return `${suggestion.item.name}`;
      },
    },
  };
</script>


<style>
  .autosuggest__input {
    outline: none;
    position: relative;
    display: block;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
  }

  .autosuggest__input.autosuggest__input-open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .autosuggest__results-container {
    position: relative;
    width: 100%;
  }

  .autosuggest__results {
    font-weight: 300;
    margin: 0;
    position: absolute;
    z-index: 10000001;
    width: 100%;
    border: 1px solid #e0e0e0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background: white;
    padding: 0px;
    max-height: 400px;
    overflow-y: scroll;
  }

  .autosuggest__results ul {
    list-style: none;
    padding-left: 0;
    margin: 0;
  }

  .autosuggest__results .autosuggest__results-item {
    cursor: pointer;
    padding: 15px;
  }

  #autosuggest ul:nth-child(1) > .autosuggest__results_title {
    border-top: none;
  }

  .autosuggest__results .autosuggest__results-before {
    color: gray;
    font-size: 11px;
    margin-left: 0;
    padding: 15px 13px 5px;
    border-top: 1px solid lightgray;
  }

  .autosuggest__results .autosuggest__results-item:active,
  .autosuggest__results .autosuggest__results-item:hover,
  .autosuggest__results .autosuggest__results-item:focus,
  .autosuggest__results,
  .autosuggest__results-item.autosuggest__results-item--highlighted {
    background-color: #f6f6f6;
  }
</style>
