import Vue from "vue";
import {
  FETCH_ROUTEDEFINITION,
  FETCH_CRUISES_WITH_VEHICLES_AND_DRIVERS,
  FETCH_SAVED_RESERVATIONS,
  FETCH_RESERVATION_VEHICLE_NAME,
} from "@/store/actions.type";
export default {
  name: 'Cruiseplanningview',

  props: {
    slug: Number | String,
    savedCruiseId: Number,
    reservations: Object | Array,
    routeId: Number | String,
    routeTypeId: Number | String,
    cruiseList: Boolean
  },
  mounted() {
    this.savedCruiseId = this.slug;
    this.initRouteDefinition();
    this.initVehicleAndDriversOnCruise();
    this.initReservations(this.slug);
  },
  data: () => ({
    formData: {
      numeTipRuta: null,
      routeId: null,
      countries: null,
      transportType: null,
      trackType: null,
      identificationCode: null,
      courseTime: null,
      date: null,
      calendar: [],
    },
    cruise_vehicles_drivers: null,
    country_code_component: null,
    country_name_component: null,
    route_type_component: null,
  }),
  methods: {
    //Display the route details
    initRouteDefinition() {
      this.$store.dispatch(FETCH_ROUTEDEFINITION, this.routeTypeId).then((response) => {
        this.formData.numeTipRuta = response.data.numeTipRuta;
        this.formData.routeId = response.data.id;
        this.formData.countries = response.data.country_name;
        this.formData.transportType = (response.data.transportType == 'TR_PERS' ? 'Persoane' : 'Colete');
        this.formData.trackType = response.data.trackType;
        this.country_code_component = response.data.country_code;
        this.country_name_component = response.data.country_name;
        this.route_type_component = (response.data.routeType == 'external') ? 'Extern' : 'Intern';
        this.$log.info('FETCH_ROUTEDEFINITION', response);
      }).catch((error) => {
        this.$log.error('FETCH_ROUTEDEFINITION', error);
      });
    },
    initReservations(cruiseId) {
      this.$store.dispatch(FETCH_SAVED_RESERVATIONS,cruiseId).then((response) => {
        this.reservations = response.data;
        this.$log.info('FETCH_SAVED_RESERVATIONS', response);
      }).catch((error) => {
        this.$log.error('FETCH_SAVED_RESERVATIONS', error);
      });
    },
    //display the cruise vehicles with drivers
    initVehicleAndDriversOnCruise() {
      this.$store.dispatch(FETCH_CRUISES_WITH_VEHICLES_AND_DRIVERS, this.savedCruiseId).then((response) => {
        response = response.data;
        this.cruise_vehicles_drivers = response;
        this.$log.info('FETCH_CRUISES_WITH_VEHICLES_AND_DRIVERS', response);
      }).catch((error) => {
        this.$log.error('FETCH_CRUISES_WITH_VEHICLES_AND_DRIVERS', error);
      });
    },
    goToRoute(routeName) {
      this.$router.push({name: routeName});
    },
  },
  watch: {
    slug: {
      handler(val) {
        this.initPageData(val);
      },
    },
  },
}
