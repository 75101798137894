<template>
  <div class="card pt-3 pl-3">
    <!--HEADER-->

    <div class="listing-page">
      <table class="table table-responsive-lg table-hover" style="table-layout: fixed; width: 100%;">
        <!--<table class="table table-bordered table-responsive-lg table-striped text-nowrap">-->
        <thead class="bg--white">
        </thead>
        <tbody class="text-center">
        <tr class="selected-td font-weight-bolder" v-if="selectedItemsWithPositions.driver1 && selectedItemsWithPositions.driver1.codAng">
          <td
            class="align-top text-center"
            style="word-wrap: normal"
          >
            {{selectedItemsWithPositions.driver1.nume}} {{selectedItemsWithPositions.driver1.prenume}}
          </td>
          <td
            class="align-top text-center"
            style="word-wrap: normal"
          >
            <div class="form-group">
              <select class="form-control mb-3" name="sofer_position_1" v-model="selectedItemsWithPositions.driver1.position">
                <option value=""></option>
                <option v-if="selectedItemsWithPositions.driver2.position == 'principal'
                || selectedItemsWithPositions.driver3.position == 'principal'"  value="principal" disabled>Principal</option>
                <option v-else  value="principal" >Principal</option>
                <option v-if="selectedItemsWithPositions.driver2.position == 'rezerva'
                || selectedItemsWithPositions.driver3.position == 'rezerva'"  value="rezerva" disabled>Rezerva</option>
                <option v-else value="rezerva" >Rezerva</option>
                <option v-if="selectedItemsWithPositions.driver2.position === 'suplimentar'
                || selectedItemsWithPositions.driver3.position === 'suplimentar'" value="suplimentar" disabled>Rezerva 2</option>
                <option v-else  value="suplimentar" >Rezerva 2</option>
              </select>
            </div>
          </td>
          <td>
            <a class="md-raised md-warn" @click="deleteDriver(1)">
              Sterge
            </a>
          </td>
        </tr>
        <tr class="selected-td font-weight-bolder" v-if="selectedItemsWithPositions.driver2 && selectedItemsWithPositions.driver2.codAng">
          <td
            class="align-top text-center"
            style="word-wrap: normal"
          >
            {{selectedItemsWithPositions.driver2.nume}} {{selectedItemsWithPositions.driver2.prenume}}
          </td>
          <td
            class="align-top text-center"
            style="word-wrap: normal"
          >
            <div class="form-group">
              <select class="form-control mb-3" name="sofer_position_1" v-model="selectedItemsWithPositions.driver2.position">
                <option value=""></option>
                <option v-if="selectedItemsWithPositions.driver1.position == 'principal'
                || selectedItemsWithPositions.driver3.position == 'principal'"  value="principal" disabled>Principal</option>
                <option v-else  value="principal" >Principal</option>
                <option v-if="selectedItemsWithPositions.driver1.position == 'rezerva'
                || selectedItemsWithPositions.driver3.position == 'rezerva'"  value="rezerva" disabled>Rezerva</option>
                <option v-else value="rezerva" >Rezerva</option>
                <option v-if="selectedItemsWithPositions.driver1.position === 'suplimentar'
                || selectedItemsWithPositions.driver3.position === 'suplimentar'" value="suplimentar" disabled>Rezerva 2</option>
                <option v-else  value="suplimentar" >Rezerva 2</option>
              </select>
            </div>
          </td>
          <td>
            <a class="md-raised md-warn" @click="deleteDriver(2)">
              Sterge
            </a>
          </td>
        </tr>
        <tr class="selected-td font-weight-bolder" v-if="selectedItemsWithPositions.driver3 && selectedItemsWithPositions.driver3.codAng">
          <td
            class="align-top text-center"
            style="word-wrap: normal"
          >
            {{selectedItemsWithPositions.driver3.nume}} {{selectedItemsWithPositions.driver3.prenume}}
          </td>
          <td
            class="align-top text-center"
            style="word-wrap: normal"
          >
            <div class="form-group">
              <select class="form-control mb-3" name="sofer_position_1" v-model="selectedItemsWithPositions.driver3.position">
                <option value=""></option>
                <option v-if="selectedItemsWithPositions.driver2.position == 'principal'
                || selectedItemsWithPositions.driver1.position == 'principal'"  value="principal" disabled>Principal</option>
                <option v-else  value="principal" >Principal</option>
                <option v-if="selectedItemsWithPositions.driver2.position == 'rezerva'
                || selectedItemsWithPositions.driver1.position == 'rezerva'"  value="rezerva" disabled>Rezerva</option>
                <option v-else value="rezerva" >Rezerva</option>
                <option v-if="selectedItemsWithPositions.driver2.position === 'suplimentar'
                || selectedItemsWithPositions.driver1.position === 'suplimentar'" value="suplimentar" disabled>Rezerva 2</option>
                <option v-else  value="suplimentar" >Rezerva 2</option>
              </select>
            </div>
          </td>
          <td>
            <a class="md-raised md-warn" @click="deleteDriver(3)">
              Sterge
            </a>
          </td>
        </tr>

        <tr class="">
          <td
            class="align-top text-center"
            style="word-wrap: normal"
          >
          </td>
          <td
            class="align-top text-center"
            style="word-wrap: normal"
          >
          </td>
          <td>
            <button class="btn btn-purple btn-block shadow" @click="onSelectDrivers()">
              Salveaza
            </button>
          </td>
        </tr>

        </tbody>
      </table>
    </div>

    <div class="row pt-4">
      <div class="col">
        <div class="card rounded shadow-lg">
          <div class="card-header text-purple">
            Cautare
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label class="text-purple pl-1">
                    Status Sofer
                  </label>
                  <select class="form-control mb-3" id="sofer_status" label="Status Sofer" v-model="filters.driverStatus">
                    <option value=""></option>
<!--                    <option value="inactiv">Inactiv</option>-->
                    <option value="holiday">In Concediu</option>
                    <option value="at-home">Acasa</option>
                    <option value="driving">In cursa</option>
                    <option value="contract-suspendat">Contract Suspendat</option>
                  </select>
                </div>
              </div>

              <div class="col-md">
                <div class="form-group">
                  <label class="text-purple pl-1">
                    Nume Sofer
                  </label>
                  <input id="nume" type="text" class="form-control" placeholder="Nume" v-model="filters.name">
                </div>
              </div>

              <div class="col">
                <div class="form-group">
                  <label class="text-purple pl-1">
                    Data expirarii CI
                  </label>
                  <datepicker :clear-button="true" :bootstrap-styling="true" input-class="form-control" v-model="filters.ciExpirationDate" name="ciExpirationDate"></datepicker>
                </div>
              </div>

              <div class="col">
                <div class="form-group">
                  <label class="text-purple pl-1">
                    Data expirarii Permis
                  </label>
                  <datepicker :clear-button="true" :bootstrap-styling="true" input-class="form-control" v-model="filters.dlExpirationDate" name="dlExpirationDate"></datepicker>
                </div>
              </div>
            </div>
            <div class="row">

              <div class="col">
                <div class="form-group">
                  <label class="text-purple pl-1">
                    Data expirarii Aviz Medical
                  </label>
                  <datepicker :clear-button="true" :bootstrap-styling="true" input-class="form-control" v-model="filters.medExpirationDate" name="medExpirationDate"
                  ></datepicker>
                </div>
              </div>

              <div class="col">
                <div class="form-group">
                  <label class="text-purple pl-1">
                    Data expirarii Aviz Psihologic
                  </label>
                  <datepicker :clear-button="true" :bootstrap-styling="true" input-class="form-control" v-model="filters.psiExpirationDate" name="psiExpirationDate"
                  ></datepicker>
                </div>
              </div>

              <div class="col">
                <div class="form-group">
                  <label class="text-purple pl-1">
                    Data expirarii Card Tahograf
                  </label>
                  <datepicker :clear-button="true" :bootstrap-styling="true" input-class="form-control" v-model="filters.cardTahExpirationDate" name="cardTahExpirationDate"
                  ></datepicker>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-2">
                <button class="btn btn-purple btn-block shadow" @click="onSearch()">Cauta</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-body rounded shadow-lg">
        <!--TABLE-->
        <table class="table table-responsive-lg table-hover t-vehicles" style="table-layout: fixed; width: 100%;">
          <!--<table class="table table-bordered table-responsive-lg table-striped text-nowrap">-->
          <thead class="bg--white">
          <tr>
            <th class="text-purple align-top text-center" style="word-wrap: normal" @click="onSort('codAng')">
              <div class="sortable" :class="[currentSort.column === 'codAng' ? this.currentSort.dir : '']">
                ID
              </div>
            </th>
            <th class="text-purple align-top text-center" style="word-wrap: normal">
              Nr. contract
            </th>
            <th class="text-purple align-top text-center" style="word-wrap: normal">
              Nume
            </th>
            <th class="text-purple align-top text-center" style="word-wrap: normal">
              Telefon 1
            </th>
            <th class="text-purple align-top text-center" style="word-wrap: normal">
              Tip permis
            </th>
            <th class="text-purple align-top text-center" style="word-wrap: normal">
              Perioada de proba
            </th>
            <th class="text-purple align-top text-center" style="word-wrap: normal">
              Status
            </th>
            <th class="text-purple align-top text-center" style="word-wrap: normal" @click="onSort('lastArrivalTime')">
              <div class="sortable" :class="[currentSort.column === 'lastArrivalTime' ? this.currentSort.dir : '']">
                Zile acasa
              </div>
            </th>
          </tr>
          </thead>
          <tbody class="text-center">
          <tr v-for="driver in drivers" :class="[selectedIds.includes(driver.codAng) ? 'selected-td font-weight-bolder':'', driverStatusClass(driver)]"
              @click="onSelect($event, driver)"
          >
            <td class="align-top text-center" style="word-wrap: normal">
              {{ driver.codAng}}
            </td>
            <td class="align-top text-center" style="word-wrap: normal">
              {{ driver.numarContract}}
            </td>
            <td class="align-top text-center" style="word-wrap: normal">
              {{ driver.prenume}}  {{ driver.nume}}
            </td>
            <td class="align-top text-center" style="word-wrap: normal">
              {{ driver.telefon}}
            </td>
            <td class="align-top text-center" style="word-wrap: normal">
              {{ driver.drivingLicenceType}}
            </td>
            <td class="align-top text-center" style="word-wrap: normal">
              {{ driverProbationEnded(driver)}}
            </td>
            <td class="align-top text-center" style="word-wrap: normal">
              {{ showDriverStatus(driver)}}
            </td>
            <td class="align-top text-center" style="word-wrap: normal">
              {{ driver.daysAtHome}}
            </td>
          </tr>

          </tbody>
        </table>


        <!--PAGINATION-->
        <div class="md-layout">
          <div class="md-layout-item md-size-50">
            {{ driversTotal }} soferi (de la {{ driversFrom }} pana la {{ driversTo }})
          </div>
          <div class="md-layout-item md-size-50">
            <rom-paginator :current-page="driversCurrentPage"
                           :last-page="driversLastPage"
                           :threshold="2"
                           :has-prev-next="true"
                           :has-first-last="true"
                           @onFirstPage="onFirstPage"
                           @onPreviousPage="onPreviousPage"
                           @onNextPage="onNextPage"
                           @onLastPage="onLastPage"
                           @onChangePage="onChangePage">
            </rom-paginator>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
  import ListingPage from '@/components/page-templates/ListingPage.vue';
  import RomPaginator from '@/components/pagination/RomPaginator.vue';
  import {
    FETCH_DRIVERS,
    UPDATE_ACTIVE_SORT_FIELD_DRIVERS,
    UPDATE_SEARCH_DRIVERS,
    CLEAR_SEARCH_DRIVERS, ROUTE_FILES_INIT_EDIT_DATA,
  } from '@/store/actions.type';
  import moment from 'moment';
  import Datepicker from 'vuejs-datepicker';
  import router from "@/router";

  export default {
    name: 'SelectDrivers',
    components: {
      ListingPage,
      RomPaginator,
      Datepicker,
    },
    props: {
      selectedItemsWithPositions: {
        type: Object,
        default() {
          return {
            driver1: {},
            driver2: {},
            driver3: {},
          }
        }
      }
    },
    data: () => ({
      selectedIds: [],
      filters: {
        driverStatus: null,
        name: null,
        ciExpirationDate: null,
        dlExpirationDate: null,
        psiExpirationDate: null,
        cardTahExpirationDate: null,
        medExpirationDate: null,
      },
      currentSort: {column: 'codAng', dir: 'desc'},
    }),
    computed: {
      drivers() {
        return this.$store.getters.driversItems;
      },
      driversPerPage() {
        return this.$store.getters.driversPerPage;
      },
      driversCurrentPage() {
        return this.$store.getters.driversCurrentPage;
      },
      driversLastPage() {
        return this.$store.getters.driversLastPage;
      },
      driversFrom() {
        return this.$store.getters.driversFrom;
      },
      driversTo() {
        return this.$store.getters.driversTo;
      },
      driversTotal() {
        return this.$store.getters.driversTotal;
      },
      driversSortFields() {
        return this.$store.getters.driversSortFields;
      },
      driversActiveSort() {
        return this.$store.getters.driversActiveSort;
      },
      driversSearch() {
        return this.$store.getters.driversSearch;
      },
    },
    mounted() {
      this.initDrivers();
    },
    methods: {
      deleteDriver(poz) {
        let driverId = null;

        if (poz === 1) {
          driverId = this.selectedItemsWithPositions.driver1.codAng;
          this.selectedItemsWithPositions.driver1 = {};
        }
        if (poz === 2) {
          driverId = this.selectedItemsWithPositions.driver2.codAng;
          this.selectedItemsWithPositions.driver2 = {};
        }
        if (poz === 3) {
          driverId = this.selectedItemsWithPositions.driver3.codAng;
          this.selectedItemsWithPositions.driver3 = {};
        }
        this.selectedIds = this.selectedIds.filter( value => driverId != driverId);
      },
      getClass(item) {
        if (item.enabled === 0) return 'page-enabled';
        if (item.enabled === 1) return 'page-disabled';
        return '';
      },
      getItemProperty(item, property) {
        const properties = property.split('.');
        while(properties.length && (item = item[properties.shift()]));
        return item;
      },
      onSelect(event, item) {
        if (this.selectedIds.includes(item.codAng) || (
          this.selectedItemsWithPositions.driver1 && this.selectedItemsWithPositions.driver1.codAng
          &&  this.selectedItemsWithPositions.driver2  && this.selectedItemsWithPositions.driver2.codAng
          &&  this.selectedItemsWithPositions.driver3  && this.selectedItemsWithPositions.driver3.codAng)) {
          return
        }

        this.selectedIds.push(item.codAng);

        if (!this.selectedItemsWithPositions.driver1 || !this.selectedItemsWithPositions.driver1.codAng) {
          this.selectedItemsWithPositions.driver1 = {...item, position: null};
          return;
        }

        if (!this.selectedItemsWithPositions.driver2 || !this.selectedItemsWithPositions.driver2.codAng) {
          this.selectedItemsWithPositions.driver2 = {...item, position: null};
          return;
        }

        if (!this.selectedItemsWithPositions.driver3 || !this.selectedItemsWithPositions.driver3.codAng) {
          this.selectedItemsWithPositions.driver3 = {...item, position: null};
          return;
        }
      },
      onSelectDrivers() {
        if( this.selectedItemsWithPositions.driver1.position != 'principal'
          && this.selectedItemsWithPositions.driver2.position != 'principal'
          &&  this.selectedItemsWithPositions.driver3.position != 'principal')
        {
          this.$alert("Nu exista un sofer principal! Te rog aduaga un sofer principal!");
        } else {
          //This statement removes the position name from routeFileEditPage drivers list
          if (typeof this.selectedItemsWithPositions.driver1.codAng === 'undefined') {
            this.selectedItemsWithPositions.driver1.position = null;
          } else if (typeof this.selectedItemsWithPositions.driver2.codAng === 'undefined') {
            this.selectedItemsWithPositions.driver2.position = null;
          } else if (typeof this.selectedItemsWithPositions.driver3.codAng === 'undefined') {
            this.selectedItemsWithPositions.driver3.position = null;
          }
          this.$emit('onSelectDrivers', this.selectedItemsWithPositions);
        }
      },
      isNumeric(item, property) {
        return !isNaN(this.getItemProperty(item, property));
      },
      initDrivers() {
        const params = {
          page: this.driversCurrentPage,
          perPage: this.driversPerPage,
          filters: this.filters,
          sort: this.currentSort,
        };
        this.fetchDrivers(params);
      },
      onSearch() {
        let query = {
          page: 1,
          perPage: this.perPage,
          filters: this.filters,
          sort: this.currentSort,
        };

        this.fetchDrivers(query);
      },
      fetchDrivers(params) {
        this.filters.withUserStatus = 1;
        this.filters.employmentStatus = 'hired';

        this.$store.dispatch(FETCH_DRIVERS, params).then((response) => {

          this.$log.info('FETCH_DRIVERS', response);
        }).catch((error) => {
          this.$log.error('FETCH_DRIVERS', error);
        });
      },
      onFirstPage() {
        const params = {
          page: 1,
          perPage: this.driversPerPage,
        };
        this.fetchDrivers(params);
      },
      onPreviousPage() {
        const params = {
          page: this.driversCurrentPage - 1,
          perPage: this.driversPerPage,
          filters: this.filters,
          sort: this.currentSort,
        };
        this.fetchDrivers(params);
      },
      onChangePage(data) {
        const params = {
          page: data,
          perPage: this.driversPerPage,
          filters: this.filters,
          sort: this.currentSort,
        };
        this.fetchDrivers(params);
      },
      onNextPage() {
        const params = {
          page: this.driversCurrentPage + 1,
          perPage: this.driversPerPage,
          filters: this.filters,
          sort: this.currentSort,
        };
        this.fetchDrivers(params);
      },
      onLastPage() {
        const params = {
          page: this.driversLastPage,
          perPage: this.driversPerPage,
          filters: this.filters,
          sort: this.currentSort,
        };
        this.fetchDrivers(params);
      },
      clearsearch() {
        this.$store.dispatch(CLEAR_SEARCH_DRIVERS).then(() => {
          const params = {
            page: 1,
            perPage: this.driversPerPage,
            filters: this.filters,
            sort: this.currentSort,
          };
          this.fetchDrivers(params);
        }).catch((error) => {
          this.$log.error('CLEAR_SEARCH_DRIVERS', error);
        });
      },

      toDate(datetime) {
        return datetime ? moment(datetime).format('DD/MM/YYYY') : '';
      },
      toDatetime(datetime) {
        return datetime ? moment(datetime).format('DD/MM/YYYY, h:mm:ss') : '';
      },

      documentAvailabilityClass(datetime) {
        if (!datetime) return '';

        if (moment(datetime).isBefore(moment().add(1, 'week'))) return 'document-expires';

        if (moment(datetime).isBefore(moment().add(1, 'month'))) return 'document-warning';

        return 'document-ok';
      },

      driverStatusClass(driver) {
        if (driver.driverStatus === 'inactiv') return 'inactiv';

        var driverLeaveArr = String(driver.leave).split('/'); //Create an array if we have multiple leaves
        //Change the color based on what status have the driver
        if (!driver.lastArrivalTime || driver.daysAtHome >= 4) {
          if(driverLeaveArr.includes('concediu-odihnal') || driverLeaveArr.includes('concediu-medical') || driverLeaveArr.includes('contract-suspendat')) {
            return 'status-suspendare';
          } else {
            return 'status-error';
          }
        }

        if (driver.daysAtHome >= 2) {
          return 'status-warning'
        }

        return 'status-ok';
      },

      showDriverStatus(driver) {
        if (driver.driverStatus === 'inactiv') return 'Inactiv';

        var leaves = driver.leave;
        if (driver.lastArrivalTime && driver.daysAtHome < 0) {
          return 'In cursa';
        } else if(driver.leave) {
          return leaves;
        }

        return 'Acasa';
      },
      driverProbationEnded(driver) {
        if (!driver.dataAngajare) return '-';

        return moment().diff(moment(driver.dataAngajare), 'days') > 90 ? 'Nu' : 'Da';
      },

    },
  };
</script>
