const vehicleStatusValues = {
  transfer: 'available for transfer',
  course: 'available for course',
  transferAndCourse: 'available for transfer and course',
  damaged: 'unavailable/damaged',
  broken: 'unavailable/broken',
  withoutDriver: 'unavailable/withoutDriver',
  withoutDocuments: 'unavailable/withoutDocuments',
  service: 'unavailable/service',
  ontrack: 'unavailable/ontrack',
  unavailable: 'unavailable'
};

export default vehicleStatusValues;
