<template>
  <vue-autosuggest
    ref="autocomplete"
    v-model="value"
    :suggestions="suggestions"
    :inputProps="sugestionsProps"
    :renderSuggestion="renderSuggestion"
    :getSuggestionValue="getSuggestionValue"
    @input="fetchSuggestions"
    @selected="onSelectSuggestion"
  />
</template>

<script>
  import { VueAutosuggest } from 'vue-autosuggest';
  import { FETCH_COMPANY_BRANCHES } from '@/store/actions.type';

  export default {
    name: 'romfour-company-branch-autosuggest',
    components: {
      VueAutosuggest,
    },
    props: {
      value: String,
      companyId: Number|String,
      sugestionsProps: {
        type: Object,
        default() {
          return {
            name: "autosuggest__input__branch",
            class: 'form-control autosuggest__input',
            placeholder: 'Type...'
          };
        },
      },
    },
    data: () => ({
      timeout: null,
      debounceMilliseconds: 500,
      selected: null,
      suggestions: [{data: []}]
    }),
    computed: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      }
    },
    mounted() {
    },
    methods: {
      onSelectSuggestion(suggestion) {
          this.$emit('onSelectSuggestion', suggestion.item);
      },

      fetchSuggestions(text) {
        clearTimeout(this.timeout);

        if (!text) {
          this.$emit('onSelectSuggestion', null);
          return;
        }

        const filters = {
          denumire: text,
          codSoc: this.companyId
        };

        this.timeout = setTimeout(() => {
          this.$store.dispatch(FETCH_COMPANY_BRANCHES, {filters: filters}).then((response) => {
            this.suggestions = [{ data:  response.data}];
            this.$log.info('FETCH_COMPANY_BRANCHES', response);
          }).catch((error) => {
            this.$log.error('FETCH_COMPANY_BRANCHES', error);
          });
        }, this.debounceMilliseconds)
      },

      renderSuggestion(suggestion) {
        return suggestion.item.denumire;
      },

      getSuggestionValue(suggestion) {
        return suggestion.item.denumire;
      },
    },
  };
</script>


<style>
  .autosuggest__input {
    outline: none;
    position: relative;
    display: block;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
  }

  .autosuggest__input.autosuggest__input-open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .autosuggest__results-container {
    position: relative;
    width: 100%;
  }

  .autosuggest__results {
    font-weight: 300;
    margin: 0;
    position: absolute;
    z-index: 10000001;
    width: 100%;
    border: 1px solid #e0e0e0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background: white;
    padding: 0px;
    max-height: 400px;
    overflow-y: scroll;
  }

  .autosuggest__results ul {
    list-style: none;
    padding-left: 0;
    margin: 0;
  }

  .autosuggest__results .autosuggest__results-item {
    cursor: pointer;
    padding: 15px;
  }

  #autosuggest ul:nth-child(1) > .autosuggest__results_title {
    border-top: none;
  }

  .autosuggest__results .autosuggest__results-before {
    color: gray;
    font-size: 11px;
    margin-left: 0;
    padding: 15px 13px 5px;
    border-top: 1px solid lightgray;
  }

  .autosuggest__results .autosuggest__results-item:active,
  .autosuggest__results .autosuggest__results-item:hover,
  .autosuggest__results .autosuggest__results-item:focus,
  .autosuggest__results,
  .autosuggest__results-item.autosuggest__results-item--highlighted {
    background-color: #f6f6f6;
  }
</style>
