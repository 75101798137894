<template>
  <div>
    <div class="row pt-5 pb-3">
      <div class="col">
        <h1 class="text-purple pl-1">Sofer</h1>
      </div>
    </div>

    <form action="" @submit="onSubmitDriver(formData)" @submit.prevent="onSubmit" enctype="multipart/form-data" method="post">
      <div class="card rounded card-custom shadow-lg">
        <div class="card-header">
          <h4 class="text-purple">Date Personale</h4>
        </div>
        <div>
          <div class="card-body">
            <div class="form-row">
              <div class="form-group col-md-4 pr-md-5 col-xs-12">
                <label class="text-purple pl-1">Societate</label>
                <select id="societate"
                        class="form-control select2-hidden-accessible select2-dropdown d-block"
                        tabindex="-1"
                        aria-hidden="true"
                        required
                        v-model="formData.employee.companyId"
                >
                  <option value="1">SC ROMFOUR TUR S.R.L</option>
                </select>
                <small class="form-text pl-1 error" v-if="submitStatus && !$v.formData.employee.companyId.required">Acest camp este obligatoriu</small>
              </div>
              <div class="form-group col-md-4 pr-md-4 pl-md-4">
                <label class="text-purple pl-1">Punctul de lucru</label>
                <romfour-company-branch-autosuggest
                  v-model="selectedCompanyBranchName"
                  :companyId="formData.employee.companyId"
                  @onSelectSuggestion="onSelectCompanyBranchId"
                />
                <small class="form-text pl-1 error" v-if="submitStatus && !$v.formData.employee.companyBranchId.required">Acest camp este obligatoriu</small>
              </div>
              <div class="form-group col-md-4 pl-md-5">
                <label class="text-purple pl-1">Sectia</label>
                <romfour-company-department-autosuggest
                  v-model="selectedCompanyDepartmentName"
                  :companyId="formData.employee.companyId"
                  :branchId="formData.employee.companyBranchId"
                  @onSelectSuggestion="onSelectCompanyDepartmentId"
                />
                <small class="form-text pl-1 error" v-if="submitStatus && !$v.formData.employee.departmentId.required">Acest camp este obligatoriu</small>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-4 pr-5">
                <label class="text-purple pl-1">Nume</label>
                <input id="nume" type="text" class="form-control" v-model="formData.employee.lastName" required>
                <small class="form-text pl-1 error" v-if="submitStatus && !$v.formData.employee.lastName.required">Acest camp este obligatoriu</small>
              </div>
              <div class="form-group col-md-4 pr-4 pl-md-4">
                <label class="text-purple pl-1">Prenume
                </label>
                <input id="prenume" type="text" class="form-control" v-model="formData.employee.firstName" required>
                <small class="form-text pl-1 error" v-if="submitStatus && !$v.formData.employee.firstName.required">Acest camp este obligatoriu</small>
              </div>

              <div class="form-group col-md-4 flex-column flex pl-md-5">
                <label class="text-purple d-block pl-1">Sex
                </label>
                <div class="btn-group custom-btn-group btn-group-toggle" data-toggle="buttons">
                  <label class="btn" :class="computedActiveClass(formData.employee.gender, 'masculin')">Masculin
                    <input type="radio" name="sex" value="masculin" autocomplete="off" v-model="formData.employee.gender">
                  </label>
                  <label class="btn" :class="computedActiveClass(formData.employee.gender, 'feminin')">Feminin
                    <input type="radio" name="sex" value="feminin" autocomplete="off" v-model="formData.employee.gender">
                  </label>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-4 pr-5">
                <label class="text-purple pl-1">Username
                </label>
                <input id="username" type="text" class="form-control" v-model="formData.user.username" :disabled="!canFillUsername">
                <small class="form-text pl-1 error" v-if="submitStatus && !$v.formData.user.username.required">Acest camp este obligatoriu</small>
                <small class="form-text pl-1 error" v-if="invalidFieldsErrors.username">Numele de utilizator este luat deja</small>
              </div>
              <div class="form-group col-md-4 pr-4 pl-md-4" >
                <label class="text-purple pl-1">Parola</label>
                <input id="pass" type="text" class="form-control" v-model="formData.user.password" required>
              </div>
            </div>

            <div class="form-row">

              <div class="form-group col-md-4 pr-5">
                <label class="text-purple pl-1">CNP
                </label>
                <input id="cnp" type="number" class="form-control" v-model="formData.documents.ci.cnp" required>
                <small class="form-text pl-1 error" v-if="submitStatus && !$v.formData.documents.ci.cnp.required">Acest camp este obligatoriu</small>
              </div>
              <div class="form-group col-md-4 pr-4 pl-md-4">
                <label class="text-purple pl-1">Serie CI</label>
                <input id="serieCI" type="text" class="form-control" v-model="formData.documents.ci.series" required>
                <small class="form-text pl-1 error" v-if="submitStatus && !$v.formData.documents.ci.series.required">Acest camp este obligatoriu</small>
              </div>
              <div class="form-group col-md-4 pr-4 pl-md-4">
                <label class="text-purple pl-1">
                  Numar CI
                </label>
                <input id="numarCI" type="number" class="form-control" v-model="formData.documents.ci.number" required>
                <small class="form-text pl-1 error" v-if="submitStatus && !$v.formData.documents.ci.number.required">Acest camp este obligatoriu</small>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-4 pr-5">
                <label class="text-purple pl-1">Numar Personal</label>
                <input id="phoneNo" type="text" class="form-control" v-model="formData.employee.phoneNo" required>
                <small class="form-text pl-1 error" v-if="submitStatus && !$v.formData.employee.phoneNo.required">Acest camp este obligatoriu</small>
              </div>
              <div class="form-group col-md-2 pr-4 pl-md-4">
                <label class="text-purple pl-1">Cod Tara</label>
                <input id="tara" type="text" class="form-control" v-model="formData.employee.address.countryId" required>
                <small class="form-text pl-1 error" v-if="submitStatus && !$v.formData.employee.address.countryId.required">Acest camp este obligatoriu</small>
                <small class="form-text pl-1 error" v-if="submitStatus && !$v.formData.employee.address.countryId.maxLength">Doar 2 caractere</small>
              </div>
              <div class="form-group col-md-2 pr-4 pl-md-4">
                <label class="text-purple pl-1">
                  Cod Judet
                </label>
                <input id="judet" type="text" class="form-control" v-model="formData.employee.address.countyId" required>
                <small class="form-text pl-1 error" v-if="submitStatus && !$v.formData.employee.address.countyId.maxLength">Doar 2 caractere</small>
              </div>
              <div class="form-group col-md-4 pl-md-5">
                <label class="text-purple pl-1">
                  Localitate
                </label>
                <romfour-region-autosuggest
                  v-model="selectCityName"
                  @onSelectSuggestion="onSelectCityId"
                />

              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-4 pr-5">
                <label class="text-purple pl-1">Strada</label>
                <input id="street" type="text" class="form-control" v-model="formData.employee.address.street" required>

              </div>
              <div class="form-group col-md-2 pr-4 pl-md-4">
                <label class="text-purple pl-1">Numar</label>
                <input id="adresa" type="text" class="form-control" v-model="formData.employee.address.streetNo" required>

              </div>
              <div class="form-group col-md-2 pr-4 pl-md-4">
                <label class="text-purple pl-1">Bloc</label>
                <input id="building" type="text" class="form-control" v-model="formData.employee.address.building" required>

              </div>
              <div class="form-group col-md-1 pl-md-5 ">
                <label class="text-purple pl-1">Scara</label>
                <input id="entrance" type="text" class="form-control" v-model="formData.employee.address.entrance" required>

              </div>
              <div class="form-group col-md-1 ml-1 pr-5">
                <label class="text-purple pl-1">Etaj</label>
                <input id="floor" type="text" class="form-control" v-model="formData.employee.address.floor" required>

              </div>
              <div class="form-group col-md-1 ml-1 pr-5">
                <label class="text-purple pl-1">Apartament</label>
                <input id="apartment" type="text" class="form-control" v-model="formData.employee.address.apartment" required>
              </div>
            </div>
            <div class="form-row">
            <div class="form-group col-md-4 pr-5">
                <label class="text-purple pl-1">
                  Data expirarii CI
                </label>
                <datepicker :clear-button="true" :bootstrap-styling="true" input-class="form-control" v-model="formData.documents.ci.expirationDate" name="ciExpirationDate"></datepicker>
              <small class="form-text pl-1 error" v-if="submitStatus && !$v.formData.documents.ci.expirationDate.required">Acest camp este obligatoriu</small>
            </div>
              <div class="form-group col-md-4 pr-4 pl-md-4">
                <label class="text-purple pl-1">
                  Upload CI
                </label>
                <div class="custom-file">
                  <input type="file" class="custom-file-input" id="ci-file" ref="ci"  v-on:change="onFileChange($event, 'ci')"/>
                  <label class="custom-file-label" for="ci-file">
                    <span v-if="formData.documents.ci.file">{{formData.documents.ci.file.name}}</span>
                    <span v-if="!formData.documents.ci.file">Incarca fisier</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-8">
          <div class="row">
            <div class="col-md-6">
              <!-- Permis -->
              <div class="card rounded card-custom shadow-lg">
                <div class="card-header text-purple">
                  <strong class="pl-1 font-weight-bold">Permis</strong>
                </div>
                <div class="card-body">
                  <div class="form-group">
                    <label class="text-purple pl-1">
                      Data expirarii Permis
                    </label>
                    <datepicker :clear-button="true" :bootstrap-styling="true" input-class="form-control" v-model="formData.documents.drivingLicence.expirationDate" name="licenceExpirationDate"></datepicker>
                    <small class="form-text pl-1 error" v-if="submitStatus && !$v.formData.documents.drivingLicence.expirationDate.required">Acest camp este obligatoriu</small>
                  </div>
                  <div class="form-group text-purple">
                    <label class="pl-1">Tip permis</label>
                    <select id="tipPermis"
                            class="form-control select2-hidden-accessible select2-dropdown d-block"
                            tabindex="-1"
                            aria-hidden="true"
                            required
                            v-model="formData.documents.drivingLicence.type"
                    >
                      <option value="B">B</option>
                      <option value="D">D</option>
                      <option value="B + E">B + E</option>
                      <option value="D + E">D + E</option>
                      <option value="B + C + E">B + C + E</option>
                      <option value="B + C + D">B + C + D</option>
                      <option value="B + C + D + E">B + C + D + E</option>
                      <option value="D + 1">D + 1</option>
                    </select>
                    <small class="form-text pl-1 error" v-if="submitStatus && !$v.formData.documents.drivingLicence.type.required">Acest camp este obligatoriu</small>                  </div>
                  <div class="form-group">
                    <label class="text-purple pl-1">
                      Upload Permis
                    </label>
                    <div class="custom-file">
                      <input type="file" class="custom-file-input" id="dlicence-file" ref="drivingLicence"  v-on:change="onFileChange($event, 'drivingLicence')"/>
                      <label class="custom-file-label" for="dlicence-file">
                        <span v-if="formData.documents.drivingLicence.file">{{formData.documents.drivingLicence.file.name}}</span>
                        <span v-if="!formData.documents.drivingLicence.file">Incarca fisier</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <!-- Atribuire functie sofer -->
              <div class="card rounded card-custom shadow-lg">
                <div class="card-header text-purple">
                  <strong class="pl-1  font-weight-bold">Contract de munca</strong><span
                  class="float-right">90 zile</span>
                </div>
                <div class="card-body">
                  <div class="form-group">
                    <label class="text-purple pl-1">
                      Data start
                    </label>
                    <datepicker :clear-button="true" :bootstrap-styling="true" input-class="form-control" v-model="formData.trialPeriod.startedAt" name="trialPeriodStartDate"></datepicker>

                  </div>
                  <div class="form-group">
                    <label class="text-purple pl-1">
                      Data expirarii perioada proba
                    </label>
                    <datepicker :clear-button="true" :bootstrap-styling="true" input-class="form-control" v-model="formData.trialPeriod.endedAt" name="trialPeriodEndDate" ></datepicker>

                  </div>
                  <div class="form-group">
                    <label class="text-purple pl-1">
                      Document Contract de Munca
                    </label>
                    <div class="custom-file">
                      <input type="file" class="custom-file-input" id="contractDeMunca-file" ref="atVignieta"  v-on:change="onFileChange($event, 'contractDeMunca')"/>
                      <label class="custom-file-label" for="contractDeMunca-file">
                        <span v-if="formData.documents.contractDeMunca.file">{{formData.documents.contractDeMunca.file.name}}</span>
                        <span v-if="!formData.documents.contractDeMunca.file">Incarca fisier</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <!-- Aviz psihologic si medical-->
            <div class="card rounded card-custom shadow-lg">
              <div class="card-header text-purple">
                <div class="row">
                  <div class="col">
                    <strong class="pl-1  font-weight-bold">Aviz psihologic</strong>
                  </div>
                  <div class="col">
                    <strong class="pl-md-4  font-weight-bold">Aviz medical</strong>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col pr-5">
                    <div class="form-group">
                      <label class="text-purple pl-1">
                        Data expirarii
                      </label>
                      <datepicker :clear-button="true" :bootstrap-styling="true" input-class="form-control" v-model="formData.documents.psihologicalCertificate.expirationDate" name="psCertExpDate"></datepicker>


                    </div>
                    <div class="form-group">
                      <label class="text-purple pl-1">
                        Upload aviz psihologic
                      </label>
                      <div class="custom-file">

                        <input type="file" class="custom-file-input" id="psc-file" ref="psihologicalCertificate"  v-on:change="onFileChange($event, 'psihologicalCertificate')"/>
                        <label class="custom-file-label" for="psc-file">
                          <span v-if="formData.documents.psihologicalCertificate.file">{{formData.documents.psihologicalCertificate.file.name}}</span>
                          <span class="d-none d-sm-block" v-if="!formData.documents.psihologicalCertificate.file">Incarca fisier</span>
                        </label>
                      </div>
                      </div>
                    </div>
                  <div class="col">
                    <div class="form-group">
                      <label class="text-purple pl-1">
                        Data expirarii
                      </label>
                      <datepicker :clear-button="true" :bootstrap-styling="true" input-class="form-control" v-model="formData.documents.medicalCertificate.expirationDate" name="medCertExpDate"></datepicker>


                    </div>
                    <div class="form-group">
                      <label class="text-purple pl-1">
                        Upload aviz medical
                      </label>
                      <div class="custom-file">
                        <input type="file" class="custom-file-input" id="medc-file"  ref="medicalCertificate"  v-on:change="onFileChange($event, 'medicalCertificate')"/>
                        <label class="custom-file-label" for="medc-file">
                          <span v-if="formData.documents.medicalCertificate.file">{{formData.documents.medicalCertificate.file.name}}</span>
                          <span class="d-none d-sm-block" v-if="!formData.documents.medicalCertificate.file">Incarca fisier</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <!-- COR -->
            <div class="col-md-6">
              <div class="card rounded card-custom shadow-lg">
                <div class="card-header">
                  <strong class="pl-1 text-purple  font-weight-bold">COR</strong>
                </div>
                <div class="card-body">
                  <div class="form-group">
                    <label class="text-purple pl-1">Versiune</label>
                    <select id="CORversion"
                            class="form-control select2-hidden-accessible select2-dropdown d-block"
                            tabindex="-1"
                            aria-hidden="true"
                            v-model="formData.employee.corVersion"
                    >
                      <option hidden disabled selected>Alege optiune</option>
                      <option value="0">Alege versiune</option>
                      <option value="1">COR 1</option>
                      <option value="2">COR 2</option>
                      <option value="3">COR 3</option>
                      <option value="4">COR 4</option>
                      <option value="5">COR 5</option>
                      <option value="6">COR 6</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label class="text-purple pl-1">Cod</label>
                    <input id="CORcode" type="text" class="form-control" required v-model="formData.employee.codCor6"/>

                  </div>
                </div>
              </div>
            </div>
            <!-- Card tahograf -->
            <div class="col-md-6">
              <div class="card rounded card-custom shadow-lg">
                <div class="card-header">
                  <strong class="pl-1 text-purple font-weight-bold">Card tahograf</strong>
                </div>
                <div class="card-body">
                  <div class="form-group">
                    <label class="text-purple pl-1">
                      Data expirarii
                    </label>
                    <datepicker :clear-button="true" :bootstrap-styling="true" input-class="form-control" v-model="formData.documents.cardTahograf.expirationDate" name="tahografExpDate"></datepicker>

                  </div>
                  <div class="form-group">
                    <label class="text-purple pl-1">Serie document</label>
                    <input id="serieCardTahograf" type="text" class="form-control" v-model="formData.documents.cardTahograf.series" required>

                  </div>
                  <div class="form-group">
                    <label class="text-purple pl-1">
                      Perioada descarcata
                    </label>
                    <div class="input-group mb-3">
                      <input type="text" class="form-control" v-model="formData.documents.cardTahograf.downloadedPeriod"
                             id="downloadPeriodTahograf" required>

                      <div class="input-group-append">
                        <span class="input-group-text text-purple">zile</span>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="text-purple pl-1">
                      Upload document
                    </label>
                    <div class="custom-file">
                      <input type="file" class="custom-file-input" id="tahograf-file" ref="tahograf"  v-on:change="onFileChange($event, 'cardTahograf')"/>
                      <label class="custom-file-label">
                        <span v-if="formData.documents.cardTahograf.file">{{formData.documents.cardTahograf.file.name}}</span>
                        <span v-if="!formData.documents.cardTahograf.file">Incarca fisier</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
<!--        </div>-->
        <!-- Atestate -->
        <div class="col-md-4">
          <div class="card rounded card-custom shadow-lg">
            <div class="card-header text-purple">
              <strong class="pl-1 font-weight-bold">Atestate</strong>
            </div>
            <div class="card-body">
              <div class="form-group flex-column flex">
                <label class="text-purple d-block pl-1">Atestat tip Marfa</label>
              </div>
              <div class="form-group">
                <label class="text-purple pl-1">
                  Data expirarii
                </label>
                <datepicker :clear-button="true" :bootstrap-styling="true" input-class="form-control" v-model="formData.documents.goodsTransportCertificate.expirationDate" name="goodsCertExpDate"></datepicker>

              </div>
              <div class="form-group custom-height">
                <label class="text-purple pl-1">
                  Upload atestat
                </label>
                <div class="custom-file">
                  <input type="file" class="custom-file-input" id="goodsCertExpDate-file" ref="goodsTransportCertificate"  v-on:change="onFileChange($event, 'goodsTransportCertificate')"/>
                  <label class="custom-file-label" for="goodsCertExpDate-file">
                    <span v-if="formData.documents.goodsTransportCertificate.file">{{formData.documents.goodsTransportCertificate.file.name}}</span>
                    <span v-if="!formData.documents.goodsTransportCertificate.file">Incarca fisier</span>
                  </label>
                </div>
              </div>

              <div class="form-group flex-column flex">
                <label class="text-purple d-block pl-1">Atestat tip Persoane</label>
              </div>
              <div class="form-group">
                <label class="text-purple pl-1">
                  Data expirarii
                </label>
                <datepicker :clear-button="true" :bootstrap-styling="true" input-class="form-control" v-model="formData.documents.peopleTransportCertificate.expirationDate" name="peopleCertExpDate"></datepicker>

              </div>
              <div class="form-group custom-height">
                <label class="text-purple pl-1">
                  Upload atestat
                </label>
                <div class="custom-file">
                  <input type="file" class="custom-file-input" id="peopleCertExpDate-file" ref="peopleTransportCertificate"  v-on:change="onFileChange($event, 'peopleTransportCertificate')"/>
                  <label class="custom-file-label" for="peopleCertExpDate-file">
                    <span v-if="formData.documents.peopleTransportCertificate.file">{{formData.documents.peopleTransportCertificate.file.name}}</span>
                    <span v-if="!formData.documents.peopleTransportCertificate.file">Incarca fisier</span>
                  </label>
                </div>
              </div>
              <div class="form-group flex-column flex">
                <label class="text-purple d-block pl-1">Atestat marfuri periculoase
                </label>
<!--                <div class="btn-group custom-btn-group btn-group-toggle" data-toggle="buttons">-->
<!--                  <label class="btn">DA-->
<!--                    <input type="radio" name="atestateMP" value="da"-->
<!--                           autocomplete="off"-->
<!--                           v-model="formData.documents.dangerousGoodsTransportCertificate.hasCertificate"-->
<!--                           :class="computedActiveClass(formData.documents.dangerousGoodsTransportCertificate.hasCertificate, 'da')"-->
<!--                    />-->
<!--                  </label>-->
<!--                  <label class="btn">NU-->
<!--                    <input type="radio" name="atestateMP" value="nu"-->
<!--                           autocomplete="off"-->
<!--                           :class="computedActiveClass(formData.documents.dangerousGoodsTransportCertificate.hasCertificate, 'nu')"-->
<!--                    />-->
<!--                  </label>-->
<!--                </div>-->
              </div>
              <div class="form-group">
                <label class="text-purple pl-1">
                  Data expirarii
                </label>
                <datepicker :clear-button="true" :bootstrap-styling="true" input-class="form-control" v-model="formData.documents.dangerousGoodsTransportCertificate.expirationDate" name="danGoodsCertExpDate"></datepicker>

              </div>
              <div class="form-group">
                <label class="text-purple pl-1">
                  Upload atestat
                </label>
                <div class="custom-file">
                  <input type="file" class="custom-file-input" id="danGoodsCertExpDate-file" ref="dangerousGoodsTransportCertificate"  v-on:change="onFileChange($event, 'dangerousGoodsTransportCertificate')"/>
                  <label class="custom-file-label" for="danGoodsCertExpDate-file">
                    <span v-if="formData.documents.dangerousGoodsTransportCertificate.file">{{formData.documents.dangerousGoodsTransportCertificate.file.name}}</span>
                    <span v-if="!formData.documents.dangerousGoodsTransportCertificate.file">Incarca fisier</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



      <div class="row">
        <!-- COR -->
        <div class="col">
          <div class="card rounded card-custom shadow-lg">
            <div class="card-header">
              <strong class="pl-1 text-purple  font-weight-bold">Date Utilizator</strong>
              <a  v-if="canFillInvoiceSeries" @click="initNewDriverSeries"><span class="float-right">Genereaza Serie</span></a>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col">
<!--                  <div class="form-group">-->
<!--                    <label class="text-purple pl-1">Departament</label>-->
<!--                    <input id="departament" type="text" class="form-control" required v-model="formData.employee.departmentId"/>-->
<!--                    -->
<!--                  </div>-->
                  <div class="form-group">
                    <label class="text-purple pl-1">Casa de marcat</label>
                    <romfour-cash-register-autosuggest
                      v-model="selectedCashRegisterName"
                      :companyId="formData.employee.companyId"
                      :branchId="formData.employee.companyBranchId"
                      @onSelectSuggestion="onSelectCashRegisterId"
                    />
                  </div>

                  <div class="form-group">
                    <label class="text-purple pl-1">Serie comanda</label>
                    <input id="serie-comanda" type="text" class="form-control" required disabled  v-model="formData.invoiceSeries.serieComanda"/>

                    <small class="form-text pl-1 error" v-if="submitStatus && !$v.formData.invoiceSeries.serieComanda.required">Acest camp este obligatoriu</small>
                  </div>
                  <div class="form-group">
                    <label class="text-purple pl-1">Serie bilet</label>
                    <input id="serie-bilet" type="text" class="form-control" required disabled  v-model="formData.invoiceSeries.serieBilet"/>

                    <small class="form-text pl-1 error" v-if="submitStatus && !$v.formData.invoiceSeries.serieBilet.required">Acest camp este obligatoriu</small>
                  </div>
                  <div class="form-group">
                    <label class="text-purple pl-1">Serie factura</label>
                    <input id="serie-fac" type="text" class="form-control" required disabled  v-model="formData.invoiceSeries.serieFactura"/>
                    <small class="form-text pl-1 error" v-if="submitStatus && !$v.formData.invoiceSeries.serieFactura.required">Acest camp este obligatoriu</small>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label class="text-purple pl-1">Serie factura asigurare colet (A)</label>
                    <input id="serie-fac-asig-col" type="text" class="form-control" required disabled  v-model="formData.invoiceSeries.serieFacturaAsigColet"/>

                  </div>
                  <div class="form-group">
                    <label class="text-purple pl-1">Serie factura surplus de bagaje (B)</label>
                    <input id="serie-fac-surplus-bag" type="text" class="form-control" required disabled  v-model="formData.invoiceSeries.serieFacturaSurplusBagaje"/>

                  </div>
                  <div class="form-group">
                    <label class="text-purple pl-1">Serie factura coletarie (C)</label>
                    <input id="serie-fac-col" type="text" class="form-control" required disabled  v-model="formData.invoiceSeries.serieFacturaColetarie"/>

                  </div>
                  <div class="form-group">
                    <label class="text-purple pl-1">Serie factura bilet de avion (D)</label>
                    <input id="serie-fac-avion" type="text" class="form-control" required  disabled  v-model="formData.invoiceSeries.serieFacturaBiletDeAvion"/>

                  </div>
                  <div class="form-group">
                    <label class="text-purple pl-1">Serie factura transport de persoane (E)</label>
                    <input id="serie-fac-transp-pers" type="text" class="form-control" required disabled  v-model="formData.invoiceSeries.serieFacturaTransportPersoane"/>

                  </div>

                  <div class="form-group">
                    <label class="text-purple pl-1">Serie factura coletarie ramburs (R)</label>
                    <input id="serie-fac-col-ramburs" type="text" class="form-control" required disabled  v-model="formData.invoiceSeries.serieFacturaColetarieRamburs"/>

                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label class="text-purple pl-1">Serie factura avans (V)</label>
                    <input id="serie-fac-avans" type="text" class="form-control" required disabled  v-model="formData.invoiceSeries.serieFacturaAvans"/>

                  </div>
                  <div class="form-group">
                    <label class="text-purple pl-1">Serie factura inchiriere auto (I)</label>
                    <input id="serie-fac-inch-auto" type="text" class="form-control" required disabled  v-model="formData.invoiceSeries.serieFacturaInchiriereAuto"/>

                  </div>
                  <div class="form-group">
                    <label class="text-purple pl-1">Serie factura garantie (G)</label>
                    <input id="serie-fac-garantie" type="text" class="form-control" required disabled  v-model="formData.invoiceSeries.serieFacturaGarantie"/>

                  </div>
                  <div class="form-group">
                    <label class="text-purple pl-1">Serie factura turism (T)</label>
                    <input id="serie-fac-turism" type="text" class="form-control" required disabled  v-model="formData.invoiceSeries.serieFacturaTurism"/>

                  </div>
                  <div class="form-group">
                    <label class="text-purple pl-1">Serie factura servicii (S)</label>
                    <input id="serie-fac-servicii" type="text" class="form-control" required disabled  v-model="formData.invoiceSeries.serieFacturaServicii"/>

                  </div>
                  <div class="form-group">
                    <label class="text-purple pl-1">Serie factura produse (P)</label>
                    <input id="serie-fac-produse" type="text" class="form-control" required disabled  v-model="formData.invoiceSeries.serieFacturaProduse"/>

                  </div>
                </div>
              </div>
              <div class="row border-bottom mb-3"></div>
              <div class="row">

                <div class="col">
                  <div class="form-group">
                    <label class="text-purple pl-1">Serie chitanta</label>
                    <input id="serie-chitanta" type="text" class="form-control" required disabled v-model="formData.receits.serieChitanta" :disabled="canFillReceitSeries"/>
                    <small class="form-text pl-1 error" v-if="submitStatus && !$v.formData.receits.serieChitanta.required">Acest camp este obligatoriu</small>
                  </div>
                  <div class="form-group">
                    <label class="text-purple pl-1">Serie Chitanta ramburs</label>
                    <input id="serie-chitanta-ramburs" type="text" class="form-control" required :disabled="canFillReceitRambursSeries" v-model="formData.receits.serieChitantaRamburs"/>

                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label class="text-purple pl-1">Comision pt bilete - suma</label>
                    <input id="com-sum-bilete" type="text" class="form-control" required v-model="formData.commission.comisionBileteSuma"/>

                  </div>
                  <div class="form-group">
                    <label class="text-purple pl-1">Comision pt bilete - procent</label>
                    <input id="com-per-bilete" type="text" class="form-control" required v-model="formData.commission.comisionBileteProcent">

                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label class="text-purple pl-1">Comision pt comenzi - suma</label>
                    <input id="com-sum-comenzi" type="text" class="form-control" required v-model="formData.commission.comisionComenziSuma">

                  </div>
                  <div class="form-group">
                    <label class="text-purple pl-1">Comision pt comenzi - procent</label>
                    <input id="com-per-comenzi" type="text" class="form-control" required v-model="formData.commission.comisionComenziProcent">

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="row">
        <!-- COR -->
        <div class="col">
          <div class="card rounded card-custom shadow-lg">
            <div class="card-header">
              <strong class="pl-1 text-purple  font-weight-bold">Jurnale</strong>
              <a  v-if="canFillJournal" @click="initNewDriverSeries"><span class="float-right">Genereaza Jurnale</span></a>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label class="text-purple pl-1">Cod Moneda</label>
                    <input id="jur-ron-mon" type="text" class="form-control" v-model="formData.journals.ron.codMoneda" disabled/>

                  </div>
                  <div class="form-group">
                    <label class="text-purple pl-1">Cod Jurnal</label>
                    <div class="input-group mb-3">
                      <input id="jur-ron-cod" type="text" class="form-control" required disabled v-model="formData.journals.ron.cod"/>
                      <small class="form-text pl-1 error" v-if="submitStatus && !$v.formData.journals.ron.cod.required">Acest camp este obligatoriu</small>
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="text-purple pl-1">Nume Jurnal</label>
                    <input id="jur-ron-nume" type="text" class="form-control" required disabled v-model="formData.journals.ron.numeJurnal"/>

                  </div>

                  <div class="form-group">
                    <label class="text-purple pl-1">Tip Jurnal</label>
                    <select id="jurnal-tip-ron"
                            class="form-control select2-hidden-accessible select2-dropdown d-block"
                            tabindex="-1"
                            aria-hidden="true"
                            required
                            v-model="formData.journals.ron.tip"
                    >
                      <option v-for="tipJurnal in tipuriJurnale" :value="tipJurnal.id">
                        {{ tipJurnal.name }}
                      </option>
                    </select>

                  </div>
                  <div class="form-group">
                    <label class="text-purple pl-1">Contrapartida</label>
                    <div class="input-group mb-3">
                      <input id="jur-ron-ctr-p" type="text" class="form-control" required disabled v-model="formData.journals.ron.contrapartida">

                    </div>

                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label class="text-purple pl-1">Cod Moneda</label>
                    <input id="jur-eur-mon" type="text" class="form-control" v-model="formData.journals.euro.codMoneda" disabled/>

                  </div>
                  <div class="form-group">
                    <label class="text-purple pl-1">Cod Jurnal</label>
                    <div class="input-group mb-3">
                      <input id="jur-eur-cod" type="text" class="form-control" required disabled v-model="formData.journals.euro.cod"/>
                      <small class="form-text pl-1 error" v-if="submitStatus && !$v.formData.journals.euro.cod.required">Acest camp este obligatoriu</small>
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="text-purple pl-1">Nume Jurnal</label>
                    <input id="jur-eur-nume" type="text" class="form-control" required disabled v-model="formData.journals.euro.numeJurnal"/>

                  </div>

                  <div class="form-group">
                    <label class="text-purple pl-1">Tip Jurnal</label>
                    <select id="jurnal-tip-euro"
                            class="form-control select2-hidden-accessible select2-dropdown d-block"
                            tabindex="-1"
                            aria-hidden="true"
                            required
                            v-model="formData.journals.euro.tip"
                    >
                      <option disabled value="">Alege Tip</option>
                      <option v-for="tipJurnal in tipuriJurnale" :value="tipJurnal.id">
                        {{ tipJurnal.name }}
                      </option>
                    </select>

                  </div>
                  <div class="form-group">
                    <label class="text-purple pl-1">Contrapartida</label>
                    <div class="input-group mb-3">
                      <input id="jur-eur-ctr-p" type="text" class="form-control" required disabled v-model="formData.journals.euro.contrapartida">

                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label class="text-purple pl-1">Cod Moneda</label>
                    <input id="jur-gbp-mon" type="text" class="form-control" v-model="formData.journals.gbp.codMoneda" disabled/>

                  </div>
                  <div class="form-group">
                    <label class="text-purple pl-1">Cod Jurnal</label>

                    <div class="input-group mb-3">
                      <input id="jur-gbp-cod" type="text" class="form-control" required disabled v-model="formData.journals.gbp.cod"/>
                      <small class="form-text pl-1 error" v-if="submitStatus && !$v.formData.journals.gbp.cod.required">Acest camp este obligatoriu</small>
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="text-purple pl-1">Nume Jurnal</label>
                    <input id="jur-gbp-nume" type="text" class="form-control" required disabled v-model="formData.journals.gbp.numeJurnal"/>

                  </div>

                  <div class="form-group">
                    <label class="text-purple pl-1">Tip Jurnal</label>
                    <select id="jurnal-tip-gbp"
                            class="form-control select2-hidden-accessible select2-dropdown d-block"
                            tabindex="-1"
                            aria-hidden="true"
                            required
                            v-model="formData.journals.gbp.tip"
                    >
                      <option disabled value="">Alege Tip</option>
                      <option v-for="tipJurnal in tipuriJurnale" :value="tipJurnal.id">
                        {{ tipJurnal.name }}
                      </option>
                    </select>

                  </div>
                  <div class="form-group">
                    <label class="text-purple pl-1">Contrapartida</label>
                    <div class="input-group mb-3">
                      <input id="jur-gbp-ctr-p" type="text" class="form-control" required disabled v-model="formData.journals.gbp.contrapartida">

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="row">
        <div class="col-md-8">
          <div class="row">
            <div class="col">
              <a class="btn btn-purple btn-block shadow" @click="goToRoute('drivers')">Renunta</a>
            </div>
            <div class="col">
              <button class="btn  btn-purple btn-block shadow"
                      @click.prevent="onSubmitDriver(formData)"
                      v-if="canEditDriver"
              >Salveaza</button>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <a class="btn btn-block  btn-white text-purple shadow" @click="onSeeDocs()">Vezi documente</a>
        </div>
      </div>
    </form>

  </div>
</template>

<script>
  import { DRIVER_EDIT_SUBMIT, FETCH_DRIVER, DRIVER_INIT_NEW_SERIES } from '@/store/actions.type';
  import Datepicker from 'vuejs-datepicker';
  import { required, maxLength } from 'vuelidate/lib/validators'
  import RomfourCompanyDepartmentAutosuggest from "../../components/autosuggest/RomfourCompanyDepartment";
  import RomfourCompanyBranchAutosuggest from "../../components/autosuggest/RomfourCompanyBranch";
  import RomfourCashRegisterAutosuggest from "../../components/autosuggest/RomfourCashRegister";
  import RomfourRegionAutosuggest from "../../components/autosuggest/RomfourRegions";
  import moment from 'moment';

  export default {
    name: 'DriverEditPage',
    components: {
      RomfourRegionAutosuggest,
      RomfourCashRegisterAutosuggest,
      RomfourCompanyBranchAutosuggest,
      RomfourCompanyDepartmentAutosuggest,
      Datepicker
    },
    props: {
      slug: Number|String,
    },
    data: () => ({
      formData: {
        employee: {
          companyId: null,
          companyBranchId: null,
          departmentId: null,
          firstName: null,
          lastName: null,
          gender: 'masculin',
          corVersion: null,
          codCor6: null,
          phoneNo: null,
          address: {
            countryId: null,
            countyId: null,
            cityId: null,
            street: null,
            streetNo: null,
            building: null,
            entrance: null,
            floor: null,
            apartment: null,
          },
        },
        user: {
          username: null,
          password: null,
        },
        documents: {
          ci: {
            cnp: null,
            expirationDate: null,
            series: null,
            number: null,
            file: null
          },
          drivingLicence: {
            type: null,
            expirationDate: null,
            file: null
          },
          peopleTransportCertificate: {
            expirationDate: null,
            file: null
          },
          contractDeMunca: {
            expirationDate: null,
            file: null
          },
          goodsTransportCertificate: {
            expirationDate: null,
            file: null
          },
          dangerousGoodsTransportCertificate: {
            expirationDate: null,
            file: null,
            hasCertificate: 'nu'
          },
          medicalCertificate: {
            expirationDate: null,
            file: null
          },
          psihologicalCertificate: {
            expirationDate: null,
            file: null
          },
          cardTahograf: {
            expirationDate: null,
            series: null,
            downloadedPeriod: null,
            file: null,
          },
        },
        trialPeriod: {
          hasTrialPeriod: false,
          startedAt: null,
          endedAt: null,
        },
        invoiceSeries: {
          assignedInvoiceSeriesPrefix: null,
          cashRegister: null,
          serieComanda: null,
          serieBilet: null,
          serieFactura: null,
          serieFacturaAsigColet: null,
          serieFacturaSurplusBagaje: null,
          serieFacturaColetarie: null,
          serieFacturaBiletDeAvion: null,
          serieFacturaTransportPersoane: null,
          serieFacturaColetarieRamburs: null,
          serieFacturaAvans: null,
          serieFacturaInchiriereAuto: null,
          serieFacturaGarantie: null,
          serieFacturaTurism: null,
          serieFacturaServicii: null,
          serieFacturaProduse: null,
        },
        receits: {
          assignedReceitSeriesPrefix: null,
          serieChitanta: null,
          serieChitantaRamburs: null,
        },
        commission: {
          comisionBileteSuma: null,
          comisionBileteProcent: null,
          comisionComenziSuma: null,
          comisionComenziProcent: null,
        },
        journals: {
          ron: {
            codMoneda: 'RON',
            cod: null,
            contrapartida: null,
            numeJurnal: null,
            tip: null,
          },
          euro: {
            codMoneda: 'EUR',
            cod: null,
            contrapartida: null,
            numeJurnal: null,
            tip: null,
          },
          gbp: {
            codMoneda: 'GBP',
            cod: null,
            contrapartida: null,
            numeJurnal: null,
            tip: null,
          }
        }
      },
      invalidFieldsErrors: {},
      submitStatus: null,
      selectedCashRegisterName: null,
      selectedCompanyDepartmentName: null,
      selectedCompanyBranchName: null,
      selectCityName: null,
      tipuriJurnale: [
        {
          id: 20,
          name: 'Registru de casa'
        },
        {
          id: 21,
          name: 'Jurnal de banca'
        }
      ],
      canFillInvoiceSeries: false,
      canFillJournal: false,
      canFillUsername: false,
      canFillReceitSeries: false,
      canFillReceitRambursSeries: false,
    }),
    validations: {
      formData: {
        employee: {
          companyId: { required },
          companyBranchId: { required },
          lastName: { required },
          firstName: { required },
          departmentId: { required },
          phoneNo: { required },
          address: {
            countryId: { required, maxLength: maxLength(2) },
            countyId: { maxLength: maxLength(2) },
          },
        },
        user: {
          username: {required},
        },
        documents: {
          ci: {
            cnp: { required },
            series: { required },
            number: { required },
            expirationDate: { required },
          },
          drivingLicence: {
            expirationDate: { required },
            type: { required },
          }
        },
        invoiceSeries: {
          serieComanda: { required },
          serieBilet: { required },
          serieFactura: { required },
        },
        receits: {
          serieChitanta: { required },
        },
        journals: {
          ron: {cod: { required }},
          euro: {cod: { required }},
          gbp: {cod: { required }},
        }
      }
    },
    computed: {
      newDriverForm() {
        return this.$store.getters.newDriverForm;
      },
      canEditDriver() {
        return this.$store.getters.permissions.createEditDriver;
      }
    },
    mounted() {
      this.initDriver();
    },
    methods: {
      goToRoute(route) {
        this.$router.push({ name: route});
      },
      initNewDriverSeries() {
        this.$store.dispatch(DRIVER_INIT_NEW_SERIES).then((response) => {

          this.formData.invoiceSeries = {
            assignedInvoiceSeriesPrefix: this.formData.invoiceSeries.assignedInvoiceSeriesPrefix || response.prefix,
            cashRegister: this.formData.invoiceSeries.cashRegister || null,
            serieComanda: this.formData.invoiceSeries.serieComanda || response.prefix,
            serieBilet: this.formData.invoiceSeries.serieBilet || response.prefix,
            serieFactura: this.formData.invoiceSeries.serieFactura || response.prefix,
            serieFacturaAsigColet: this.formData.invoiceSeries.serieFacturaAsigColet || response.serieFacturaAsigColet,
            serieFacturaSurplusBagaje: this.formData.invoiceSeries.serieFacturaSurplusBagaje || response.serieFacturaSurplusBagaje,
            serieFacturaColetarie: this.formData.invoiceSeries.serieFacturaColetarie || response.serieFacturaColetarie,
            serieFacturaBiletDeAvion: this.formData.invoiceSeries.serieFacturaBiletDeAvion || response.serieFacturaBiletDeAvion,
            serieFacturaTransportPersoane: this.formData.invoiceSeries.serieFacturaTransportPersoane || response.serieFacturaTransportPersoane,
            serieFacturaColetarieRamburs: this.formData.invoiceSeries.serieFacturaColetarieRamburs || response.serieFacturaColetarieRamburs,
            serieFacturaAvans: this.formData.invoiceSeries.serieFacturaAvans || response.serieFacturaAvans,
            serieFacturaInchiriereAuto:this.formData.invoiceSeries.serieFacturaInchiriereAuto || response.serieFacturaInchiriereAuto,
            serieFacturaGarantie: this.formData.invoiceSeries.serieFacturaGarantie || response.serieFacturaGarantie,
            serieFacturaTurism: this.formData.invoiceSeries.serieFacturaTurism || response.serieFacturaTurism,
            serieFacturaServicii: this.formData.invoiceSeries.serieFacturaServicii || response.serieFacturaServicii,
            serieFacturaProduse: this.formData.invoiceSeries.serieFacturaProduse || response.serieFacturaProduse,
          };

          this.formData.receits = {
            assignedReceitSeriesPrefix:  this.formData.receits.assignedReceitSeriesPrefix || response.prefix,
            serieChitanta:  this.formData.receits.serieChitanta || response.prefix,
            serieChitantaRamburs:  this.formData.receits.serieChitantaRamburs || response.prefix,
          };

          if (!this.formData.journals.ron.cod) {
            this.formData.journals.ron.cod = response.prefixJournal + 'R';
            this.formData.journals.ron.numeJurnal = this.getDefaultJournalName('RON', response.prefixJournal);
            this.formData.journals.ron.contrapartida = '5311.03.' + response.prefixJournal;
            this.formData.journals.ron.tip = 20;
          }
          if (!this.formData.journals.euro.cod) {
            this.formData.journals.euro.cod = response.prefixJournal + 'E';
            this.formData.journals.euro.numeJurnal = this.getDefaultJournalName('EUR', response.prefixJournal);
            this.formData.journals.euro.contrapartida = '5314.01.03.' + response.prefixJournal;
            this.formData.journals.euro.tip = 20;
          }
          if (!this.formData.journals.gbp.cod) {
            this.formData.journals.gbp.cod = response.prefixJournal + 'G';
            this.formData.journals.gbp.numeJurnal = this.getDefaultJournalName('GBP', response.prefixJournal);
            this.formData.journals.gbp.contrapartida = '5314.02.03.' + response.prefixJournal;
            this.formData.journals.gbp.tip = 20;
          }

          this.$log.info('DRIVER_INIT_NEW_SERIES', response);
        }).catch((error) => {
          this.$log.error('DRIVER_INIT_NEW_SERIES', error);
        });
      },
      getDefaultJournalName(codMoneda, cod) {
        const employee = this.formData.employee;
        return  `Reg. casa in ${codMoneda} ${employee.lastName} ${employee.firstName} (${cod})`;
      },
      initDriver() {
        this.$store.dispatch(FETCH_DRIVER, this.slug).then((response) => {
          response = response.data;
          const user = response.user || {};
          const docs = response.docs || {};
          const journals = response.journals && Object.keys(response.journals).length ? response.journals : {EUR: {}, RON: {}, GBP: {}};

          this.formData = {
            employee: {
              companyId: response.employee.codSoc,
                companyBranchId: response.employee.codPunct,
                departmentId:  response.employee.numarSectie,
                firstName:  response.employee.prenume,
                lastName:  response.employee.nume,
                gender: response.driver ? response.driver.gender : null,
                corVersion: response.employee.codVersiuneCor,
                codCor6:  response.employee.codCor6,
                phoneNo:  response.employee.mobil,
                address: {
                countryId: response.employee.codTara,
                  countyId: response.employee.codJudet,
                  cityId: null,
                  street:  response.employee.strada,
                  streetNo: null,
                  building: response.employee.bloc,
                  entrance: response.employee.scara,
                  floor: response.employee.etaj,
                  apartment: response.employee.apartament,
              },
            },
            user: {
              username: response.user ?  response.user.username : null,
              password: null,
            },
            documents: {
              ci: {
                cnp: response.employee.cnp,
                  expirationDate: docs['carte-de-identitate'] ? docs['carte-de-identitate'].expirationDate : null,
                  series: response.employee.ciSerie,
                  number: response.employee.ciNumar,
                  file: null
              },
              drivingLicence: {
                type:  response.driver? response.driver.drivingLicenceType : null,
                expirationDate: docs['permis'] ? docs['permis'].expirationDate : null,
                  file: null
              },
              peopleTransportCertificate: {
                expirationDate: docs['atestat-tip-persoane'] ? docs['atestat-tip-persoane'].expirationDate : null,
                  file: null
              },
                contractDeMunca: {
                expirationDate: docs['contractDeMunca'] ? docs['contractDeMunca'].expirationDate : null,
                  file: null
              },
              goodsTransportCertificate: {
                expirationDate: docs['atestat-tip-marfa'] ? docs['atestat-tip-marfa'].expirationDate : null,
                  file: null
              },
              dangerousGoodsTransportCertificate: {
                expirationDate: docs['atestat-marfuri-periculoase'] ? docs['atestat-marfuri-periculoase'].expirationDate : null,
                  file: null,
                  hasCertificate: 'nu'
              },
              medicalCertificate: {
                expirationDate: docs['aviz-medical'] ? docs['aviz-medical'].expirationDate : null,
                  file: null
              },
              psihologicalCertificate: {
                expirationDate: docs['aviz-psihologic'] ? docs['aviz-psihologic'].expirationDate : null,
                  file: null
              },
              cardTahograf: {
                expirationDate: docs['card-tahograf'] ? docs['card-tahograf'].expirationDate : null,
                series: docs['card-tahograf'] ? docs['card-tahograf'].seriesNumber : null,
                downloadedPeriod: docs['card-tahograf'] && docs['card-tahograf']['extraInfo'] ?
                  JSON.parse(docs['card-tahograf']['extraInfo']).period : null,
                file: null,
              },
            },
            trialPeriod: {
              hasTrialPeriod: false,
                startedAt: response.employee? response.employee.dataAngajare : null,
                endedAt: response.employee && response.employee.dataAngajare ?
                  moment(response.employee.dataAngajare, 'YYYY-MM-DD').add(90, 'days').format('YYYY-MM-DD')
                  : null,
            },
            invoiceSeries: {
              assignedInvoiceSeriesPrefix: response.driver? response.driver.assignedInvoiceSeriesCode : null,
                cashRegister: response.cashRegister ? response.cashRegister.codCasaMarcat : null,
                serieComanda: user.serieComanda,
                serieBilet: user.serieBilet,
                serieFactura: user.serieFactura,
                serieFacturaAsigColet: user.serieFacturaAsig,
                serieFacturaSurplusBagaje: user.serieFacturaBagaje,
                serieFacturaColetarie: user.serieFacturaColetarie,
                serieFacturaBiletDeAvion: user.serieFacturaAvion,
                serieFacturaTransportPersoane: user.serieFacturaTrPers,
                serieFacturaColetarieRamburs: user.serieFactRamburs,
                serieFacturaAvans: user.serieFactAvans,
                serieFacturaInchiriereAuto: user.serieFactInch,
                serieFacturaGarantie: user.serieFactGarantie,
                serieFacturaTurism: user.serieFactTurism,
                serieFacturaServicii: user.serieFactServ,
                serieFacturaProduse: user.serieFactProd,
            },
            receits: {
              assignedReceitSeriesPrefix: response.driver? response.driver.assignedInvoiceSeriesCode : null,
              serieChitanta: user.serieChitanta || null,
              serieChitantaRamburs: user.serieChitRamburs || null,
            },
            commission: {
              comisionBileteSuma: user.sumaBilet,
                comisionBileteProcent: user.procentBilet,
                comisionComenziSuma: user.sumaComanda,
                comisionComenziProcent: user.procentComanda,
            },
            journals: {
              ron: {
                codMoneda: 'RON',
                cod: journals.RON ? journals.RON.codJurnal : null,
                contrapartida: journals.RON ? journals.RON.contrapartida : null,
                numeJurnal: journals.RON ? journals.RON.numeJurnal : null,
                tip: journals.RON ? journals.RON.tipJurnal2 : null,
              },
              euro: {
                codMoneda: 'EUR',
                cod: journals.EUR ? journals.EUR.codJurnal : null,
                contrapartida: journals.EUR ? journals.EUR.contrapartida: null,
                numeJurnal: journals.EUR ? journals.EUR.numeJurnal: null,
                tip: journals.EUR ? journals.EUR.tipJurnal2: null,
              },
              gbp: {
                codMoneda: 'GBP',
                cod: journals.GBP ? journals.GBP.codJurnal: null,
                contrapartida: journals.GBP ? journals.GBP.contrapartida: null,
                numeJurnal: journals.GBP ? journals.GBP.numeJurnal: null,
                tip: journals.GBP ? journals.GBP.tipJurnal2: null,
              }
            }
          };

          this.canFillInvoiceSeries = !response.user;
          this.canFillReceitSeries = !response.user || response.user.serieChitanta;
          this.canFillReceitRambursSeries = !response.user || response.user.serieChitRamburs;
          this.canFillJournal = !(response.journals && Object.keys(response.journals).length);
          this.canFillUsername = !response.user || !response.user.username;
          this.selectCityName = response.region ? response.region.numeLocalitate : null;
          this.selectedCashRegisterName = response.cashRegister ? response.cashRegister.numeCasaMarcat : null;
          this.selectedCompanyBranchName = response.companyBranch ? response.companyBranch.denumire : null;
          this.selectedCompanyDepartmentName = response.companyDepartment ? response.companyDepartment.numeSectie : null;

          this.$log.info('FETCH_DRIVER', response);
        }).catch((error) => {
          this.$log.error('FETCH_DRIVER', error);
        });
      },
      computedActiveClass(actualValue, expectedValue) {
        return actualValue === expectedValue ? 'active' : '';
      },
      onFileChange(e, fileType) {
        var files = e.target.files || e.dataTransfer.files;
        if (!files.length)
          return;
        this.formData.documents[fileType].file = files[0];
        // this.createImage(files[0], fileType);
      },
      onSubmitDriver(e) {
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.submitStatus = 'ERROR';
          return;
        }
        this.submitStatus = 'PENDING';

        let formData = new FormData();
        formData.append('data', JSON.stringify(this.formData));
        formData.append('ciFile', this.formData.documents.ci.file);
        formData.append('drivingLicenceFile', this.formData.documents.drivingLicence.file);
        formData.append('medicalCertificateFile', this.formData.documents.medicalCertificate.file);
        formData.append('psihologicalCertificateFile', this.formData.documents.psihologicalCertificate.file);
        formData.append('dangerousGoodsTransportCertificateFile', this.formData.documents.dangerousGoodsTransportCertificate.file);
        formData.append('goodsTransportCertificateFile', this.formData.documents.goodsTransportCertificate.file);
        formData.append('peopleTransportCertificateFile', this.formData.documents.peopleTransportCertificate.file);
        formData.append('cardTahografFile', this.formData.documents.cardTahograf.file);
        formData.append('contractDeMuncaFile', this.formData.documents.contractDeMunca.file);

        this.$store.dispatch(DRIVER_EDIT_SUBMIT, {id: this.slug, formData}).then((response) => {
          this.submitStatus = null;
          this.$log.info('DRIVER_EDIT_SUBMIT', response);
          this.$router.push({name: 'drivers'});
        }).catch((error) => {
          this.submitStatus = null;
          if (error.response && error.response.status === 400) {
            this.invalidFieldsErrors = error.response.data || {};
          }
          this.$log.error('DRIVER_EDIT_SUBMIT', error);
        });
      },
      onSelectCompanyBranchId(companyBranch) {
        this.selectedCompanyBranchName = companyBranch ? companyBranch.denumire : '';
        this.formData.employee.companyBranchId = companyBranch ? companyBranch.codPunct : null;
      },
      onSelectCompanyDepartmentId(department) {
        this.selectedCompanyDepartmentName = department ? department.numeSectie : '';
        this.formData.employee.departmentId = department ? department.codMagazie : null;
      },
      onSelectCashRegisterId(cashRegister) {
        this.selectedCashRegisterName = cashRegister ? cashRegister.numeCasaMarcat : '';
        this.formData.invoiceSeries.cashRegister = cashRegister ? cashRegister.codCasaMarcat : null;
      },
      onSelectCityId(region) {
        this.selectCityName = region ? region.numeLocalitate : '';
        this.formData.employee.address.cityId = region ? region.codLocalitate : null;
      },
      onSeeDocs() {
        this.$router.push({ name: 'driverDocument', params: { slug: this.$props.slug}});
      },
    },

    watch: {
      slug: {
        handler(val) {
          this.initPageData(val);
        },
      },
    },
  };
</script>
