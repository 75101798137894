<template>
  <div>
    <div class="row pt-5 pb-3">
      <div class="col">
        <h1 class="text-purple pl-1">Hub</h1>
      </div>
    </div>

    <form action="" @submit="onSubmitHub(formData)" @submit.prevent="onSubmit" enctype="multipart/form-data" method="post">
      <div class="card rounded card-custom shadow-lg">
        <div>
          <div class="card-body">
            <div class="form-row">
              <div class="form-group col-md-6 pr-3">
                <label class="text-purple pl-1">Nume</label>
                <input id="nume" type="text" class="form-control" v-model="formData.name" required>
                <small class="form-text pl-1 error" v-if="submitStatus && !$v.formData.name.required">Acest camp este obligatoriu</small>
              </div>
              <div class="form-group col-md-6 flex-column flex pl-3">
                <label class="text-purple d-block pl-1">Tipul Hub-ului
                </label>
                <div class="btn-group custom-btn-group btn-group-toggle" data-toggle="buttons" @click="resetInput($event)">
                  <label class="btn" :class="computedActiveClass(formData.type, 'intern')">Intern
                    <input type="radio" name="type" value="intern" autocomplete="off" v-model="formData.type" >
                  </label>
                  <label class="btn" :class="computedActiveClass(formData.type, 'extern')">Extern
                    <input type="radio" name="sex" value="extern" autocomplete="off" v-model="formData.type" >
                  </label>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-6 pr-3">
                <label class="text-purple pl-1">Latitudine
                </label>
                <input id="latitude" type="text" class="form-control" v-model="formData.latitude">
              </div>
              <div class="form-group col-md-6 pr-4 pl-3" >
                <label class="text-purple pl-1">Longitudine</label>
                <input id="longitude" type="text" class="form-control" v-model="formData.longitude">
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-6" pr-3>
                <label class="text-purple pl-1">Adresa</label>
                <input id="address" type="text" class="form-control" v-model="formData.address">
              </div>
               <div class="form-group col-md-6 pr-3 pl-3" >
                 <label class="text-purple pl-1">Tara</label>
                 <select
                   v-model="formData.country"
                   class="form-control"
                   id="vehicle-status-select"
                   :disabled="formData.type == 'intern'"
                   @click="changeCountry($event)"
                 >
                   <option v-for="country in countryList" :value="country.zoneCode">{{ country.countryName }} - {{ country.zoneCode }}</option>
                 </select>

<!--                <input id="country" type="text" class="form-control" :placeholder="formData.type == 'intern' ? 'RO' : ''" v-model="formData.country" :disabled="formData.type == 'intern'">-->
                 <small class="form-text pl-1 error" v-if="submitStatus && !$v.formData.country.required">Acest camp este obligatoriu</small>
              </div>
              <div class="form-group col-md-6 pr-3" >
                <label class="text-purple pl-1">Oras</label>
                <input id="city" type="text" class="form-control" v-model="formData.city">
                <small class="form-text pl-1 error" v-if="submitStatus && !$v.formData.city.required">Acest camp este obligatoriu</small>
              </div>
               <div class="form-group col-md-6 pr-4 pl-3" >
                <label class="text-purple pl-1">Regiune</label>
                <input id="region" type="text" class="form-control" v-model="formData.region">
                 <small class="form-text pl-1 error" v-if="submitStatus && !$v.formData.region.required">Acest camp este obligatoriu</small>
              </div>
               <div class="form-group col-md-6 pr-3" >
                <label class="text-purple pl-1">Cod Postal</label>
                <input id="postal_code" type="text" class="form-control" v-model="formData.postalCode">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-8">
          <div class="row">
            <div class="col">
              <a class="btn btn-purple btn-block shadow" @click="goToRoute('hubs')">Renunta</a>
            </div>
            <div class="col">
              <button class="btn  btn-purple btn-block shadow" @click.prevent="onSubmitHub(formData)">Salveaza</button>
            </div>
          </div>
        </div>
        <div class="col-md-4">
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import {FETCH_HUB_ROUTES, HUB_CREATE_SUBMIT} from '@/store/actions.type';
  import Datepicker from 'vuejs-datepicker';
  import { required, maxLength } from 'vuelidate/lib/validators'
import Vue from "vue";
  export default {
    name: 'HubNewPage',
    components: {
      Datepicker
    },
    data: () => ({
      formData: {
          name: null,
          type: 'intern',
          latitude: null,
          longitude: null,
          address: null,
          country: 'RO',
          city: null,
          region: null,
          postalCode: null,
      },
      countryList: {},
      submitStatus: null,
    }),
    validations: {
      formData: {
          name: { required },
          country: { required },
          city: { required },
          region: { required },
      },
    },
    computed: {
      newHubForm() {
        return this.$store.getters.newHubForm;
      },
    },
    mounted() {
      this.initHubRoutes();
      // this.initNewDriverSeries();
    },
    methods: {
      resetInput(event) {
        this.inputeValue = event.target.value;
        if(this.inputeValue == "intern" && typeof this.inputeValue != "undefined") {
          this.formData.country = "RO";
          $(this.countryList).each(( index, value ) => {
            if(this.formData.country === value.zoneCode) {
              this.formData.postalCode = value.zipCode;
            }
          });
        } else if(this.inputeValue == "extern"  && typeof this.inputeValue != "undefined") {
          this.formData.postalCode = "";
          return this.formData.country = "";
        }
      },
      changeCountry(event) {
        this.countryValue = event.target.value;
        $(this.countryList).each(( index, value ) => {
          if(this.countryValue === value.zoneCode) {
            this.formData.postalCode = value.zipCode;
          }
        });
      },
      initHubRoutes() {
        this.$store.dispatch(FETCH_HUB_ROUTES).then((response) => {
          this.countryList = response.data;
          this.$log.info('FETCH_HUB_ROUTES', response);
        }).catch((error) => {
          this.$log.error('FETCH_HUB_ROUTES', error);
        });
      },
      goToRoute(route) {
        this.$router.push({ name: route});
      },
      computedActiveClass(actualValue, expectedValue) {
        return actualValue === expectedValue ? 'active' : '';
      },
      onSubmitHub(e) {
        this.$v.$touch();

        if (this.$v.$invalid) {
          this.submitStatus = 'ERROR';
          return;
        }

        this.submitStatus = 'PENDING';

        let formData = new FormData();
        formData.append('data', JSON.stringify(this.formData));

        this.$store.dispatch(HUB_CREATE_SUBMIT, formData).then((response) => {
          this.submitStatus = null;
          this.$log.info('HUB_CREATE_SUBMIT', response);
          Vue.notify({
            group: 'success',
            type: 'success',
            title: 'Hub-ul a fost creat cu success!',
          })
          this.$router.push({name: 'hubs'});
        }).catch((error) => {
          this.submitStatus = null;
          if (error.response && error.response.status === 400) {
            this.invalidFieldsErrors = error.response.data || {};
          }
          this.$log.error('HUB_CREATE_SUBMIT', error);
        });
      },
    }
  };
</script>
