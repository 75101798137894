<template>
    <md-card v-if="pageMode !== 'VIEW' && isAdmin">
        <md-card-header>
            <div class="md-title">Page Options</div>
            <p class="slug">Slug /{{pageData.slug}}</p>
        </md-card-header>

        <md-card-content>
            <!--TYPE-->
            <md-list>
                <md-subheader>Type</md-subheader>

                <md-list-item>
                    <md-radio v-model="type" value="DEFAULT" />
                    <span class="md-list-item-text">Default</span>
                </md-list-item>

                <md-list-item>
                    <md-radio v-model="type" value="SHOWCASE" />
                    <span class="md-list-item-text">Showcase</span>
                </md-list-item>
            </md-list>

            <!--OPTIONS-->
            <md-list>
                <md-subheader>Options</md-subheader>

                <md-list-item>
                    <span class="md-list-item-text">
                        <span v-if="pageData.enabled">Enabled</span>
                        <span v-if="!pageData.enabled">Disabled</span>
                    </span>
                    <md-switch v-model="isPageEnabled" />
                </md-list-item>

                <md-list-item>
                    <span class="md-list-item-text">
                        <span v-if="pageData.commentsEnabled">Comments enabled</span>
                        <span v-if="!pageData.commentsEnabled">Comments disabled</span>
                    </span>
                    <md-switch v-model="arePageCommentsEnabled" />
                </md-list-item>
            </md-list>

            <!--MENU OPTIONS-->
            <md-list>
                <md-subheader>Menu options</md-subheader>

                <md-list-item>
                    <md-checkbox v-model="menu" value="MAIN_MENU" />
                    <span class="md-list-item-text">Main menu</span>
                </md-list-item>

                <md-list-item>
                    <md-checkbox v-model="menu" value="FOOTER_MENU" />
                    <span class="md-list-item-text">Footer menu</span>
                </md-list-item>
            </md-list>

            <!--SECTIONS-->
            <!--<md-list>-->
                <!--<md-subheader>Sections (TO IMPLEMENT)</md-subheader>-->

                <!--<md-list-item>-->
                    <!--<md-checkbox v-model="sections" value="COLUMNS" />-->
                    <!--<span class="md-list-item-text">COLUMNS</span>-->
                <!--</md-list-item>-->

                <!--<md-list-item>-->
                    <!--<md-checkbox v-model="sections" value="PARALLAX" />-->
                    <!--<span class="md-list-item-text">PARALLAX</span>-->
                <!--</md-list-item>-->

                <!--<md-list-item>-->
                    <!--<md-checkbox v-model="sections" value="CALL_TO_ACTION" />-->
                    <!--<span class="md-list-item-text">CALL_TO_ACTION</span>-->
                <!--</md-list-item>-->
            <!--</md-list>-->
        </md-card-content>

        <md-card-actions>
            <md-button class="md-raised md-danger" v-if="pageMode !== 'CREATE'" @click="remove">
                DELETE
            </md-button>
            <md-button class="md-raised md-warn" v-if="pageMode === 'EDIT'" @click="cancel">
                CANCEL
            </md-button>
            <md-button class="md-raised md-success" @click="save">
                SAVE
            </md-button>
        </md-card-actions>
    </md-card>
</template>

<script>
import {
  PAGE_CHANGE_FIELD_VALUE,
  PAGE_CREATE_SUMBIT,
  PAGE_EDIT_SUMBIT,
  PAGE_DELETE_SUBMIT,
} from '@/store/actions.type';

export default {
  name: 'RomPageSidebar',
  props: {
    mode: {
      type: String,
      default: 'EDIT',
    },
  },
  data: () => ({
    type: 'DEFAULT',
    options: {
      enabled: 0,
      commentsEnabled: 0,
    },
    menu: [],
    // sections: [],
  }),
  computed: {
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    pageData() {
      if (this.mode === 'CREATE') {
        return this.$store.getters.newPageData;
      }

      return this.$store.getters.editPageData;
    },
    isPageEnabled: {
      get() {
        if (this.mode === 'CREATE') {
          return this.$store.getters.newPageData.enabled === 1;
        }

        return this.$store.getters.editPageData.enabled === 1;
      },
      set(value) {
        const params = {
          field: 'enabled',
          fieldValue: value === true ? 1 : 0,
        };

        this.$log.info('PAGE_CHANGE_FIELD_VALUE', params);
        this.$store.dispatch(PAGE_CHANGE_FIELD_VALUE, params).then((response) => {
          this.$log.info('PAGE_CHANGE_FIELD_VALUE', response);
        }).catch((error) => {
          this.$log.error('PAGE_CHANGE_FIELD_VALUE', error);
        });
      },
    },
    arePageCommentsEnabled: {
      get() {
        if (this.mode === 'CREATE') {
          return this.$store.getters.newPageData.commentsEnabled === 1;
        }

        return this.$store.getters.editPageData.commentsEnabled === 1;
      },
      set(value) {
        const params = {
          field: 'commentsEnabled',
          fieldValue: value === true ? 1 : 0,
        };

        this.$store.dispatch(PAGE_CHANGE_FIELD_VALUE, params).then((response) => {
          this.$log.info('PAGE_CHANGE_FIELD_VALUE', response);
        }).catch((error) => {
          this.$log.error('PAGE_CHANGE_FIELD_VALUE', error);
        });
      },
    },
    pageMode() {
      return this.$store.getters.pageMode;
    },
  },
  methods: {
    save() {
      if (this.pageMode === 'CREATE') {
        this.$store.dispatch(PAGE_CREATE_SUMBIT).then((response) => {
          this.$log.info('PAGE_CREATE_SUMBIT', response);
          this.$toasted.show(response.message, {
            type: 'success',
            duration: 5000,
            icon: 'check',
          });
          this.$router.push({ name: 'pages' });
        }).catch((error) => {
          this.$log.error('PAGE_CREATE_SUMBIT', error);
          this.$toasted.error(error.message, {
            type: 'error',
            duration: 5000,
            icon: 'alert',
          });
        });
      } else if (this.pageMode === 'EDIT') {
        this.$store.dispatch(PAGE_EDIT_SUMBIT).then((response) => {
          this.$log.info('PAGE_EDIT_SUMBIT', response);
          this.$toasted.show(response.message, {
            type: 'success',
            duration: 5000,
            icon: 'check',
          });
          this.$router.push({ name: 'pages' });
        }).catch((error) => {
          this.$log.error('PAGE_EDIT_SUMBIT', error);
          this.$toasted.error(error.message, {
            type: 'error',
            duration: 5000,
            icon: 'alert',
          });
        });
      }
    },
    remove() {
      this.$store.dispatch(PAGE_DELETE_SUBMIT, this.pageData.id).then((response) => {
        this.$log.info('PAGE_DELETE_SUBMIT', response);
        this.$toasted.show(response.message, {
          type: 'success',
          duration: 5000,
          icon: 'check',
        });
        this.$router.push({ name: 'pages' });
      }).catch((error) => {
        this.$log.error('PAGE_DELETE_SUBMIT', error);
        this.$toasted.error(error.message, {
          type: 'error',
          duration: 5000,
          icon: 'alert',
        });
      });
    },
    cancel() {
      this.$router.go(-1);
    },
  },
};
</script>
