<template>
  <div class="listing-page">
    <table class="table table-responsive-lg table-striped table-hover t-vehicles" style="table-layout: fixed; width: 100%;">
      <!--<table class="table table-bordered table-responsive-lg table-striped text-nowrap">-->
      <thead class="bg--white">
      <tr>
        <th
          v-for="column in columns"
          class="text-purple align-top text-center"
          style="word-wrap: normal; padding: 0.75rem 0;"
        >
          {{ column.label }}
        </th>
        <th
          v-if="actions && actions.length"
          class="text-purple align-top text-center"
          style="word-wrap: normal;"
        >Actiuni</th>
      </tr>
      </thead>
      <tbody class="text-center" id="tbody">
      <tr v-for="item in items">
        <td
          v-for="column in columns"
          class="align-top text-center"
          style="word-wrap: break-word; padding: 11px"
        >
          {{ getItemProperty(item, column) }}

        </td>
        <td v-if="Object.keys(actions).length !== 0">
          <a class="text-purple" v-if="actions.view" @click="onView(item)">Arata</a>
          <a class="btn text-purple font-weight-bold" v-if="checkIfActionIsAvailable(actions.edit, item) && item.deleted != 'Ruta Dezactivata'" @click="onEdit(item)">Editeaza</a>
          <a class="btn text-purple font-weight-bold" v-if="checkIfActionIsAvailable(actions.show, item)" @click="onShow(item)">Istoric Situatii Autovehicul</a>
          <a class="btn text-purple font-weight-bold" v-if="checkIfActionIsAvailable(actions.detail, item)" @click="onDetail(item)">Detalii</a>
          <a class="btn text-purple font-weight-bold" v-if="checkIfActionIsAvailable(actions.newRide, item) && (item.deleted != 'Ruta Dezactivata' && item.routeType != 'Intern')" @click="onNewRide(item)">Creare Cursa Noua</a>
          <a class="btn text-purple font-weight-bold" v-if="checkIfActionIsAvailable(actions.cruisePlanning, item) && (item.deleted != 'Ruta Dezactivata' && item.routeType != 'Intern' && item.status != 'Planificata')" @click="onCruisePlanning(item)">Planifica Cursa</a>
          <a class="btn text-purple font-weight-bold" v-if="checkIfActionIsAvailable(actions.onCruisePlanningEdit, item) && (item.deleted != 'Ruta Dezactivata' && item.routeType != 'Intern' && item.status == 'Planificata')" @click="onCruisePlanningEdit(item)">Editeaza Planificarea</a>
          <a class="btn text-purple font-weight-bold" v-if="checkIfActionIsAvailable(actions.onCruisePlanningView, item) && (item.deleted != 'Ruta Dezactivata' && item.routeType != 'Intern' && item.status == 'Planificata')" @click="onCruisePlanningView(item)">Vizualizare Planificare</a>
          <a class="btn text-purple font-weight-bold" v-if="checkIfActionIsAvailable(actions.onCruisePlanningDelete, item) && (item.deleted != 'Ruta Dezactivata' && item.routeType != 'Intern' && item.status == 'Planificata')" @click="onCruisePlanningDelete(item)">Sterge Planificarea</a>
          <a class="btn text-purple font-weight-bold" v-if="checkIfActionIsAvailable(actions.close, item)" @click="onClose(item)">Inchide</a>
          <a class="btn text-purple font-weight-bold" v-if="checkIfActionIsAvailable(actions.delete, item) && item.deleted != 'Ruta Dezactivata'" @click="onDelete(item)">Stergere</a>
          <a class="btn text-purple font-weight-bold" v-if="checkIfActionIsAvailable(actions.logbooks, item)" @click="onManageLogbooks(item)">Foi de parcurs</a>
<!--          <md-button class="md-flat md-primary md-icon-button btn btn-purple btn-block shadow" @click="onView(item)">-->
<!--            <md-icon><i class="far fa-eye"></i></md-icon>-->
<!--          </md-button>-->
<!--          <md-button class="md-flat md-primary md-icon-button" @click="onEdit(item)">-->
<!--            <md-icon><i class="far fa-edit"></i></md-icon>-->
<!--          </md-button>-->
<!--          <md-button class="md-flat md-primary md-icon-button" @click="onRemove(item)">-->
<!--            <md-icon><i class="far fa-trash-alt"></i></md-icon>-->
<!--          </md-button>-->
        </td>
      </tr>

      </tbody>
    </table>
  </div>

</template>

<script>
  import moment from 'moment';
  import {isUndefined, isFunction} from 'lodash';
  import vehicleStatusValues from '@/enums/vehicleStatusValues';
  import i18n from '@/plugins/i18n'

export default {
  name: 'ListingPage',
  props: {
    items: {
      type: Array,
      default() { return []; },
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    actions: {
      type: Object,
      default() { return {}; },
    }
  },
  data: () => ({
    selected: {},
  }),

  methods: {
    checkIfActionIsAvailable(action, item) {
      if (isUndefined(action)) {
        return false;
      } else if (isFunction(action)) {
        return action(item);
      }
      return action;
    },
    onView(item) {
      this.$emit('onView', item);
    },
    onCreate() {
      this.$emit('onCreate');
    },
    onEdit(item) {
      this.$emit('onEdit', item);
    },
    onShow(item) {
      this.$emit('onShow', item);
    },
    onDetail(item) {
      this.$emit('onDetail', item);
    },
    onNewRide(item) {
      this.$emit('onNewRide', item);
    },
    onCruisePlanning(item) {
      this.$emit('onCruisePlanning', item);
    },
    onCruisePlanningView(item) {
      this.$emit('onCruisePlanningView', item);
    },
    onCruisePlanningEdit(item) {
      this.$emit('onCruisePlanningEdit', item);
    },
    onCruisePlanningDelete(item) {
      this.$emit('onCruisePlanningDelete', item);
    },
    onManageLogbooks(item) {
      this.$emit('onManageLogbooks', item);
    },
    onRemove(item) {
      this.$emit('onRemove', item);
    },
    onClose(item) {
      this.$emit('onClose', item);
    },
    onDelete(item) {
      this.$emit('onDelete', item);
    },
    getClass(item) {
      if (item.enabled === 0) return 'page-enabled';
      if (item.enabled === 1) return 'page-disabled';
      return '';
    },
    getItemProperty: function (item, column) {
      let property = column.name;
      let initialItem = item;
      const {type, mapper, mapItem} = column;
      if (Array.isArray(property)) {
        let extractedKey = [];
        for (let propertyKeyNames of property) {
          let itemValue = item;
          const keyNames = propertyKeyNames.split('.');
          while (keyNames.length && (itemValue = itemValue[keyNames.shift()])) ;
          extractedKey.push(itemValue)
        }
        return extractedKey.join(' ');
      } else {
        const properties = property.split('.');
        while (properties.length && (item = item[properties.shift()])) ;
      }

      if (type != undefined && type == 'datetime') {
        return item ? moment(item).format('DD/MM/YYYY, h:mm:ss') : '';
      }

      if (type != undefined && type == 'date') {
        return item ? moment(item).format('DD/MM/YYYY') : '';
      }

      if (mapper != undefined) {
        return item ? mapper(item) : '';
      }

      if (mapItem != undefined) {
        return mapItem(initialItem);
      }
      this.$i18n.locale = 'ro';

      if (item === vehicleStatusValues.transfer) {
        item = this.$t('transfer');
      } else if (item === vehicleStatusValues.course) {
        item = this.$t('course');
      } else if (item === vehicleStatusValues.transferAndCourse) {
        item = this.$t('transferAndCourse');
      } else if (item === vehicleStatusValues.damaged) {
        item = this.$t('damaged');
      } else if (item === vehicleStatusValues.broken) {
        item = this.$t('broken');
      } else if (item === vehicleStatusValues.withoutDriver) {
        item = this.$t('withoutDriver');
      } else if (item === vehicleStatusValues.withoutDocuments) {
        item = this.$t('withoutDocuments');
      } else if (item === vehicleStatusValues.service) {
        item = this.$t('service');
      } else if (item === vehicleStatusValues.ontrack) {
        item = this.$t('ontrack');
      }
      return item;
    },

    onSelect(item) {
      this.selected = item;
    },
    isNumeric(item, property) {
      return !isNaN(this.getItemProperty(item, property));
    },
  },
  computed: {
    currentRouteName() {
      return this.$i18n.t(`${this.$route.name}`);
    },
    isDisabled: function(){
      return !this.terms;
    }
  },
}
</script>

<style lang="scss">
  /*@import '@/assets/scss/layout/listing-page.scss';*/
</style>
