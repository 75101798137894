import RomfourVehicleAutosuggest from "@/components/autosuggest/RomfourVehiclesCruisePlanning";
import CruisePlanningCreateStep4 from "@/views/CruisePlanning/CruisePlanningCreateStep4/CruisePlanningCreateStep4.vue";
import {
  CRUISE_RESERVATIONS_MAIN_VEHICLE_SUBMIT,
  CRUISE_RESERVATIONS_TRANSFER_VEHICLE_SUBMIT,
  CRUISE_RESERVATIONS_DELETE_SUBMIT,
  CRUISE_RESERVATIONS_MAIN_TRAILER_VEHICLE_SUBMIT,
  CRUISE_RESERVATIONS_MAIN_BACKUP_VEHICLE_SUBMIT
} from "@/store/actions.type";
import Vue from "vue";
import { required } from "vuelidate/lib/validators";

export default {
  name: 'Cruiseplanningcreatestep3',

  components: {
    RomfourVehicleAutosuggest,
    CruisePlanningCreateStep4
  },

  mixins: [],

  props: {
    slug: Number | String,
    reservations: Object | Array,
    savedCruiseId: Number,
    formDataStep1: Object,
    formDataStep2: Object,
    routeId: Number | String,
    routeTypeId: Number | String,
  },

  data: () => ({
    show: {
      createRoute: false,
      selectReservation: false,
      createRoutesStep2: false,
      createRoutesStep3: true,
    },
    selectedMainVehicles: {},
    selectedTrailers: {},
    selectMainBackupVehicles: {},
    selectedTransferVehicles: [],
    selectedVehicle: null,
    selectedVehicleArr: [],
    cruiseId: null,
    submitStatus: false,
    type: 'trailer',
  }),
  mounted() {
    this.initSelectedVehicles();
  },

  methods: {
    //Display vehicles for each country
    initSelectedVehicles() {
      const keys = Object.keys(this.reservations);
      for(let key of keys) {
        this.selectedMainVehicles[key] = {};
        this.selectMainBackupVehicles[key] = {};
        this.selectedTrailers[key] = {};
      }
      const ReservationValues = Object.values(this.reservations);
      ReservationValues.forEach(reservations => {
        reservations.forEach(reservation => {
          this.selectedTransferVehicles[reservation.id] = {};
        })
      })
    },
    //Change the template for each step
    changeDisplay(createRoute, reservation, createRouteStep2, createRouteStep3, createRouteStep4) {
      this.show = {
        createRoute: createRoute,
        selectReservation: reservation,
        createRoutesStep2: createRouteStep2,
        createRoutesStep3: createRouteStep3,
        createRoutesStep4: createRouteStep4,
      }
    },
    //Get the current step
    onSelectSteps(item) {
      this.selectedReservations = item;
      this.show = {
        createRoute: false,
        createRouteStep2: false,
        createRouteStep3: true,
        createRoutesStep4: false,
      }
    },
    //Deletes the saved reservation when the step back from step 3 is clicked
    onSelectPreviousStep() {
      this.cruiseId = this.savedCruiseId;
      this.$store.dispatch(CRUISE_RESERVATIONS_DELETE_SUBMIT, this.cruiseId).then((response) => {
        Vue.notify({
          group: 'success',
          type: 'success',
          title: 'Rezervarile Au fost sterse pentru cursa curenta!',
        })
        this.$emit('onSelectSteps', false,false,true,false);
        this.$log.info('CRUISE_RESERVATIONS_DELETE_SUBMIT', response);
      }).catch((error) => {
        this.$log.error('CRUISE_RESERVATIONS_DELETE_SUBMIT', error);
      });
    },
    //Save the main vehicle for each country
    selectMainVehicle(item) {
      this.selectedMainVehicles[item.country] = {
        'vehicle': item.numarInmatriculare,
      };
      this.selectedVehicleArr[item.country] = item ? item.codAutovehicul : null;
      this.$store.dispatch(CRUISE_RESERVATIONS_MAIN_VEHICLE_SUBMIT, item).then((response) => {
        Vue.notify({
          group: 'success',
          type: 'success',
          title: 'Autovehiculul Principal a fost salvat!',
        })
        this.$log.info('CRUISE_RESERVATIONS_MAIN_VEHICLE_SUBMIT', response);
      }).catch((error) => {
        this.$log.error('CRUISE_RESERVATIONS_MAIN_VEHICLE_SUBMIT', error);
      });
    },
    //Save the transfer vehicle on each reservation
    selectTransferVehicle(item) {
      this.selectedVehicleArr[item.country] = item ? item.codAutovehicul : null;
      this.$store.dispatch(CRUISE_RESERVATIONS_TRANSFER_VEHICLE_SUBMIT, item).then((response) => {
        Vue.notify({
          group: 'success',
          type: 'success',
          title: 'Autovehiculul de transfer a fost salvat!',
        })
        this.$log.info('CRUISE_RESERVATIONS_TRANSFER_VEHICLE_SUBMIT', response);
      }).catch((error) => {
        this.$log.error('CRUISE_RESERVATIONS_TRANSFER_VEHICLE_SUBMIT', error);
      });
    },
    onSubmitCruiseReservationDefinitionCar(e) {
      this.submitStatus = false;
      Object.entries(this.selectedMainVehicles).forEach(mainVehicle => {
        if (Object.keys(mainVehicle[1]).length === 0) {
          this.submitStatus = true;
        }
      });
      if( this.submitStatus === false) {
        this.changeDisplay(false, false,  false, false, true);
      }
    }
  },
  watch: {
    slug: {
      handler(val) {
        this.initPageData(val);
      },
    },
  },
}
