<template>
  <div>
    <div class="row pt-5 pb-3">
      <div class="col">
        <h1 class="text-purple pl-1">Vehicul {{licencePlate}}</h1>
      </div>
    </div>

    <form action="" @submit="onSubmitVehicle()" @submit.prevent="onSubmit" enctype="multipart/form-data" method="post">
      <div class="form-row">
        <div class="card rounded card-custom ml-md-2 mr-md-2 shadow-lg">
          <div class="card-header">
            <h4 class="text-purple">Date</h4>
          </div>
            <div class="card-body">
              <div class="form-row">
                <div class="col-md-3 pr-md-5">
                  <div class="form-group">
                    <label class="text-purple pl-1">
                      Status
                    </label>
                    <select class="form-control mb-3" id="statusAuto" label="Status Vehicul" v-model="formData.statusAuto" style="max-width: 360px">
                      <option value="" disabled></option>
                      <option value="activ">Activ</option>
                      <option value="inactiv">Inactiv</option>
                    </select>
                    <small class="form-text pl-1 error" v-if="submitStatus && !$v.formData.statusAuto.required">Acest camp este obligatoriu</small>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label class="text-purple pl-1">
                      Disponibilitate
                    </label>
                    <select class="form-control mb-3" id="drivingStatus" label="Disponibilitate" v-model="formData.drivingStatus" style="max-width: 360px">
                      <option disabled value=""></option>
                      <option value="acte oprite">acte oprite</option>
                      <option value="activa/ruleaza">activa/ruleaza</option>
                      <option value="avariata">avariata</option>
                      <option value="defect">defect</option>
                      <option value="disparuta">disparuta</option>
                      <option value="donata">donata</option>
                      <option value="furata">furata</option>
                      <option value="la casat">la casat</option>
                      <option value="la radiat">la radiat</option>
                      <option value="la vanzare">la vanzare</option>
                      <option value="lasata in parcare">lasata in parcare</option>
                      <option value="libera">libera</option>
                      <option value="lipsa valabilitate acte">lipsa valabilitate acte</option>
                      <option value="parc">parc</option>
                      <option value="retinut UE">retinut UE</option>
                      <option value="service">service</option>
                      <option value="transferuri">transferuri</option>
                      <option value="vandut">vandut</option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="header">
                <h5 class="text-purple">Preferinte</h5>
              </div>
              <div class="form-row">
                <div class="form-group col-md-3 pr-md-5">
                  <label class="text-purple pl-1">Sofer Principal</label>
                  <romfour-driver-autosuggest
                    v-model="mainDriver"
                    @onSelectSuggestion="selectMainDriverHandler"
                  />
<!--                  <vue-autosuggest-->
<!--                    ref="autocomplete"-->
<!--                    v-model="mainDriver"-->
<!--                    :suggestions="drivers"-->
<!--                    :inputProps="sugestionsProps"-->
<!--                    :renderSuggestion="renderSuggestion"-->
<!--                    :getSuggestionValue="getSuggestionValue"-->
<!--                    @input="fetchDrivers"-->
<!--                    @selected="selectMainDriverHandler"-->
<!--                  />-->

                  <small class="form-text pl-1 invalid-feedback">Acest camp este obligatoriu</small>
                </div>
                <div class="form-group col-md-3 pr-md-4 pl-md-1">
                  <label class="text-purple pl-1">Sofer Rezerva</label>
                  <romfour-driver-autosuggest
                    v-model="backupDriver"
                    @onSelectSuggestion="selectBackupDriverHandler"
                  />
<!--                  <vue-autosuggest-->
<!--                    ref="autocomplete"-->
<!--                    v-model="backupDriver"-->
<!--                    :suggestions="drivers"-->
<!--                    :inputProps="sugestionsProps"-->
<!--                    :renderSuggestion="renderSuggestion"-->
<!--                    :getSuggestionValue="getSuggestionValue"-->
<!--                    @input="fetchDrivers"-->
<!--                    @selected="selectBackupDriverHandler"-->
<!--                  />-->
                  <small class="form-text pl-1 invalid-feedback">Acest camp este obligatoriu</small>
                </div>
                <div class="form-group col-md-3 pr-md-4 pl-md-1">
                  <label class="text-purple pl-1">Sofer Rezerva 2</label>
                  <romfour-driver-autosuggest
                    v-model="thirdDriver"
                    @onSelectSuggestion="selectThirdDriverHandler"
                  />
<!--                  <vue-autosuggest-->
<!--                    ref="autocomplete"-->
<!--                    v-model="backupDriver"-->
<!--                    :suggestions="drivers"-->
<!--                    :inputProps="sugestionsProps"-->
<!--                    :renderSuggestion="renderSuggestion"-->
<!--                    :getSuggestionValue="getSuggestionValue"-->
<!--                    @input="fetchDrivers"-->
<!--                    @selected="selectBackupDriverHandler"-->
<!--                  />-->
                  <small class="form-text pl-1 invalid-feedback">Acest camp este obligatoriu</small>
                </div>
                <div class="form-group col-md-3 pl-md-5">
                  <label class="text-purple pl-1">Remorca</label>
                  <vue-autosuggest
                    ref="autocomplete"
                    v-model="trailer"
                    :suggestions="trailers"
                    :inputProps="sugestionsProps"
                    :renderSuggestion="renderSuggestion"
                    :getSuggestionValue="getSuggestionValue"
                    @input="fetchTrailers"
                    @selected="selectTrailerHandler"
                  />
                  <small class="form-text pl-1 invalid-feedback">Acest camp este obligatoriu</small>
                </div>
              </div>
            </div>
        </div>
      </div>

      <div class="form-row">
        <div class="card rounded card-custom ml-md-2 mr-md-2 shadow-lg">
          <div class="card-header text-purple">
            <strong class="pl-1 font-weight-bold">Asigurari</strong>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-4">
                <div class="form-group flex-column flex">
                  <label class="text-purple d-block pl-1">RCA</label>
                </div>
                <div class="form-group">
                  <label class="text-purple pl-1">
                    Data expirarii
                  </label>
                  <datepicker :clear-button="true" :bootstrap-styling="true" input-class="form-control" v-model="formData.documents.rcaInsurance.expirationDate" name="goodsCertExpDate"></datepicker>
                  <small class="form-text pl-1 invalid-feedback">Acest camp este obligatoriu</small>
                </div>
                <div class="form-group custom-height">
                  <label class="text-purple pl-1">
                    Document RCA
                  </label>
                  <div class="custom-file">
                    <input type="file" class="custom-file-input" id="goodsCertExpDate-file" ref="goodsTransportCertificate"  v-on:change="onFileChange($event, 'rcaInsurance')"/>
                    <label class="custom-file-label" for="goodsCertExpDate-file">
                      <span v-if="formData.documents.rcaInsurance.file">{{formData.documents.rcaInsurance.file.name}}</span>
                      <span v-if="!formData.documents.rcaInsurance.file">Incarca fisier</span>
                    </label>
                  </div>
                </div>
                <div class="form-group">
                  <rfr-file-previewer
                    title="RCA"
                    id="rcaInsurance"
                    :file="formData.documents.rcaInsurance.fileUploaded"
                    v-if="formData.documents.rcaInsurance.fileUploaded"
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group flex-column flex">
                  <label class="text-purple d-block pl-1">CASCO</label>
                </div>
                <div class="form-group">
                  <label class="text-purple pl-1">
                    Data expirarii
                  </label>
                  <datepicker :clear-button="true" :bootstrap-styling="true" input-class="form-control" v-model="formData.documents.cascoInsurance.expirationDate" name="goodsCertExpDate"></datepicker>
                  <small class="form-text pl-1 invalid-feedback">Acest camp este obligatoriu</small>
                </div>
                <div class="form-group custom-height">
                  <label class="text-purple pl-1">
                    Document
                  </label>
                  <div class="custom-file">
                    <input type="file" class="custom-file-input" id="casco-file" ref="goodsTransportCertificate"  v-on:change="onFileChange($event, 'cascoInsurance')"/>
                    <label class="custom-file-label" for="goodsCertExpDate-file">
                      <span v-if="formData.documents.cascoInsurance.file">{{formData.documents.cascoInsurance.file.name}}</span>
                      <span v-if="!formData.documents.cascoInsurance.file">Incarca fisier</span>
                    </label>
                  </div>
                  </div>
                <div class="form-group">
                  <rfr-file-previewer
                    title="Casco"
                    id="cascoInsurance"
                    :file="formData.documents.cascoInsurance.fileUploaded"
                    v-if="formData.documents.cascoInsurance.fileUploaded"
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group flex-column flex">
                  <label class="text-purple d-block pl-1">CMR</label>
                </div>
                <div class="form-group">
                  <label class="text-purple pl-1">
                    Data expirarii
                  </label>
                  <datepicker :clear-button="true" :bootstrap-styling="true" input-class="form-control" v-model="formData.documents.cmrInsurance.expirationDate" name="cmrExpDate"></datepicker>
                  <small class="form-text pl-1 invalid-feedback">Acest camp este obligatoriu</small>
                </div>
                <div class="form-group custom-height">
                  <label class="text-purple pl-1">
                    Document CMR
                  </label>
                  <div class="custom-file">
                    <input type="file" class="custom-file-input" id="cmr-file" ref="goodsTransportCertificate"  v-on:change="onFileChange($event, 'cmrInsurance')"/>
                    <label class="custom-file-label" for="goodsCertExpDate-file">
                      <span v-if="formData.documents.cmrInsurance.file">{{formData.documents.cmrInsurance.file.name}}</span>
                      <span v-if="!formData.documents.cmrInsurance.file">Incarca fisier</span>
                    </label>
                  </div>
                  </div>
                <div class="form-group">
                  <rfr-file-previewer
                    title="CMR"
                    id="cmrInsurance"
                    :file="formData.documents.cmrInsurance.fileUploaded"
                    v-if="formData.documents.cmrInsurance.fileUploaded"
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group flex-column flex">
                  <label class="text-purple d-block pl-1">ACC</label>
                </div>
                <div class="form-group">
                  <label class="text-purple pl-1">
                    Data expirarii
                  </label>
                  <datepicker input-class="form-control" v-model="formData.documents.accInsurance.expirationDate" name="goodsCertExpDate"></datepicker>
                  <small class="form-text pl-1 invalid-feedback">Acest camp este obligatoriu</small>
                </div>
                <div class="form-group custom-height">
                  <label class="text-purple pl-1">
                    Document
                  </label>
                  <div class="custom-file">
                    <input type="file" class="custom-file-input" id="acc-file" ref="goodsTransportCertificate"  v-on:change="onFileChange($event, 'accInsurance')"/>
                    <label class="custom-file-label" for="goodsCertExpDate-file">
                      <span v-if="formData.documents.accInsurance.file">{{formData.documents.accInsurance.file.name}}</span>
                      <span v-if="!formData.documents.accInsurance.file">Incarca fisier</span>
                    </label>
                  </div>
                </div>
                <div class="form-group">
                  <rfr-file-previewer
                    title="ACC"
                    id="accInsurance"
                    :file="formData.documents.accInsurance.fileUploaded"
                    v-if="formData.documents.accInsurance.fileUploaded"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-3 col-sm-12">
          <div class="card rounded card-custom ml-md-2 mr-md-2 shadow-lg">
            <div class="card-header text-purple">
              <strong class="pl-1 font-weight-bold">Leasing</strong>
            </div>
            <div class="card-body">
              <div class="form-group">
                <label class="text-purple pl-1">
                  Data expirarii
                </label>
                <datepicker :clear-button="true" :bootstrap-styling="true" input-class="form-control" v-model="formData.documents.leasing.expirationDate" name="leasingExpirationDate"></datepicker>
                <small class="form-text pl-1 invalid-feedback">Acest camp este obligatoriu</small>
              </div>
              <div class="form-group">
                <label class="text-purple pl-1">
                  Document
                </label>
                <div class="custom-file">
                  <input type="file" class="custom-file-input" id="leasing-file" ref="leasing"  v-on:change="onFileChange($event, 'leasing')"/>
                  <label class="custom-file-label" for="leasing-file">
                    <span v-if="formData.documents.leasing.file">{{formData.documents.leasing.file.name}}</span>
                    <span v-if="!formData.documents.leasing.file">Incarca fisier</span>
                  </label>
                </div>
              </div>
              <div class="form-group">
                <rfr-file-previewer
                  title="Document"
                  id="leasing"
                  :file="formData.documents.leasing.fileUploaded"
                  v-if="formData.documents.leasing.fileUploaded"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-12">
          <div class="card rounded card-custom ml-md-2 mr-md-2 shadow-lg">
            <div class="card-header text-purple">
              <strong class="pl-1 font-weight-bold">Tahograf</strong>
            </div>
            <div class="card-body">
              <div class="form-group">
                <label class="text-purple pl-1">
                  Data expirarii
                </label>
                <datepicker :clear-button="true" :bootstrap-styling="true" input-class="form-control" v-model="formData.documents.tahograf.expirationDate" name="tahografExpirationDate"></datepicker>

                <small class="form-text pl-1 invalid-feedback">Acest camp este obligatoriu</small>
              </div>
              <div class="form-group">
                <label class="text-purple pl-1">
                  Document
                </label>
                <div class="custom-file">
                  <input type="file" class="custom-file-input" id="tahograf-file" ref="tahograf"  v-on:change="onFileChange($event, 'tahograf')"/>
                  <label class="custom-file-label" for="tahograf-file">
                    <span v-if="formData.documents.tahograf.file">{{formData.documents.tahograf.file.name}}</span>
                    <span v-if="!formData.documents.tahograf.file">Incarca fisier</span>
                  </label>
                </div>
              </div>
              <div class="form-group">
                <rfr-file-previewer
                  title="Document"
                  id="tahograf"
                  :file="formData.documents.tahograf.fileUploaded"
                  v-if="formData.documents.tahograf.fileUploaded"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-12">
          <div class="card rounded card-custom ml-md-2 mr-md-2 shadow-lg">
            <div class="card-header text-purple">
              <strong class="pl-1 font-weight-bold">ITP</strong>
            </div>
            <div class="card-body">
                <div class="form-group">
                  <label class="text-purple pl-1">
                    Data expirarii
                  </label>
                  <datepicker :clear-button="true" :bootstrap-styling="true" input-class="form-control" v-model="formData.documents.itp.expirationDate" name="itpExpirationDate"></datepicker>
                  <small class="form-text pl-1 invalid-feedback">Acest camp este obligatoriu</small>
                </div>
                <div class="form-group">
                  <label class="text-purple pl-1">
                    Document
                  </label>
                  <div class="custom-file">
                    <input type="file" class="custom-file-input" id="itp-file" ref="itp"  v-on:change="onFileChange($event, 'itp')"/>
                    <label class="custom-file-label" for="itp-file">
                      <span v-if="formData.documents.itp.file">{{formData.documents.itp.file.name}}</span>
                      <span v-if="!formData.documents.itp.file">Incarca fisier</span>
                    </label>
                  </div>
                  </div>
              <div class="form-group">
                <rfr-file-previewer
                  title="Document"
                  id="itp"
                  :file="formData.documents.itp.fileUploaded"
                  v-if="formData.documents.itp.fileUploaded"
                />
              </div>
            </div>
        </div>
        </div>
        <div class="col-md-3 col-sm-12">
          <div class="card rounded card-custom ml-md-2 mr-md-2 shadow-lg">
            <div class="card-header text-purple">
              <strong class="pl-1 font-weight-bold">Carte de identitate</strong>
            </div>
            <div class="card-body">
              <div class="form-group">
                <label class="text-purple pl-1">
                  Document
                </label>
                <div class="custom-file">
                  <input type="file" class="custom-file-input" id="ci-file" ref="leasing"  v-on:change="onFileChange($event, 'ci')"/>
                  <label class="custom-file-label" for="ci-file">
                    <span v-if="formData.documents.ci.file">{{formData.documents.ci.file.name}}</span>
                    <span v-if="!formData.documents.ci.file">Incarca fisier</span>
                  </label>
                </div>
              </div>
              <div class="form-group">
                <rfr-file-previewer
                  title="Document"
                  id="ci"
                  :file="formData.documents.ci.fileUploaded"
                  v-if="formData.documents.ci.fileUploaded"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-4">
          <div class="card rounded card-custom ml-md-2 mr-md-2 shadow-lg">
            <div class="card-header text-purple">
              <strong class="pl-1 font-weight-bold">Clasificare</strong>
            </div>
            <div class="card-body">
              <div class="form-group">
                <label class="text-purple pl-1">
                  Data expirarii
                </label>
                <datepicker :clear-button="true" :bootstrap-styling="true" input-class="form-control" v-model="formData.documents.clasificare.expirationDate" name="clasificareExpirationDate"></datepicker>
                <small class="form-text pl-1 invalid-feedback">Acest camp este obligatoriu</small>
              </div>
              <div class="form-group">
                <label class="text-purple pl-1">
                  Document
                </label>
                <div class="custom-file">
                  <input type="file" class="custom-file-input" id="clasificare-file" ref="clasificare"  v-on:change="onFileChange($event, 'clasificare')"/>
                  <label class="custom-file-label" for="clasificare-file">
                    <span v-if="formData.documents.clasificare.file">{{formData.documents.clasificare.file.name}}</span>
                    <span v-if="!formData.documents.clasificare.file">Incarca fisier</span>
                  </label>
                </div>
                </div>
              <div class="form-group">
                <rfr-file-previewer
                  title="Document"
                  id="clasificare"
                  :file="formData.documents.clasificare.fileUploaded"
                  v-if="formData.documents.clasificare.fileUploaded"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card rounded card-custom ml-md-2 mr-md-2 shadow-lg">
            <div class="card-header text-purple">
              <strong class="pl-1 font-weight-bold">COZUT</strong>
            </div>
            <div class="card-body">
              <div class="form-group">
                <label class="text-purple pl-1">
                  Data expirarii
                </label>
                <datepicker :clear-button="true" :bootstrap-styling="true" input-class="form-control" v-model="formData.documents.cozut.expirationDate" name="cozutExpirationDate"></datepicker>
                <small class="form-text pl-1 invalid-feedback">Acest camp este obligatoriu</small>
              </div>
              <div class="form-group">
                <label class="text-purple pl-1">
                  Document
                </label>
                <div class="custom-file">
                  <input type="file" class="custom-file-input" id="cozut-file" ref="cozut"  v-on:change="onFileChange($event, 'cozut')"/>
                  <label class="custom-file-label" for="cozut-file">
                    <span v-if="formData.documents.cozut.file">{{formData.documents.cozut.file.name}}</span>
                    <span v-if="!formData.documents.cozut.file">Incarca fisier</span>
                  </label>
                </div>
                </div>
              <div class="form-group">
                <rfr-file-previewer
                  title="Document"
                  id="cozut"
                  :file="formData.documents.cozut.fileUploaded"
                  v-if="formData.documents.cozut.fileUploaded"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card rounded card-custom ml-md-2 mr-md-2 shadow-lg">
            <div class="card-header text-purple">
              <strong class="pl-1 font-weight-bold">CNADR</strong>
            </div>
            <div class="card-body">
              <div class="form-group">
                <label class="text-purple pl-1">
                  Data expirarii
                </label>
                <datepicker :clear-button="true" :bootstrap-styling="true" input-class="form-control" v-model="formData.documents.cnadr.expirationDate" name="cnadrExpirationDate"></datepicker>
                <small class="form-text pl-1 invalid-feedback">Acest camp este obligatoriu</small>
              </div>
              <div class="form-group">
                <label class="text-purple pl-1">
                  Document
                </label>
                <div class="custom-file">
                  <input type="file" class="custom-file-input" id="cnadr-file" ref="cnadr"  v-on:change="onFileChange($event, 'cnadr')"/>
                  <label class="custom-file-label" for="cnadr-file">
                    <span v-if="formData.documents.cnadr.file">{{formData.documents.cnadr.file.name}}</span>
                    <span v-if="!formData.documents.cnadr.file">Incarca fisier</span>
                  </label>
                </div>
                </div>
              <div class="form-group">
                <rfr-file-previewer
                  title="Document"
                  id="cnadr"
                  :file="formData.documents.cnadr.fileUploaded"
                  v-if="formData.documents.cnadr.fileUploaded"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-4">
          <div class="card rounded card-custom ml-md-2 mr-md-2 shadow-lg">
            <div class="card-header text-purple">
              <strong class="pl-1 font-weight-bold">Licenta Transport Marfa</strong>
            </div>
            <div class="card-body">
              <div class="form-group">
                <label class="text-purple pl-1">
                  Data expirarii
                </label>
                <datepicker :clear-button="true" :bootstrap-styling="true" input-class="form-control" v-model="formData.documents.goodsTrCert.expirationDate" name="goodsTrCertExpirationDate"></datepicker>
                <small class="form-text pl-1 invalid-feedback">Acest camp este obligatoriu</small>
              </div>
              <div class="form-group">
                <label class="text-purple pl-1">
                  Document
                </label>
                <div class="custom-file">
                  <input type="file" class="custom-file-input" id="goodsTrCert-file" ref="goodsTrCert"  v-on:change="onFileChange($event, 'goodsTrCert')"/>
                  <label class="custom-file-label" for="goodsTrCert-file">
                    <span v-if="formData.documents.goodsTrCert.file">{{formData.documents.goodsTrCert.file.name}}</span>
                    <span v-if="!formData.documents.goodsTrCert.file">Incarca fisier</span>
                  </label>
                </div>
                </div>
              <div class="form-group">
                <rfr-file-previewer
                  title="Document"
                  id="goodsTrCert"
                  :file="formData.documents.goodsTrCert.fileUploaded"
                  v-if="formData.documents.goodsTrCert.fileUploaded"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card rounded card-custom ml-md-2 mr-md-2 shadow-lg">
            <div class="card-header text-purple">
              <strong class="pl-1 font-weight-bold">Licenta Transport Persoane</strong>
            </div>
            <div class="card-body">
              <div class="form-group">
                <label class="text-purple pl-1">
                  Data expirarii
                </label>
                <datepicker :clear-button="true" :bootstrap-styling="true" input-class="form-control" v-model="formData.documents.peopleTrCert.expirationDate" name="peopleTrCertExpirationDate"></datepicker>
                <small class="form-text pl-1 invalid-feedback">Acest camp este obligatoriu</small>
              </div>
              <div class="form-group">
                <label class="text-purple pl-1">
                  Document
                </label>
                <div class="custom-file">
                  <input type="file" class="custom-file-input" id="peopleTrCert-file" ref="peopleTrCert"  v-on:change="onFileChange($event, 'peopleTrCert')"/>
                  <label class="custom-file-label" for="peopleTrCert-file">
                    <span v-if="formData.documents.peopleTrCert.file">{{formData.documents.peopleTrCert.file.name}}</span>
                    <span v-if="!formData.documents.peopleTrCert.file">Incarca fisier</span>
                  </label>
                </div>
                </div>
              <div class="form-group">
                <rfr-file-previewer
                  title="Document"
                  id="peopleTrCert"
                  :file="formData.documents.peopleTrCert.fileUploaded"
                  v-if="formData.documents.peopleTrCert.fileUploaded"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="card rounded card-custom ml-md-2 mr-md-2 shadow-lg">
          <div class="card-header text-purple">
            <strong class="pl-1 font-weight-bold">Vignieta</strong>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-4">
                <div class="form-group flex-column flex">
                  <label class="text-purple d-block pl-1">Romania</label>
                </div>
                <div class="form-group">
                  <label class="text-purple pl-1">
                    Data expirarii
                  </label>
                  <datepicker :clear-button="true" :bootstrap-styling="true" input-class="form-control" v-model="formData.documents.roVignieta.expirationDate" name="roVignietaExpDate"></datepicker>
                  <small class="form-text pl-1 invalid-feedback">Acest camp este obligatoriu</small>
                </div>
                <div class="form-group custom-height">
                  <label class="text-purple pl-1">
                    Document
                  </label>
                  <div class="custom-file">
                    <input type="file" class="custom-file-input" id="roVignieta-file" ref="roVignieta"  v-on:change="onFileChange($event, 'roVignieta')"/>
                    <label class="custom-file-label" for="roVignieta-file">
                      <span v-if="formData.documents.roVignieta.file">{{formData.documents.roVignieta.file.name}}</span>
                      <span v-if="!formData.documents.roVignieta.file">Incarca fisier</span>
                    </label>
                  </div>
                  </div>
                <div class="form-group">
                  <rfr-file-previewer
                    title="Document"
                    id="roVignieta"
                    :file="formData.documents.roVignieta.fileUploaded"
                    v-if="formData.documents.roVignieta.fileUploaded"
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group flex-column flex">
                  <label class="text-purple d-block pl-1">Ungaria</label>
                </div>
                <div class="form-group">
                  <label class="text-purple pl-1">
                    Data expirarii
                  </label>
                  <datepicker :clear-button="true" :bootstrap-styling="true" input-class="form-control" v-model="formData.documents.huVignieta.expirationDate" name="huVignietaExpDate"></datepicker>
                  <small class="form-text pl-1 invalid-feedback">Acest camp este obligatoriu</small>
                </div>
                <div class="form-group custom-height">
                  <label class="text-purple pl-1">
                    Document
                  </label>
                  <div class="custom-file">
                    <input type="file" class="custom-file-input" id="huVignieta-file" ref="huVignieta"  v-on:change="onFileChange($event, 'huVignieta')"/>
                    <label class="custom-file-label" for="huVignieta-file">
                      <span v-if="formData.documents.huVignieta.file">{{formData.documents.huVignieta.file.name}}</span>
                      <span v-if="!formData.documents.huVignieta.file">Incarca fisier</span>
                    </label>
                  </div>
                  </div>
                <div class="form-group">
                  <rfr-file-previewer
                    title="Document"
                    id="huVignieta"
                    :file="formData.documents.huVignieta.fileUploaded"
                    v-if="formData.documents.huVignieta.fileUploaded"
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group flex-column flex">
                  <label class="text-purple d-block pl-1">Austria</label>
                </div>
                <div class="form-group">
                  <label class="text-purple pl-1">
                    Data expirarii
                  </label>
                  <datepicker :clear-button="true" :bootstrap-styling="true" input-class="form-control" v-model="formData.documents.atVignieta.expirationDate" name="atVignietaExpDate"></datepicker>
                  <small class="form-text pl-1 invalid-feedback">Acest camp este obligatoriu</small>
                </div>
                <div class="form-group custom-height">
                  <label class="text-purple pl-1">
                    Document
                  </label>
                  <div class="custom-file">
                    <input type="file" class="custom-file-input" id="atVignieta-file" ref="atVignieta"  v-on:change="onFileChange($event, 'atVignieta')"/>
                    <label class="custom-file-label" for="atVignieta-file">
                      <span v-if="formData.documents.atVignieta.file">{{formData.documents.atVignieta.file.name}}</span>
                      <span v-if="!formData.documents.atVignieta.file">Incarca fisier</span>
                    </label>
                  </div>
                  </div>
                <div class="form-group">
                  <rfr-file-previewer
                    title="Document"
                    id="atVignieta"
                    :file="formData.documents.atVignieta.fileUploaded"
                    v-if="formData.documents.atVignieta.fileUploaded"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group flex-column flex">
                  <label class="text-purple d-block pl-1">Cehia</label>
                </div>
                <div class="form-group">
                  <label class="text-purple pl-1">
                    Data expirarii
                  </label>
                  <datepicker :clear-button="true" :bootstrap-styling="true" input-class="form-control" v-model="formData.documents.chVignieta.expirationDate" name="chVignietaExpDate"></datepicker>
                  <small class="form-text pl-1 invalid-feedback">Acest camp este obligatoriu</small>
                </div>
                <div class="form-group custom-height">
                  <label class="text-purple pl-1">
                    Document
                  </label>
                  <div class="custom-file">
                    <input type="file" class="custom-file-input" id="chVignieta-file" ref="chVignieta"  v-on:change="onFileChange($event, 'chVignieta')"/>
                    <label class="custom-file-label" for="chVignieta-file">
                      <span v-if="formData.documents.chVignieta.file">{{formData.documents.chVignieta.file.name}}</span>
                      <span v-if="!formData.documents.chVignieta.file">Incarca fisier</span>
                    </label>
                  </div>
                  </div>
                <div class="form-group">
                  <rfr-file-previewer
                    title="Document"
                    id="chVignieta"
                    :file="formData.documents.chVignieta.fileUploaded"
                    v-if="formData.documents.chVignieta.fileUploaded"
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group flex-column flex">
                  <label class="text-purple d-block pl-1">Slovacia</label>
                </div>
                <div class="form-group">
                  <label class="text-purple pl-1">
                    Data expirarii
                  </label>
                  <datepicker :clear-button="true" :bootstrap-styling="true" input-class="form-control" v-model="formData.documents.slVignieta.expirationDate" name="slVignietaExpDate"></datepicker>
                  <small class="form-text pl-1 invalid-feedback">Acest camp este obligatoriu</small>
                </div>
                <div class="form-group custom-height">
                  <label class="text-purple pl-1">
                    Document
                  </label>
                  <div class="custom-file">
                    <input type="file" class="custom-file-input" id="slVignieta-file" ref="slVignieta"  v-on:change="onFileChange($event, 'slVignieta')"/>
                    <label class="custom-file-label" for="slVignieta-file">
                      <span v-if="formData.documents.slVignieta.file">{{formData.documents.slVignieta.file.name}}</span>
                      <span v-if="!formData.documents.slVignieta.file">Incarca fisier</span>
                    </label>
                  </div>
                </div>
                <div class="form-group">
                  <rfr-file-previewer
                    title="Document"
                    id="slVignieta"
                    :file="formData.documents.slVignieta.fileUploaded"
                    v-if="formData.documents.slVignieta.fileUploaded"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-row">
          <div class="card rounded card-custom ml-md-2 mr-md-2 shadow-lg">
            <div class="card-header text-purple">
              <strong class="pl-1 font-weight-bold">Amenzi</strong>
            </div>
            <div class="card-body">
              <div class="row">
              <div class="col-md-3 col-sm-12" v-for="ticket in tickets">
                <div class="form-group" v-if="ticket.file">
                  <rfr-file-previewer
                    title="Amenda"
                    :id="`ticket-${ticket.id}`"
                    :file="ticket.file"
                    :expirationDate="ticket.expirationDate"
                  />
                </div>
              </div>

              <div class="col-md-3 col-sm-12">
                <div class="form-group">
                  <label class="text-purple pl-1 font-weight-bold">
                    Adauga amenda noua
                  </label>
                </div>
                <div class="form-group">
                  <label class="text-purple pl-1">
                    Data amenda
                  </label>
                  <datepicker :clear-button="true" :bootstrap-styling="true" input-class="form-control" v-model="formData.documents.newTicket.expirationDate" name="newTicketExpirationDate"></datepicker>
                  <small class="form-text pl-1 invalid-feedback">Acest camp este obligatoriu</small>
                </div>
                <div class="form-group">
                  <label class="text-purple pl-1">
                    Document
                  </label>
                  <div class="custom-file">
                    <input type="file" class="custom-file-input" id="newTicket-file" ref="newTicket"  v-on:change="onFileChange($event, 'newTicket')"/>
                    <label class="custom-file-label" for="newTicket-file">
                      <span v-if="formData.documents.newTicket.file">{{formData.documents.newTicket.file.name}}</span>
                      <span v-if="!formData.documents.newTicket.file">Incarca fisier</span>
                    </label>
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div>
      </div>

      <div class="row">
        <div class="col-md-8">
          <div class="row">
            <div class="col">
              <a class="btn btn-purple btn-block shadow" @click="goToRoute('vehiclesList')">Renunta</a>
            </div>
            <div class="col">
              <button
                class="btn  btn-purple btn-block shadow"
                @click.prevent="onSubmitVehicle(formData)"
                v-if="canEditVehicle"
              >Salveaza</button>
            </div>
          </div>
        </div>
      </div>
    </form>

  </div>
</template>

<script>
  import { VueAutosuggest } from 'vue-autosuggest';
  import { VEHICLE_EDIT_SUBMIT, FETCH_VEHICLE, FETCH_DRIVERS, FETCH_VEHICLES } from '@/store/actions.type';
  import Datepicker from 'vuejs-datepicker';
  import { API_URL } from '@/config';
  import RfrFilePreviewer from "../../components/fields/RfrFilePreviewer";
  import RomfourDriverAutosuggest from "@/components/autosuggest/RomfourDriver";
  import { required } from 'vuelidate/lib/validators'

  export default {
    name: 'VehicleEditPage',
    components: {
      RomfourDriverAutosuggest,
      RfrFilePreviewer,
      Datepicker,
      VueAutosuggest,
    },
    props: {
      slug: Number|String,
    },
    data: () => ({
      formData: {
        statusAuto: null,
        drivingStatus: null,
        preferences: {
          trailerId: null,
          mainDriverId: null,
          backupDriverId: null,
          thirdDriverId: null,
        },
        documents: {
          tahograf: {
            fileUploaded: null,
            file: null,
            expirationDate: null
          },
          newTicket: {
            file: null,
            expirationDate: null
          },
          cascoInsurance: {
            fileUploaded: null,
            file: null,
            expirationDate: null,
          },
          rcaInsurance: {
            fileUploaded: null,
            file: null,
            expirationDate: null,
          },
          cmrInsurance: {
            fileUploaded: null,
            file: null,
            expirationDate: null,
          },
          accInsurance: {
            fileUploaded: null,
            file: null,
            expirationDate: null,
          },
          goodsTrCert: {
            fileUploaded: null,
            file: null,
            expirationDate: null
          },
          peopleTrCert: {
            fileUploaded: null,
            file: null,
            expirationDate: null,
          },
          roVignieta: {
            fileUploaded: null,
            file: null,
            expirationDate: null,
          },
          huVignieta: {
            fileUploaded: null,
            file: null,
            expirationDate: null,
          },
          atVignieta: {
            fileUploaded: null,
            file: null,
            expirationDate: null,
          },
          slVignieta: {
            fileUploaded: null,
            file: null,
            expirationDate: null,
          },
          chVignieta: {
            fileUploaded: null,
            file: null,
            expirationDate: null,
          },
          itp: {
            fileUploaded: null,
            file: null,
            expirationDate: null,
          },
          clasificare: {
            fileUploaded: null,
            file: null,
            expirationDate: null,
          },
          cozut: {
            fileUploaded: null,
            file: null,
            expirationDate: null,
          },
          cnadr: {
            fileUploaded: null,
            file: null,
            expirationDate: null,
          },
          leasing: {
            fileUploaded: null,
            file: null,
            expirationDate: null,
          },
          ci: {
            fileUploaded: null,
            file: null,
          },
        },
      },
      licencePlate: null,
      tickets: [],
      timeout: null,
      debounceMilliseconds: 500,
      selected: null,
      mainDriver:null,
      backupDriver:null,
      thirdDriver:null,
      drivers: [],
      trailer:null,
      trailers: [],
      sugestionsProps: {
        name: "autosuggest__input__drivers",
        class: 'form-control autosuggest__input',
        placeholder: '....'
      },
      submitStatus: null,
    }),
    validations: {
      formData: {
        statusAuto: { required },
      }
    },
    computed: {
      canEditVehicle() {
        return this.$store.getters.permissions.createEditVehicle;
      },
    },
    mounted() {
      this.initVehicle();
    },
    methods: {
      goToRoute(route) {
        this.$router.push({ name: route});
      },
      initVehicle() {
        this.$store.dispatch(FETCH_VEHICLE, this.slug).then((response) => {
          response = response.data;
          this.licencePlate = response.numarInmatriculare;

          const preferences = {
            trailerId: response.trailerId || null,
            mainDriverId: response.mainDriverId || null,
            backupDriverId: response.backupDriverId || null,
            thirdDriverId: response.thirdDriverId || null,
          };

          const documentTypes = Object.keys(this.formData.documents);
          let documents = {};
          for (let key in documentTypes) {
            let documentType = documentTypes[key];
            if (documentType === 'newTicket') {
              documents[documentType] = {
                file: null,
                expirationDate: null
              };

              continue;
            }
            documents[documentType] = {
              fileUploaded: response.documents[documentType] && response.documents[documentType].file ? response.documents[documentType].file : null,
              expirationDate: response.documents[documentType] ? response.documents[documentType].expirationDate : null,
              file: null,
            }
          }
          this.formData = {
            statusAuto: response.statusAuto,
            drivingStatus: response.drivingStatus,
            preferences,
            documents
          };

          this.tickets = response.tickets || [];

          this.backupDriver = response.backupDriverId ? `${response.backupDriverLastName} ${response.backupDriverFirstName}` : '';
          this.thirdDriver = response.thirdDriverId ? `${response.thirdDriverLastName} ${response.thirdDriverFirstName}` : '';
          this.mainDriver  = response.mainDriverId ? `${response.mainDriverLastName} ${response.mainDriverFirstName}` : '';
          this.trailer = response.trailerNumarInmatriculare;

          this.$log.info('FETCH_VEHICLE', response);
        }).catch((error) => {
          this.$log.error('FETCH_VEHICLE', error);
        });
      },

      onFileChange(e, fileType) {
        var files = e.target.files || e.dataTransfer.files;
        if (!files.length)
          return;
        this.formData.documents[fileType].file = files[0];
        this.formData.documents[fileType].fileUploaded = null;
      },
      onSubmitVehicle(e) {
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.submitStatus = 'ERROR';
          return;
        }
        this.submitStatus = 'PENDING';

        let formData = new FormData();
        formData.append('data', JSON.stringify(this.formData));

        const documentTypes = Object.keys(this.formData.documents);

        for (let key in documentTypes) {
          let documentType = documentTypes[key];
          formData.append(documentType, this.formData.documents[documentType].file);
        }

        this.$store.dispatch(VEHICLE_EDIT_SUBMIT, {vehicleId: this.slug, formData}).then((response) => {
          this.submitStatus = null;
          this.$log.info('VEHICLE_EDIT_SUBMIT', response);
          this.$router.push({name: 'vehiclesList'});
        }).catch((error) => {
          this.submitStatus = null;
          this.$log.error('VEHICLE_EDIT_SUBMIT', error);
        });
      },

      selectMainDriverHandler(item) {
        this.formData.preferences.mainDriverId = item ? item.codAng : null;
      },
      selectBackupDriverHandler(item) {
        this.formData.preferences.backupDriverId = item ? item.codAng : null;
      },
      selectThirdDriverHandler(item) {

        this.formData.preferences.thirdDriverId = item ? item.codAng : null;
      },
      selectTrailerHandler(suggestion) {
        this.formData.preferences.trailerId = suggestion.item ? suggestion.item.codAutovehicul : null;
      },

      fetchDrivers(text) {
        clearTimeout(this.timeout);

        if (!text) return;

        this.timeout = setTimeout(() => {
          this.$store.dispatch(FETCH_DRIVERS, {filters: {name: text}}).then((response) => {
            this.drivers = [{ data:  response.data}];
            this.$log.info('FETCH_DRIVERS', response);
          }).catch((error) => {
            this.$log.error('FETCH_DRIVERS', error);
          });
        }, this.debounceMilliseconds)
      },
      fetchTrailers(text) {
        clearTimeout(this.timeout);

        if (!text) {
          this.formData.preferences.trailerId = null;
          return;
        }

        const params = {filters: {
          licensePlate: text,
          vehicleType: 7,
        }};

        this.$store.dispatch(FETCH_VEHICLES, params).then((response) => {
          this.trailers = [{data: response.data}];
          this.$log.info('FETCH_VEHICLES', response);
        }).catch((error) => {
          this.$log.error('FETCH_VEHICLES', error);
        });
      },

      renderSuggestion(suggestion) {
        if (suggestion.item.codAng) {
          return `${suggestion.item.nume} ${suggestion.item.prenume} ` ;
        }
        if (suggestion.item.codAutovehicul) {
          return suggestion.item.numarInmatriculare;
        }
        return null;
      },
      getSuggestionValue(suggestion) {
        if (suggestion.item.codAng) {
          return `${suggestion.item.nume} ${suggestion.item.prenume} ` ;
        }
        if (suggestion.item.codAutovehicul) {
          return suggestion.item.numarInmatriculare;
        }
        return null;
        },
      getImagePreview(fileUploaded) {
        return `${API_URL}/files/${fileUploaded}/preview`;
      },
    },

    watch: {
      slug: {
        handler(val) {
          this.initPageData(val);
        },
      },
    },
  };
</script>


<style>
  .autosuggest__input {
    outline: none;
    position: relative;
    display: block;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
  }

  .autosuggest__input.autosuggest__input-open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .autosuggest__results-container {
    position: relative;
    width: 100%;
  }

  .autosuggest__results {
    font-weight: 300;
    margin: 0;
    position: absolute;
    z-index: 10000001;
    width: 100%;
    border: 1px solid #e0e0e0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background: white;
    padding: 0px;
    max-height: 400px;
    overflow-y: scroll;
  }

  .autosuggest__results ul {
    list-style: none;
    padding-left: 0;
    margin: 0;
  }

  .autosuggest__results .autosuggest__results-item {
    cursor: pointer;
    padding: 15px;
  }

  #autosuggest ul:nth-child(1) > .autosuggest__results_title {
    border-top: none;
  }

  .autosuggest__results .autosuggest__results-before {
    color: gray;
    font-size: 11px;
    margin-left: 0;
    padding: 15px 13px 5px;
    border-top: 1px solid lightgray;
  }

  .autosuggest__results .autosuggest__results-item:active,
  .autosuggest__results .autosuggest__results-item:hover,
  .autosuggest__results .autosuggest__results-item:focus,
  .autosuggest__results,
  .autosuggest__results-item.autosuggest__results-item--highlighted {
    background-color: #f6f6f6;
  }
</style>
