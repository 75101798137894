<template>
  <vue-autosuggest
    ref="autocomplete"
    v-model="value"
    :suggestions="suggestions"
    :inputProps="sugestionsProps"
    :renderSuggestion="renderSuggestion"
    :getSuggestionValue="getSuggestionValue"
    :countryName="countryName"
    :reservations="reservations"
    :cruiseId="cruiseId"
    :vehicleId="vehicleId"
    :postalCode="postalCode"
    :type="type"
    @input="fetchSuggestions"
    @selected="onSelectSuggestion"
    :required=true
  />
</template>

<script>
import {VueAutosuggest} from 'vue-autosuggest';
import {FETCH_VEHICLES, FETCH_VEHICLES_FOR_EXTERNAL} from '@/store/actions.type';

export default {
  name: 'romfour-vehicle-cruise-autosuggest',
  components: {
    VueAutosuggest,
  },
  props: {
    countryName: Number | String,
    country: Number| String,
    reservations: Array | String,
    value: Number | String,
    postalCode: Number | String,
    cruiseId: Number,
    type: String,
    vehicleId: Number,
    sugestionsProps: {
      type: Object,
      default() {
        return {
          name: "autosuggest__input__vehicle",
          class: 'form-control autosuggest__input',
          placeholder: (this.type == "trailer" ? 'Cauta Remorca...' : 'Cauta Autovehicul..'),
          required: true,

        };
      },
    },
  },
  data: () => ({
    timeout: null,
    debounceMilliseconds: 500,
    selected: null,
    suggestions: [{data: []}]
  }),
  computed: {
    get() {
      return this.value;
    },
    set(newValue) {
      this.$emit('input', newValue);
    }
  },
  mounted() {
  },
  methods: {
    onSelectSuggestion(suggestion) {
      suggestion.item.country = this.countryName;
      suggestion.item.reservations = this.reservations;
      suggestion.item.cruiseId = this.cruiseId;
      suggestion.item.vhType = this.type;
      suggestion.item.vehicleId = this.vehicleId;
      suggestion.item.postalCode = this.postalCode;
      this.$emit('onSelectSuggestion', suggestion.item);
    },

    fetchSuggestions(text) {
      clearTimeout(this.timeout);

      if (!text) {
        this.$emit('onSelectSuggestion', null);
        return;
      }
      const params = {
        filters: {
          licensePlate: text,
          vehicleType: (this.type == "trailer" ? 7 : ''),
          vehicleStatus: this.type ? this.type : null,
          postalCode: this.postalCode ? this.postalCode : '',
        }
      };

      this.timeout = setTimeout(() => {
        if(params.filters['vehicleStatus'] == 'trailer') {
          delete params.filters['vehicleStatus'];
        }
        console.log(params.filters);
        this.$store.dispatch(FETCH_VEHICLES_FOR_EXTERNAL, params).then((response) => {
          this.suggestions = [{data: response.data}];
          this.$log.info('FETCH_VEHICLES', response);
        }).catch((error) => {
          this.$log.error('FETCH_VEHICLES', error);
        });
      }, this.debounceMilliseconds);
    },

    renderSuggestion(suggestion) {
      return suggestion.item.numarInmatriculare;
    },

    getSuggestionValue(suggestion) {
      return suggestion.item.numarInmatriculare;
    },
  },
};
</script>


<style>
.autosuggest__input {
  outline: none;
  position: relative;
  display: block;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.autosuggest__input.autosuggest__input-open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

.autosuggest__results {
  font-weight: 300;
  margin: 0;
  position: absolute;
  z-index: 10000001;
  width: 100%;
  border: 1px solid #e0e0e0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background: white;
  padding: 0px;
  max-height: 400px;
  overflow-y: scroll;
}

.autosuggest__results ul {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.autosuggest__results .autosuggest__results-item {
  cursor: pointer;
  padding: 15px;
}

#autosuggest ul:nth-child(1) > .autosuggest__results_title {
  border-top: none;
}

.autosuggest__results .autosuggest__results-before {
  color: gray;
  font-size: 11px;
  margin-left: 0;
  padding: 15px 13px 5px;
  border-top: 1px solid lightgray;
}

.autosuggest__results .autosuggest__results-item:active,
.autosuggest__results .autosuggest__results-item:hover,
.autosuggest__results .autosuggest__results-item:focus,
.autosuggest__results,
.autosuggest__results-item.autosuggest__results-item--highlighted {
  background-color: #f6f6f6;
}
</style>
