<template>
  <div>
    <!--HEADER-->

      <div class="row">
        <div class="col mb-3">
          <span class="text-purple text-md pl-3 pr-1">Gestiune soferi</span>
          <a class="btn btn-md btn-raised btn-wave btn-icon mb-2 btn-rounded text-white"
             style="background-color:#603084"
             @click="goToRoute('driverNew')"
             v-if="canCreateDriver"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
          </a>
        </div>
      </div>


    <md-card class="card" style="z-index: 2">
      <md-card-content class="card-body rounded shadow-lg">
      <div class="card-header text-purple">
                Cautare
              </div>
      <div>
                <div class="row">
                  <div class="col">
                    <div class="form-group">
                      <label class="text-purple pl-1">
                        Status Sofer
                      </label>
                      <select class="form-control mb-3" id="sofer_status" label="Status Sofer" v-model="filters.driverStatus">
                        <option value=""></option>
<!--                        <option value="inactiv">Inactiv</option>-->
                        <option value="holiday">In Concediu</option>
                        <option value="at-home">Acasa</option>
                        <option value="driving">In cursa</option>
                        <option value="contract-suspendat">Contract Suspendat</option>
                      </select>
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group">
                      <label class="text-purple pl-1">
                        Stare Sofer C.M.(Revisal)
                      </label>
                      <select class="form-control mb-3" id="dataPlecare" label="Sofer stare contract munca(Revisal)" v-model="filters.employmentStatus">
                        <option value="hired" >Soferi activi(angajati) </option>
                        <option value="0">Soferi inactivi(C.M. incheiat)</option>
                      </select>
                    </div>
                  </div>

                  <div class="col-md">
                    <div class="form-group">
                      <label class="text-purple pl-1">
                        Nume Sofer
                      </label>
                      <input id="nume" type="text" class="form-control" placeholder="Nume" v-model="filters.name">
                    </div>
                  </div>

                  <div class="col d-none d-sm-inline-block">
                    <div class="form-group">
                      <label class="text-purple pl-1">
                        Data expirarii CI >
                      </label>
                      <datepicker :clear-button="true" :bootstrap-styling="true" input-class="form-control" v-model="filters.ciExpirationDate" name="ciExpirationDate"></datepicker>
                    </div>
                  </div>

                  <div class="col d-none d-sm-inline-block">
                    <div class="form-group">
                      <label class="text-purple pl-1">
                        Data expirarii Permis >
                      </label>
                      <datepicker :clear-button="true" :bootstrap-styling="true" input-class="form-control" v-model="filters.dlExpirationDate" name="dlExpirationDate"></datepicker>
                    </div>
                  </div>
                </div>
                <div class="row d-none d-sm-flex">

                  <div class="col">
                    <div class="form-group">
                      <label class="text-purple pl-1">
                        Data expirarii Aviz Medical >
                      </label>
                      <datepicker :clear-button="true" :bootstrap-styling="true" input-class="form-control" v-model="filters.medExpirationDate" name="medExpirationDate"
                      ></datepicker>
                    </div>
                  </div>

                  <div class="col">
                    <div class="form-group">
                      <label class="text-purple pl-1">
                        Data expirarii Aviz Psihologic >
                      </label>
                      <datepicker :clear-button="true" :bootstrap-styling="true" input-class="form-control" v-model="filters.psiExpirationDate" name="psiExpirationDate"
                      ></datepicker>
                    </div>
                  </div>

                  <div class="col">
                    <div class="form-group">
                      <label class="text-purple pl-1">
                        Data expirarii Card Tahograf >
                      </label>
                      <datepicker :clear-button="true" :bootstrap-styling="true" input-class="form-control" v-model="filters.cardTahExpirationDate" name="cardTahExpirationDate"
                      ></datepicker>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-2">
                    <button class="btn btn-purple btn-block shadow" @click="onSearch()">Cauta</button>
                  </div>
                </div>

              </div>
      </md-card-content>
    </md-card>

    <md-card class="card">
      <md-card-content class="card-body rounded shadow-lg">
        <!--TABLE-->
        <div class="table-responsive-sm">
          <table class="table table-responsive-lg table-hover" style="table-layout: fixed; width: 100%;">
            <!--<table class="table table-bordered table-responsive-lg table-striped text-nowrap">-->
            <thead class="bg--white">
            <tr>
              <th class="text-purple align-top text-center d-md-table-cell" style="word-wrap: normal" @click="onSort('codAng')">
                <div class="sortable" :class="[currentSort.column === 'codAng' ? this.currentSort.dir : '']">
                  ID
                </div>
              </th>
              <th class="text-purple align-top text-center d-none d-md-table-cell" style="word-wrap: normal">
                Nr. contract
              </th>
              <th class="text-purple align-top text-center d-md-table-cell" style="word-wrap: normal">
                Nume
              </th>
              <th class="text-purple align-top text-center d-none d-md-table-cell" style="word-wrap: normal">
                Numar Personal
              </th>
              <th class="text-purple align-top text-center d-none d-md-table-cell" style="word-wrap: normal">
                Tip permis
              </th>
              <th class="text-purple align-top text-center d-none d-md-table-cell" style="word-wrap: normal">
                Perioada de proba
              </th>
              <th class="text-purple align-top text-center d-md-table-cell" style="word-wrap: normal">
                Status
              </th>
              <th class="text-purple align-top text-center d-none d-md-table-cell" style="word-wrap: normal" @click="onSort('lastArrivalTime')">
                <div class="sortable" :class="[currentSort.column === 'lastArrivalTime' ? this.currentSort.dir : '']">
                  Zile acasa
                </div>
              </th>
              <th class="text-purple align-top text-center d-md-table-cell"
                style="word-wrap: normal"
              >Actiuni</th>
            </tr>
            </thead>
            <tbody class="text-center" id="tbody">
            <tr v-for="driver in drivers" :class="driverStatusClass(driver)">
              <td class="align-top text-center d-md-table-cell" style="word-wrap: normal">
                {{ driver.codAng}}
              </td>
              <td class="align-top text-center d-none d-md-table-cell" style="word-wrap: normal">
                {{ driver.numarContract}}
              </td>
              <td class="align-top text-center d-md-table-cell" style="word-wrap: normal">
                {{ driver.prenume}}  {{ driver.nume}}
              </td>
              <td class="align-top text-center d-none d-md-table-cell" style="word-wrap: normal">
                {{ driver.mobil}}
              </td>
              <td class="align-top text-center d-none d-md-table-cell" style="word-wrap: normal">
                {{ driver.drivingLicenceType}}
              </td>
              <td class="align-top text-center d-none d-md-table-cell" style="word-wrap: normal">
                {{ driverProbationEnded(driver)}}
              </td>
              <td class="align-top text-center d-md-table-cell" style="word-wrap: normal">
                {{ showDriverStatus(driver)}}
              </td>
              <td class="align-top text-center d-none d-md-table-cell" style="word-wrap: normal">
                {{ driver.daysAtHome}}
              </td>
              <td  class="align-top text-center d-md-table-cell" style="word-wrap: normal">
                <a class="btn text-purple font-weight-bold" @click="onEdit(driver)">Editeaza</a>
                <a class="btn text-purple font-weight-bold" @click="onManageLeaveRequests(driver)">Concediu</a>
                <a class="btn text-purple font-weight-bold" @click="onSeeDocs(driver)">Documente</a>
              </td>
            </tr>

            </tbody>
          </table>
        </div>

        <!--PAGINATION-->
        <div class="md-layout">
          <div class="md-layout-item md-size-50">
            {{ driversTotal }} soferi (de la {{ driversFrom }} pana la {{ driversTo }})
          </div>
          <div class="md-layout-item md-size-50">
            <rom-paginator :current-page="driversCurrentPage"
                           :last-page="driversLastPage"
                           :itemsPerPage="driversPerPage"
                           :threshold="2"
                           :has-prev-next="true"
                           :has-first-last="true"
                           @onFirstPage="onFirstPage"
                           @onPreviousPage="onPreviousPage"
                           @onNextPage="onNextPage"
                           @onLastPage="onLastPage"
                           @onChangePage="onChangePage"
                           @onChangePageSize="onChangePageSize"
            >
            </rom-paginator>
          </div>
        </div>
      </md-card-content>
    </md-card>

    <div class="row pl-3 pt-3 pb-5">
      <div class="col-4">
        <button class="btn btn-raised btn-wave mb-2 w-xs text-white w-100" style="background-color:#603084"  @click="dowloadReport('expiredDriverDocuments')">
          <span class="mx-1 font-weight-bold" v-if="generatingExport != 'expiredDriverDocuments'">Raport documente</span>
          <div v-if="generatingExport && generatingExport === 'expiredDriverDocuments'">
            <i class="fa fa-spinner fa-spin fa-3x fa-fw" ></i>
          </div>
        </button>
      </div>
      <div class="col-4">
        <button class="btn btn-raised btn-wave mb-2 w-xs text-white w-100" style="background-color:#603084"  @click="dowloadReport('driversStatus')">
          <span class="mx-1 font-weight-bold" v-if="generatingExport != 'driversStatus'"> Raport lista soferi</span>
          <div v-if="generatingExport && generatingExport === 'driversStatus'">
            <i class="fa fa-spinner fa-spin fa-3x fa-fw" ></i>
          </div>
        </button>
      </div>
<!--      <div class="col-4">-->
<!--        <button class="btn btn-raised btn-wave mb-2 w-xs text-white w-100" style="background-color:#603084" @click="dowloadReport('driversStatus')">-->
<!--          <span class="mx-1 font-weight-bold"> Raport concedii soferi</span> </button>-->
<!--      </div>-->
    </div>
  </div>

</template>

<script>
  import ListingPage from '@/components/page-templates/ListingPage.vue';
  import RomPaginator from '@/components/pagination/RomPaginator.vue';
  import {
    FETCH_DRIVERS,
    FETCH_REPORT,
    UPDATE_ACTIVE_SORT_FIELD_DRIVERS,
    UPDATE_SEARCH_DRIVERS,
    CLEAR_SEARCH_DRIVERS,
  } from '@/store/actions.type';
  import moment from 'moment';
  import Datepicker from 'vuejs-datepicker';

  export default {
    name: 'Drivers',
    components: {
      ListingPage,
      RomPaginator,
      Datepicker,
    },
    data: () => ({
      filters: {
        driverStatus: null,
        name: null,
        employmentStatus: 'hired',
        ciExpirationDate: null,
        dlExpirationDate: null,
        psiExpirationDate: null,
        cardTahExpirationDate: null,
        medExpirationDate: null,
      },
      generatingExport: null,
      currentSort: {column: 'codAng', dir: 'desc'},
    }),
    computed: {
      canCreateDriver() {
        return this.$store.getters.permissions.createEditDriver;
      },
      drivers() {
        return this.$store.getters.driversItems;
      },
      driversPerPage() {
        return this.$store.getters.driversPerPage;
      },
      driversCurrentPage() {
        return this.$store.getters.driversCurrentPage;
      },
      driversLastPage() {
        return this.$store.getters.driversLastPage;
      },
      driversFrom() {
        return this.$store.getters.driversFrom;
      },
      driversTo() {
        return this.$store.getters.driversTo;
      },
      driversTotal() {
        return this.$store.getters.driversTotal;
      },
      driversSortFields() {
        return this.$store.getters.driversSortFields;
      },
      driversActiveSort() {
        return this.$store.getters.driversActiveSort;
      },
      driversSearch() {
        return this.$store.getters.driversSearch;
      },
    },
    mounted() {
      this.initDrivers();
    },
    methods: {
      goToRoute(route) {
        this.$router.push({ name: route});
      },
      onEdit(item) {
        this.$router.push({ name: 'driverEdit', params: { slug: item.codAng } });
      },
      onManageLeaveRequests(driver) {
        this.$router.push({ name: 'employeeLeaveRequests', params: { slug: driver.codAng } });
      },
      onSeeDocs(driver) {
        this.$router.push({ name: 'driverDocument', params: { slug: driver.codAng}});
      },
      initDrivers() {
        const params = {
          page: this.driversCurrentPage,
          perPage: this.driversPerPage,
          filters: this.filters,
          sort: this.currentSort,
        };
        this.fetchDrivers(params);
      },
      fetchDrivers(params) {
        this.filters.withUserStatus = 1;
        this.filters.employmentStatus = 'hired';
        this.$store.dispatch(FETCH_DRIVERS, params).then((response) => {
          this.$log.info('FETCH_DRIVERS', response);
        }).catch((error) => {
          this.$log.error('FETCH_DRIVERS', error);
        });
      },
      onChangePageSize(value) {
        const params = {
          page: 1,
          perPage: value,
          filters: this.filters,
          sort: this.currentSort,
        };
        this.fetchDrivers(params);
      },
      onFirstPage() {
        const params = {
          page: 1,
          perPage: this.driversPerPage,
          filters: this.filters,
          sort: this.currentSort,
        };
        this.fetchDrivers(params);
      },
      onPreviousPage() {
        const params = {
          page: this.driversCurrentPage - 1,
          perPage: this.driversPerPage,
          filters: this.filters,
          sort: this.currentSort,
        };
        this.fetchDrivers(params);
      },
      onChangePage(data) {
        const params = {
          page: data,
          perPage: this.driversPerPage,
          filters: this.filters,
          sort: this.currentSort,
        };
        this.fetchDrivers(params);
      },
      onNextPage() {
        const params = {
          page: this.driversCurrentPage + 1,
          perPage: this.driversPerPage,
          filters: this.filters,
          sort: this.currentSort,
        };
        this.fetchDrivers(params);
      },
      onLastPage() {
        const params = {
          page: this.driversLastPage,
          perPage: this.driversPerPage,
          filters: this.filters,
          sort: this.currentSort,
        };
        this.fetchDrivers(params);
      },
      onSearch() {
        let query = {
          page: 1,
          perPage: this.perPage,
          filters: this.filters,
          sort: this.currentSort,
        };

        this.fetchDrivers(query);
      },
      onSort:function(s) {
        if(s === this.currentSort.column) {
          this.currentSort.dir = this.currentSort.dir === 'asc' ? 'desc' : 'asc';
        } else if (!this.currentSort.dir) {
          this.currentSort.dir = 'asc';
        }

        this.currentSort.column = s;
        this.onSearch();
      },
      toDate(datetime) {
        return datetime ? moment(datetime).format('DD/MM/YYYY') : '';

      },
      toDatetime(datetime) {
        return datetime ? moment(datetime).format('DD/MM/YYYY, h:mm:ss') : '';
      },

      documentAvailabilityClass(datetime) {
        if (!datetime) return '';

        if (moment(datetime).isBefore(moment().add(1, 'week'))) return 'document-expires';

        if (moment(datetime).isBefore(moment().add(1, 'month'))) return 'document-warning';

        return 'document-ok';
      },

      driverStatusClass(driver) {
        if (driver.driverStatus === 'inactiv') return 'inactiv';

        var driverLeaveArr = String(driver.leave).split('/'); //Create an array if we have multiple leaves

        //Change the color based on what status have the driver
        if (!driver.lastArrivalTime || driver.daysAtHome >= 4) {
          if(driverLeaveArr.includes('concediu-odihnal') || driverLeaveArr.includes('concediu-medical') || driverLeaveArr.includes('contract-suspendat')) {
            return 'status-suspendare';
          } else {
            return 'status-error';
          }
        }

        if (driver.daysAtHome >= 2) {
          return 'status-warning'
        }

        return 'status-ok';
      },

      showDriverStatus(driver) {
        if (driver.driverStatus === 'inactiv') return 'Inactiv';
        var leaves = driver.leave;
        if (driver.lastArrivalTime && driver.daysAtHome < 0) {
          return 'In cursa';
        } else if(driver.leave) {
          return leaves;
        }

        return 'Acasa';
      },

      addSortColumnClass(column) {
        if (column != this.currentSort.column)  return '';

        if (column === 'lastArrivalTime') {
          return this.currentSort.dir === 'asc' ? 'desc' : 'asc';
        }
        return this.currentSort.dir;
      },

      driverProbationEnded(driver) {
        if (!driver.dataAngajare) return '-';

        return moment().diff(moment(driver.dataAngajare), 'days') > 90 ? 'Nu' : 'Da';
      },
      dowloadReport(reportName) {
        this.generatingExport = reportName;

        this.$store.dispatch(FETCH_REPORT, {reportName}).then((response) => {
          this.generatingExport = null;
          this.$log.info('FETCH_REPORT', response);
        })
          .catch((error) => {
          this.generatingExport = null;
          this.$log.error('FETCH_REPORT', error);
        });
      },

      create() {
        this.$toasted.show('adauga', {
          type: 'info',
          position: 'top-right',
          duration: 5000,
          icon: 'check',
        });
      },
    },
  };
</script>
