<template>
<div>
  <form id="routesForm" novalidate class="needs-validation"  @submit="onSubmitNewLeaveRequest" @submit.prevent="onSubmit">
    <div class="row pt-5 pb-3">
      <div class="col">
        <h1 class="text-purple pl-1">Concediu Sofer</h1>
        <h3  class="text-purple pl-1">{{this.getEmployeeName()}}</h3>
      </div>
    </div>

    <div class="card rounded shadow-lg">
      <div class="card-header">
        <h4 class="text-purple">Inregistrare Concediu</h4>
      </div>
      <div>
        <div class="card-body">
          <div class="row">
            <div class="col">
              <div class="form-group" :class="{ 'form-group--error': $v.formData.leaveType.$error }">
                <label class="text-purple pl-1 form__label">Tip concediu</label>
                <select id="tipPermis"
                        class="form-control select2-hidden-accessible select2-dropdown d-block form__input"
                        tabindex="-1"
                        aria-hidden="true"
                        required
                        v-model="formData.leaveType"
                >
                  <option hidden disabled selected value>Alege concediu</option>
                  <option value="concediu-medical">Concediu Medical</option>
                  <option value="concediu-odihnal">Concediu Odihnal</option>
                  <option value="contract-suspendat">Suspendare contract</option>
                </select>
                <small class="form-text pl-1 error" v-if="submitStatus && !$v.formData.leaveType.required">Acest camp este obligatoriu</small>
              </div>
              <div class="form-group" :class="{ 'form-group--error': $v.formData.startAt.$error }">
                <label class="text-purple pl-1">Din data</label>
                <datepicker :clear-button="true" :bootstrap-styling="true" input-class="form-control" v-model="formData.startAt" name="startAt"
                            :disabled-dates="disabledStartDates()"
                            :highlighted="highlighted"
                ></datepicker>
                <small class="form-text pl-1 error" v-if="submitStatus && !$v.formData.startAt.required">Acest camp este obligatoriu</small>
              </div>
              <div class="form-group" :class="{ 'form-group--error': $v.formData.endAt.$error }">
                <label class="text-purple pl-1">Pana in data</label>
                <datepicker :clear-button="true" :bootstrap-styling="true" input-class="form-control" v-model="formData.endAt" name="endAt"
                            :disabled-dates="disabledEndDates()"
                            :highlighted="highlighted"
                ></datepicker>
                <small class="form-text pl-1 error" v-if="submitStatus && !$v.formData.endAt.required">Acest camp este obligatoriu</small>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <button class="btn  btn-purple btn-block shadow"
                      :disabled="submitStatus === 'PENDING'"
                      @click.prevent="onSubmitNewLeaveRequest(formData)">Salveaza</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div>
    <div class="card-header">
      <h4 class="text-purple">Concedii Inregistrate</h4>
    </div>
    <listing-page :items="leaveRequests"
                  :columns="columns"
                  :actions="{
                        edit: true,
                        delete: true,
                      }"
                  @onEdit="onEditLeaveRequest"
                  @onDelete="onDeleteLeaveRequest"
    >
    </listing-page>
  </div>
  <div>
    <b-modal
      :id="`modal-edit-leave-requests`"
      title="Concediu"
      ref="modal-edit-leave-requests"
      v-model="isEditFormVisible"
      @hidden="resetEditModal"
      :hide-footer="true"
    >

      <div class="card-body">
        <div class="row">
          <div class="col">
            <div class="form-group" :class="{ 'form-group--error': $v.editFormData.leaveType.$error }">
              <label class="text-purple pl-1 form__label">Tip concediu</label>
              <select id="leave-edit-type"
                      class="form-control select2-hidden-accessible select2-dropdown d-block form__input"
                      tabindex="-1"
                      aria-hidden="true"
                      required
                      v-model="editFormData.leaveType"
              >
                <option hidden disabled selected value>Alege concediu</option>
                <option value="concediu-medical">Concediu Medical</option>
                <option value="concediu-odihnal">Concediu Odihnal</option>
                <option value="contract-suspendat">Suspendare contract</option>
              </select>
              <small class="form-text pl-1 error" v-if="editSubmitStatus && !$v.editFormData.leaveType.required">Acest camp este obligatoriu</small>
            </div>
            <div class="form-group" :class="{ 'form-group--error': $v.editFormData.startAt.$error }">
              <label class="text-purple pl-1">Din data</label>
              <datepicker :clear-button="true" :bootstrap-styling="true" input-class="form-control" v-model="editFormData.startAt" name="startAt"
              >
                <!--                          :disabled-dates="disabledStartDates()"-->

              </datepicker>
              <small class="form-text pl-1 error" v-if="editSubmitStatus && !$v.editFormData.startAt.required">Acest camp este obligatoriu</small>
            </div>
            <div class="form-group" :class="{ 'form-group--error': $v.editFormData.endAt.$error }">
              <label class="text-purple pl-1">Pana in data</label>
              <datepicker :clear-button="true" :bootstrap-styling="true" input-class="form-control" v-model="editFormData.endAt" name="endAt"
                          :disabled-dates="disabledEndDates()"
              ></datepicker>
              <small class="form-text pl-1 error" v-if="editSubmitStatus && !$v.editFormData.endAt.required">Acest camp este obligatoriu</small>
              <small class="form-text pl-1 error" v-if="editSubmitStatus && !$v.editFormData.endAt.isGtStartAt">Interval de timp incorect</small>

            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <button class="btn  btn-purple btn-block shadow"
                    :disabled="editSubmitStatus === 'PENDING'"
                    @click.prevent="onSubmitEditLeaveRequest()">Salveaza</button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</div>
</template>

<script>
import {
  EMPLOYEE_LEAVE_REQUESTS_CREATE_SUMBIT,
  EMPLOYEE_LEAVE_REQUESTS_EDIT_SUBMIT,
  EMPLOYEE_LEAVE_REQUESTS_DELETE,
  FETCH_EMPLOYEE_LEAVE_REQUESTS,
  FETCH_EMPLOYEE, FETCH_DRIVER,
} from '@/store/actions.type';
  import Datepicker from 'vuejs-datepicker';
  import { required } from 'vuelidate/lib/validators'
  import moment from 'moment';
  import ListingPage from "../../components/page-templates/ListingPage.vue";
  import { BModal, VBModal } from "bootstrap-vue";

  export default {
  name: 'DriverLeaveRequests',
  components: {
    ListingPage,
    Datepicker,
    BModal,
  },
  directives: {
    'b-modal': VBModal
  },
  props: {
    slug: Number,
    lastarrival: String,
    homedays: String,
  },
  data: () => ({
    highlighted: {
      daysOfMonth: [
        new Date().getDate(),
      ], // Highlight 15th, 20th and 31st of each month
    },
    columns: [
      {
        name: 'id',
        label: 'ID Cerere',
      },
      {
        name: 'leaveType',
        label: 'Tip Concediu',
        mapper: (value) => {
          switch (value) {
            case 'concediu-odihnal':
              return 'Concediu Odihna';
            case 'concediu-medical':
              return 'Concediu Medical';
            case 'contract-suspendat':
              return 'Suspendare Contract';
            default:
              return '';
          }
        }
      },
      {
        name: 'startAt',
        label: 'De la',
        type: 'date',
      },
      {
        name: 'endAt',
        label: 'Pana la',
        type: 'date',
      },
      {
        name: 'createdBy.username',
        label: 'Inregistrat de',
      },
      {
        name: 'createdAt',
        label: 'Data inregistrarii',
        type: 'datetime',
      },
    ],
    formData: {
      leaveType: null,
      startAt: null,
      endAt: null,
    },
    editFormData: {
      id: null,
      leaveType: null,
      startAt: null,
      endAt: null,
    },
    leaveData: {
      lastArrivalTime: null,
      lastDepartureTime: null,
    },
    submitStatus: null,
    editSubmitStatus: null,
    isEditFormVisible: false,
  }),
  validations: {
    formData: {
      leaveType: {
        required
      },
      startAt: {
        required
      },
      endAt: {
        required
      },
    },
    editFormData: {
      leaveType: {
        required
      },
      startAt: {
        required
      },
      endAt: {
        required,
        isGtStartAt(value) {
          // standalone validator ideally should not assume a field is required
          if (value === null) return true;

          return moment(value).isSameOrAfter(moment(this.editFormData.startAt));
        }
      },
    }
  },
  computed: {
    employee() {
      return this.$store.getters.employee;
    },
    leaveRequests() {
      return this.$store.getters.employeeLeaveRequestsItems
    },
    canAddOlderLeaveRequests() {
      return this.$store.getters.permissions.createEditOlderLeaveRequests;
    },
  },
  mounted() {
    this.initEmployee(this.slug);
    this.initLeaveRequests(this.slug);
    this.driver(this.slug);
  },
  methods: {
    getEmployeeName() {
      if (this.employee && this.employee[0] && this.employee[0].nume) {
        return `${this.employee[0].nume} ${this.employee[0].prenume}`;
      }
      return '';
    },
    disabledStartDates() {
      const lastArrivalTime = new Date();
      lastArrivalTime.setDate(new Date(this.leaveData.lastArrivalTime).getDate());
      const lastDepartureTime = new Date();
      lastDepartureTime.setDate(new Date(this.leaveData.lastDepartureTime).getDate() - 1);
      return {ranges: [{ // Disable dates in given ranges (exclusive).
          from: lastDepartureTime,
          to: lastArrivalTime
        }]}
      if (this.canAddOlderLeaveRequests) {
        return { to: null }
      }
      // const day = new Date();
      // day.setDate(day.getDate() - 1);
      // return { to: day }
    },
    disabledEndDates() {
      //Disable the ride dates
      const lastArrivalTime = new Date();
      lastArrivalTime.setDate(new Date(this.leaveData.lastArrivalTime).getDate());
      const lastDepartureTime = new Date();
      lastDepartureTime.setDate(new Date(this.leaveData.lastDepartureTime).getDate() -1);
      return {ranges: [{ // Disable dates in given ranges (exclusive).
          from: lastDepartureTime,
          to: lastArrivalTime
        }]}
     // return { to: this.formData.startAt || new Date() }
    },
    driver(employeeId){
      this.$store.dispatch(FETCH_DRIVER, this.slug).then((response) => {
        response = response.data;
        this.leaveData.lastDepartureTime = response.lastRoute.lastDepartureTime;
        this.leaveData.lastArrivalTime = response.lastRoute.lastArrivalTime;
        this.leaveData.lastDepartureTime = response.lastRoute.lastDepartureTime;
        this.$log.info('FETCH_DRIVER', response);
      }).catch((error) => {
        this.$log.error('FETCH_DRIVER', error);
      });
    },
    initLeaveRequests(employeeId) {
      this.$store.dispatch(FETCH_EMPLOYEE_LEAVE_REQUESTS, employeeId).then((response) => {
        this.$log.info('FETCH_EMPLOYEE_LEAVE_REQUESTS', response);
      }).catch((error) => {
        this.$log.error('FETCH_EMPLOYEE_LEAVE_REQUESTS', error);
      });
    },
    initEmployee(employeeId) {
      this.$store.dispatch(FETCH_EMPLOYEE, employeeId).then((response) => {
        this.$log.info('FETCH_EMPLOYEE', response);
      }).catch((error) => {
        this.$log.error('FETCH_EMPLOYEE', error);
      });
    },
    onSubmitNewLeaveRequest() {
      this.$v.$touch();
      if (this.$v.formData.$invalid) {
        this.submitStatus = 'ERROR';
        return;
      }

      this.submitStatus = 'PENDING';
      const data = {
        employeeId: this.slug,
        leaveType: this.formData.leaveType,
        startAt: this.formData.startAt,
        endAt: this.formData.endAt,
      };
      this.$store.dispatch(EMPLOYEE_LEAVE_REQUESTS_CREATE_SUMBIT, data).then((response) => {
        this.$log.info('EMPLOYEE_LEAVE_REQUESTS_CREATE_SUMBIT', response);
        this.submitStatus = null;
        this.initLeaveRequests(this.slug);
        this.formData = {
          leaveType: null,
          startAt: null,
          endAt: null,
        };
      }).catch((error) => {
        this.$log.error('EMPLOYEE_LEAVE_REQUESTS_CREATE_SUMBIT', error);
      });
    },
    resetEditModal() {
      this.editFormData.id = null;
      this.editFormData.startAt = null;
      this.editFormData.endAt = null;
      this.editFormData.leaveType = null;
    },

    onEditLeaveRequest(leaveRequest) {
      this.editFormData.id = leaveRequest.id;
      this.editFormData.startAt = leaveRequest.startAt;
      this.editFormData.endAt = leaveRequest.endAt;
      this.editFormData.leaveType = leaveRequest.leaveType;
      this.isEditFormVisible = true;
    },
    onSubmitEditLeaveRequest() {
      this.$v.$touch();
      if (this.$v.editFormData.$invalid) {
        this.editSubmitStatus = 'ERROR';
        return;
      }

      this.editSubmitStatus = 'PENDING';
      this.$store.dispatch(EMPLOYEE_LEAVE_REQUESTS_EDIT_SUBMIT, this.editFormData).then((response) => {
        this.$log.info('EMPLOYEE_LEAVE_REQUESTS_EDIT_SUBMIT', response);
        this.editSubmitStatus = null;
        this.initLeaveRequests(this.slug);
        this.editFormData = {
          id: null,
          leaveType: null,
          startAt: null,
          endAt: null,
        };
        this.isEditFormVisible = false;

      }).catch((error) => {
        this.$log.error('EMPLOYEE_LEAVE_REQUESTS_EDIT_SUBMIT', error);
      });
    },
    onDeleteLeaveRequest(leaveRequest) {
      this.$confirm('Esti sigur ca vrei sa stergi cererea?', 'Stergere cerere #' + leaveRequest.id, 'question', {
        cancelButtonText: 'Nu',
        confirmButtonText: 'Da',
        showLoaderOnConfirm: true
      }).then((response) => {
        if (response) {
          this.isLoading = true;
          this.$store.dispatch(EMPLOYEE_LEAVE_REQUESTS_DELETE, leaveRequest.id).then((response) => {
            this.$log.info('EMPLOYEE_LEAVE_REQUESTS_DELETE', response);
            this.initLeaveRequests(this.slug);
          }).catch((error) => {
            this.$log.error('EMPLOYEE_LEAVE_REQUESTS_DELETE', error);
          });
        }
      });

    },
  },
  watch: {
    slug: {
      handler(val) {
        this.initLeaveRequests(val);
      },
    },
  },
};
</script>
