<template>
  <div>
    <md-card class="card filters">
      <md-card-content class="card-body rounded shadow-lg">
        <div class="card-header text-purple">
          Cautare
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-3 col-sm-12">
              <div class="form-group">
                <label class="text-purple pl-1">
                  Client
                </label>
                <input
                  v-model="filters.nume"
                  class="form-control mb-3"
                  type="text"
                >
              </div>
            </div>
            <div class="col-md-3 col-sm-12">
              <div class="form-group">
                <label class="text-purple pl-1">
                  Ruta
                </label>
                <input
                  v-model="filters.numeRuta"
                  class="form-control mb-3"
                  type="text"
                >
              </div>
            </div>
            <div class="col-md-3 col-sm-12">
              <div class="form-group">
                <label class="text-purple pl-1">
                  Bilet
                </label>
                <input
                  v-model="filters.bilet"
                  class="form-control mb-3"
                  type="text"
                >
              </div>
            </div>
            <div class="col-md-3 col-sm-12">
              <div class="form-group">
                <label class="text-purple pl-1">
                  Punct Plecare
                </label>
                <input
                  v-model="filters.punctPlecare"
                  class="form-control mb-3"
                  type="text"
                >
              </div>
            </div>
            <div class="col-md-3 col-sm-12">
              <div class="form-group">
                <label class="text-purple pl-1">
                  Punct Sosire
                </label>
                <input
                  v-model="filters.punctSosire"
                  class="form-control mb-3"
                  type="text"
                >
              </div>
            </div>
            <div class="col-md-3 col-sm-12">
              <div class="form-group">
                <label class="text-purple pl-1">
                  Nume Punct
                </label>
                <input
                  v-model="filters.numePunct"
                  class="form-control mb-3"
                  type="text"
                >
              </div>
            </div>
            <div class="col-md-3 col-sm-12">
              <div class="form-group">
                <label class="text-purple pl-1">
                  Nume Jurnal
                </label>
                <input
                  v-model="filters.numeJurnal"
                  class="form-control mb-3"
                  type="text"
                >
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-2 col-sm-12 pl-1">
              <md-button class="btn-purple btn-block shadow purple" @click="onSearch()">
                Cauta
              </md-button>
            </div>
          </div>
        </div>
      </md-card-content>
    </md-card>
    <md-card class="card">
      <md-card-content class="card-body rounded shadow-lg">
        <div class="listing-page">
          <table class="table table-responsive-lg table-striped table-hover t-vehicles" style="table-layout: fixed; width: 100%;">
            <!--<table class="table table-bordered table-responsive-lg table-striped text-nowrap">-->
            <thead class="bg--white">
            <tr>
              <th
                v-for="column in columns"
                class="text-purple align-top text-center"
                style="word-wrap: normal"
              >
                {{ column.label }}
              </th>
            </tr>
            </thead>
            <tbody class="text-center" id="tbody">
            <tr v-for="(item, index) in reservations" :class="{highlight:item.selected}" :id="item.id">
              <td
                @click="onSelect(index, $event,item)"
                v-for="column in columns"
                class="align-top text-center"
                style="word-wrap: normal"
              >
                {{ getItemProperty(item, column.name) }}
              </td>
            </tr>
            </tbody>
          </table>
        </div>


        <!--PAGINATION-->
        <div class="md-layout">
          <div class="md-layout-item md-size-50">
            {{ reservationsTotal }} Rezervari (de la {{ reservationsFrom }} pana la {{ reservationsTo }})
          </div>
          <div class="md-layout-item md-size-50">
            <rom-paginator :current-page="reservationsCurrentPage"
                           :last-page="reservationsLastPage"
                           :threshold="2"
                           :has-prev-next="true"
                           :has-first-last="true"
                           @onFirstPage="onFirstPage"
                           @onPreviousPage="onPreviousPage"
                           @onNextPage="onNextPage"
                           @onLastPage="onLastPage"
                           @onChangePage="onChangePage">

            </rom-paginator>
          </div>
        </div>
      </md-card-content>
    </md-card>
    <div class="col-md-12">
      <button class="btn  btn-purple btn-block shadow" style="margin-top: 10px;" @click="onSaveSelectedReservation" >Salveaza</button>
    </div>
  </div>
</template>

<script>

// @vue/component
import ListingPage from '@/components/page-templates/ListingPage.vue';
import RomPaginator from '@/components/pagination/RomPaginator.vue';
import vehicleStatusValues from '@/enums/vehicleStatusValues';
import moment from 'moment';
import Datepicker from 'vuejs-datepicker';
import {
  FETCH_RESERVATIONS,
  FETCH_RESERVATION,
  UPDATE_FILTER_RESERVATIONS,
} from '@/store/actions.type';

export default {
  name: 'SelectReservation',
  components: {
    ListingPage,
    RomPaginator,
    Datepicker,
  },
  props: [
    'selectedReservations',
    'selected-Step2',
    'currentCountryCode',
    'reservationCountryObj',
  ],
  data: () => ({
    columns: [
      {
        name: 'id',
        label: 'ID',
      },
      {
        name: 'nume',
        label: 'Client',
      },
      {
        name: 'telefon',
        label: 'Telefon',
      },
      {
        name: 'dataCursa',
        label: 'Data Cursa',
        type: 'date',
      },
      {
        name: 'tarif',
        label: 'Tarif',
      },
      {
        name: 'codMoneda',
        label: 'Moneda',
      },
      {
        name: 'numeRuta',
        label: 'Ruta',
      },
      {
        name: 'bilet',
        label: 'Bilet',
      },
      {
        name: 'punctPlecare',
        label: 'Punct Plecare',
      },
      {
        name: 'punctSosire',
        label: 'Punct Sosire',
      },
      {
        name: 'numePunct',
        label: 'Nume Punct',
      },
      {
        name: 'numeJurnal',
        label: 'Nume Jurnal',
      },
      {
        name: 'observatii',
        label: 'Observatii',
      },
      {
        name: 'renuntata',
        label: 'Renuntat',
      },
    ],
    filters: {
      id: null,
      nume: null,
      dataCursa: null,
      numeRuta: null,
      bilet: null,
      numePunct: null,
      punctSosire: null,
      punctPlecare: null,
      numeJurnal: null,
      countryCodes: null,
      currentCountry: null,
    },
    items: [],
    actions: {},
  }),
  computed: {
    reservations() {
      return this.$store.getters.reservationsItems;
    },
    reservationsPerPage() {
      return this.$store.getters.reservationsPerPage;
    },
    reservationsCurrentPage() {
      return this.$store.getters.reservationsCurrentPage;
    },
    reservationsLastPage() {
      return this.$store.getters.reservationsLastPage;
    },
    reservationsFrom() {
      return this.$store.getters.reservationsFrom;
    },
    reservationsTo() {
      return this.$store.reservationsTo;
    },
    reservationsTotal() {
      return this.$store.getters.reservationsTotal;
    },
    reservationsFilters() {
      return this.$store.getters.reservationsFilters;
    },
  },
  mounted() {
    this.items = this.selectedReservations;
    this.initReservations();

  },
  methods: {
    getItemProperty(item, property) {
      const properties = property.split('.');
      while(properties.length && (item = item[properties.shift()]));
      if(property == 'dataCursa') {
        item = moment(String(item)).format('DD/MM/YYYY');
      }
      return item;
    },
    isNumeric(item, property) {
      return !isNaN(this.getItemProperty(item, property));
    },
    //This method is used to select multiple reservations
    onSelect(index, event, item) {
      //Check if the item selecte have highlight class (the highlight class is present when the item is selected)
      if(event.target.parentElement.className.match(/\bhighlight\b/)) {
        //if the item is already selected, deselect it and remove the highlight class
        event.target.parentElement.className -= " highlight";
        this.items = this.items.filter((obj) => {
          return obj.id !== item.id;
        })
      } else {
        //If doesn't have the highlight class, add the class on the current item
        event.target.parentElement.className += " highlight";
        this.items.push(item);
      }
    },
    initReservations() {
      this.filters.currentCountry = this.currentCountryCode;
      this.filters.countryCodes = Object.keys(this.reservationCountryObj);
      const params = {
        page: this.reservationsCurrentPage,
        perPage: this.reservationsPerPage,
        filters: this.filters,
      };

      this.fetchReservations(params);
    },
    fetchReservations(params) {
      this.$store.dispatch(FETCH_RESERVATIONS, params).then((response) => {
        var itemsIds = []; //We create a new array with the reservation ids from Course Planning Page (when the Reservation is opened to know which reservation is already selected)
        this.items.forEach(function (item) { //Add the ids that already exist into array
          itemsIds.push(item.id);
        })
        //Check if the restult items are in the ids array to select or unselect them
        response.data.forEach(function (reservation) {
          if(jQuery.inArray(reservation.id, itemsIds) >= 0) {
            $('#'+reservation.id+'').addClass('highlight');
          } else {
            $('#'+reservation.id+'').removeClass('highlight');
          }
        });
        // console.log(itemsIds);
        this.$log.info('FETCH_RESERVATIONS', response);
      }).catch((error) => {
        this.$log.error('FETCH_RESERVATIONS', error);
      });
    },
    onFirstPage() {
      const params = {
        page: 1,
        perPage: this.reservationsPerPage,
        filters: this.filters,
      };
      this.fetchReservations(params);
    },
    onPreviousPage() {
      const params = {
        page: this.reservationsCurrentPage - 1,
        perPage: this.reservationsPerPage,
        filters: this.filters,
      };
      this.fetchReservations(params);
    },
    onChangePage(data) {
      const params = {
        page: data,
        perPage: this.reservationsPerPage,
        filters: this.filters
      };
      this.fetchReservations(params);
    },
    onNextPage() {
      const params = {
        page: this.reservationsCurrentPage + 1,
        perPage: this.reservationsPerPage,
        filters: this.filters,
      };
      this.fetchReservations(params);
    },
    onLastPage() {
      const params = {
        page: this.reservationsLastPage,
        perPage: this.reservationsPerPage,
        filters: this.filters
      };
      this.fetchReservations(params);
    },
    refreshList(data) {
      const params = {
        page: 1,
        perPage: data,
      };
      this.fetchReservations(params);
    },
    onSearch(data) {
      let query = {
        page: 1,
        perPage: this.reservationsPerPage,
        filters: this.filters
      };

     this.fetchReservations(query);
    },
    //Send the selected reservations to parent page (Planning Course page)
    onSaveSelectedReservation() {
      // this.$emit('selectedStep', true);
      this.$emit('onSelectReservation', this.items);
    }
  },
};

</script>
