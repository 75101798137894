// @vue/component
import ListingPage from "@/components/page-templates/ListingPage";
import RomPaginator from "@/components/pagination/RomPaginator";
import moment from 'moment';
import Datepicker from 'vuejs-datepicker';
import {
  FETCH_RIDES, FETCH_ROUTEDEFINITION
} from '@/store/actions.type';
import daysCalendar from "@/enums/calendar";

export default {
    name: 'Cruiselist',

  components: {
    ListingPage,
    RomPaginator,
    Datepicker
  },
  data: () => ({
    columns: [
      {
        name: 'codCursa',
        label: 'Id Cursa',
      },
      {
        name: 'codIdentificare',
        label: 'Cod Identificare',
      },
      {
        name: 'data',
        label: 'Data',
        type: 'date'
      },
      {
        name: 'ziua',
        label: 'Ziua',
      },
      {
        name: 'numeRuta',
        label: 'Nume Ruta',
      },
      {
        name: 'codUtil',
        label: 'Cod Util',
      },
      {
        name: 'masina',
        label: 'Masina',
      },
    ],
    filters: {
      codCursa: null,
      data: null,
      codIdentificare: null,
      numeRuta: null,
    },
    currentSort: {column: 'codCursa', dir: 'desc'},
  }),

  computed: {
    rides() {
      return this.$store.getters.ridesItems;
    },
    ridesPerPage() {
      return this.$store.getters.ridesPerPage;
    },
    ridesCurrentPage() {
      return this.$store.getters.ridesCurrentPage;
    },
    ridesLastPage() {
      return this.$store.getters.ridesLastPage;
    },
    ridesFrom() {
      return this.$store.getters.ridesFrom;
    },
    ridesTo() {
      return this.$store.getters.ridesTo;
    },
    ridesTotal() {
      return this.$store.getters.ridesTotal;
    },
    ridesFilters() {
      return this.$store.getters.ridesFilters;
    },
  },
  mounted() {
    this.initrides();

  },
  methods: {
    initrides() {
      const params = {
        page: this.ridesCurrentPage,
        perPage: this.ridesPerPage,
        filters: this.filters,
        sort: this.currentSort,
      };
      this.fetchRides(params);
    },
    fetchRides(params) {
      this.$store.dispatch(FETCH_RIDES, params).then((response) => {
        this.$log.info('FETCH_rides', response);
      }).catch((error) => {
        this.$log.error('FETCH_rides', error);
      });
    },

    onFirstPage() {
      const params = {
        page: 1,
        perPage: this.ridesPerPage,
        filters: this.filters,
      };
      this.fetchRides(params);
    },
    onPreviousPage() {
      const params = {
        page: this.ridesCurrentPage - 1,
        perPage: this.ridesPerPage,
        filters: this.filters,
      };
      this.fetchRides(params);
    },
    onChangePage(data) {
      const params = {
        page: data,
        perPage: this.ridesPerPage,
        filters: this.filters
      };
      this.fetchRides(params);
    },
    onNextPage() {
      const params = {
        page: this.ridesCurrentPage + 1,
        perPage: this.ridesPerPage,
        filters: this.filters,
      };
      this.fetchRides(params);
    },
    onLastPage() {
      const params = {
        page: this.ridesLastPage,
        perPage: this.ridesPerPage,
        filters: this.filters
      };
      this.fetchRides(params);
    },
    refreshList(data) {
      const params = {
        page: 1,
        perPage: data,
      };
      this.fetchRides(params);
    },
    onSearch(data) {
      let query = {
        page: 1,
        perPage: this.ridesPerPage,
        filters: this.filters,
        sort: this.currentSort,
      };
      this.fetchRides(query);
    },
    onDetail(item) {
      if(item.status == null) {
        window.open('https://safir.romfour.com/romfour/index.php?r=cursa/update&id='+item.codCursa, '_blank');
      } else if((item.status !== 'completed' && item.status !== null) || item.status == 'manual') {
        this.$alert('Aceasta Cursa inca nu are planificata o cursa!','','warning');
      } else if (item.status == 'completed') {
        this.$router.push({name: 'cruisePlanningView', params: {slug: item.cruiseId, routeTypeId: item.tipRuta, identificationCode: item.codIdentificare, cruiseDate: item.data, cruiseDay: item.ziua, cruiseList: true}});
      }
    }
  }
}
