import Datepicker from 'vuejs-datepicker';
import { VueAutosuggest } from 'vue-autosuggest'
import {required, maxLength} from 'vuelidate/lib/validators'
import Multiselect from 'vue-multiselect'
import RomfourLocalityAutosuggest from "@/components/autosuggest/RomfourInternalLocality";
import {
  FETCH_HUB_ROUTES,
  FETCH_TIPURI_RUTE,
  ROUTEDEFINITION_CREATE_SUBMIT,
  FETCH_ROUTEDEFINITIONS
} from '@/store/actions.type';
import daysCalendar from '@/enums/calendar';
import Vue from "vue";

export default {
  name: 'RoutedefinitionsNewPage',
  components: {
    Datepicker,
    Multiselect,
    RomfourLocalityAutosuggest,
    VueAutosuggest
  },
  data: () => ({
    formData: {
      name: null,
      transport_type: 'TR_PERS',
      route: 'new_route',
      route_type: 'internal',
      track_type: 'tur',
      calendar: [],
      tbl_tipuri_curse_id: null,
      country_start: null,
      country_end: null,
      middle_countries: [],
    },
    tipuriRuteList: [],
    countryList: [],
    submitStatus: null,

    days: daysCalendar,
    sugestionsProps: {
      name: "autosuggest__input__localities",
      class: 'form-control autosuggest__input',
      placeholder: '....'
    },
  }),
  validations: {
    formData: {
      name: {required},
      transport_type: {required},
      route: {required},
      route_type: {required},
      track_type: {required},
      calendar: {required},
      tbl_tipuri_curse_id: {required},
      country_start: {required},
      country_end: {required},
    },
  },
  computed: {
    newRouteDefinitionForm() {
      return this.$store.getters.newRouteDefinitionForm;
    },
  },
  mounted() {
    this.initHubRoutes();
    this.initTipuriRute();
  },
  methods: {
    initHubRoutes() {
      this.$store.dispatch(FETCH_HUB_ROUTES).then((response) => {
        this.countryList = response.data;
        this.$log.info('FETCH_HUB_ROUTES', response);
      }).catch((error) => {
        this.$log.error('FETCH_HUB_ROUTES', error);
      });
    },
    initTipuriRute() {
      this.$store.dispatch(FETCH_TIPURI_RUTE).then((response) => {
        this.tipuriRuteList = response.data;
        this.$log.info('FETCH_TIPURI_RUTE', response);
      }).catch((error) => {
        this.$log.error('FETCH_TIPURI_RUTE', error);
      });
    },
    onChange(e) {
      const theTarget = e.target.options[e.target.options.selectedIndex].dataset;
      this.formData.transport_type = theTarget.facturat;
      this.formData.name = theTarget.name;
    },
    goToRoute(route) {
      this.$router.push({name: route});
    },
    computedActiveClass(actualValue, expectedValue) {
      return actualValue === expectedValue ? 'active' : '';
    },
    selectLocalityNameStartHandler(item) {
      this.formData.country_start = item ? item.numeLocalitate : null;
    },
    selectLocalityNameEndHandler(item) {
      this.formData.country_end = item ? item.numeLocalitate : null;
    },
    onSubmitRouteDefinition(e) {
      if (this.formData.route == 'new_route') {
        this.formData.tbl_tipuri_curse_id = 0;
      }
      // if(this.formData.route_type == 'internal') {
      //   this.formData.middle_countries = null;
      // }
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR';
        return;
      }
      this.submitStatus = 'PENDING';
      let formData = new FormData();
      formData.append('data', JSON.stringify(this.formData));
      this.$store.dispatch(ROUTEDEFINITION_CREATE_SUBMIT, formData).then((response) => {
        this.submitStatus = null;
        Vue.notify({
          group: 'success',
          type: 'success',
          title: 'Ruta a fost creata cu success!',
        })
        this.$log.info('ROUTEDEFINITION_CREATE_SUBMIT', response);
        this.$router.push({name: 'routeDefinitions'});
      }).catch((error) => {
        this.submitStatus = null;
        if (error.response && error.response.status === 400) {
          this.invalidFieldsErrors = error.response.data || {};
        }
        this.$log.error('ROUTEDEFINITION_CREATE_SUBMIT', error);
      });
    },
  }
};
