<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="true"
             :on-cancel="() => {}"
             :is-full-page="fullPage"
             :color="color"
             :loader="loader"
    ></loading>

    <div class="row  pt-5 pb-3">
      <div class="col">
        <span class="mx-1" style="color:#603084;"> <strong class="font-weight-bold" style="font-size: 20px;">Alocare dosare</strong></span>
        <a class="btn btn-md btn-raised btn-wave btn-icon btn-rounded mb-2 text-white" style="background-color:#603084" v-if="canCreateRouteFile"
           @click="goToRoute('routeFilesNew')">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
               stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
               class="feather feather-plus">
            <line x1="12" y1="5" x2="12" y2="19"></line>
            <line x1="5" y1="12" x2="19" y2="12"></line>
          </svg>

        </a>
      </div>
    </div>
    <div class="row pt-4">
      <div class="col">
        <div class="card rounded shadow-lg">
          <div class="card-header text-purple">
            Cautare
          </div>
          <div class="card-body">
            <div class="row">

              <div class="col-md">
                <div class="form-group">
                  <label class="text-purple pl-1" for="routeFileNo">
                    Numar Dosar
                  </label>
                  <input id="routeFileNo" type="text" class="form-control" placeholder="Numar Dosar"
                         v-model="filters.routeFileNumber">
                </div>
              </div>
              <div class="col-md">
                <div class="form-group">
                  <label class="text-purple pl-1">
                    Localitate Plecare
                  </label>
                  <romfour-route-station-autosuggest
                    v-model="departureRegion"
                    @onSelectSuggestion="selectDepRegionHandler"
                  />
                </div>
              </div>

              <div class="col-md">
                <div class="form-group">
                  <label class="text-purple pl-1">
                    Localitate Destinatie
                  </label>
                  <romfour-route-station-autosuggest
                    v-model="arrivalRegion"
                    @onSelectSuggestion="selectArrivalRegionHandler"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md">
                <div class="form-group">
                  <label class="text-purple pl-1">
                    ID Dosar
                  </label>
                  <input id="routeFileId" type="text" class="form-control" placeholder="Dosar"
                         v-model="filters.routeFileId">
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label class="text-purple pl-1">
                    Data Plecare
                  </label>
                  <datepicker :clear-button="true" :bootstrap-styling="true"
                              input-class="form-control"
                              v-model="filters.departureTime" id="departureTime" name="departureTime"></datepicker>
                </div>
              </div>

              <div class="col">
                <div class="form-group">
                  <label class="text-purple pl-1">
                    Data Sosire
                  </label>
                  <datepicker :clear-button="true" :bootstrap-styling="true" input-class="form-control" v-model="filters.arrivalTime" id="arrivalTime" name="arrivalTime"></datepicker>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label class="text-purple pl-1">
                    Numar Inmatriculare
                  </label>
                  <romfour-vehicle-autosuggest
                    v-model="selectedVehicle"
                    @onSelectSuggestion="selectVehicleHandler"
                  />
                </div>
              </div>
              <div class="col-md-4 col-sm-12">
                <div class="form-group">
                  <label class="text-purple pl-1">
                    Nume Sofer
                  </label>
                  <romfour-driver-autosuggest
                    v-model="selectedDriverName"
                    @onSelectSuggestion="selectDriverHandler"
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label class="text-purple pl-1">
                    Status Dosar
                  </label>
                  <select class="form-control mb-3" id="dosar_status" label="Status Dosar" v-model="filters.routeFileStatus">
                    <option value=""></option>
                    <option value="open">Deschis</option>
                    <option value="closed">Inchis</option>
                    <option value="forced-closed">Inchis Fortat</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-2">
                <button class="btn btn-purple btn-block shadow"  @click="onSearch()">Cauta</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <!--TABLE-->
        <listing-page :items="routeFiles"
                      :columns="columns"
                      :actions="{
                        edit: (item) => item.status !== 'closed',
                        logbooks: true,
                        close: (item) => item.status !== 'closed',
                      }"
                      @onEdit="onEdit"
                      @onClose="onClose"
                      @onManageLogbooks="onManageLogbooks"
        >
        </listing-page>

        <!--PAGINATION-->
        <div class="md-layout">
          <div class="md-layout-item md-size-50">
            Showing {{ routeFilesFrom }} to {{ routeFilesTo }} of {{ routeFilesTotal }} RouteFiles
          </div>
          <div class="md-layout-item md-size-50">
            <rom-paginator :current-page="routeFilesCurrentPage"
                           :last-page="routeFilesLastPage"
                           :threshold="2"
                           :has-prev-next="true"
                           :has-first-last="true"
                           @onFirstPage="onFirstPage"
                           @onPreviousPage="onPreviousPage"
                           @onNextPage="onNextPage"
                           @onLastPage="onLastPage"
                           @onChangePage="onChangePage">
            </rom-paginator>
          </div>
        </div>
      </div>
    </div>
    <div class="row pt-3 pb-5">
      <div class="col-4">
        <button class="btn btn-raised btn-wave mb-2 w-xs text-white w-100" style="background-color:#603084"  @click="dowloadReport('foiDeParcurs')">
          <span class="mx-1 font-weight-bold" v-if="generatingExport != 'foiDeParcurs'"> Raport foi de parcurs</span>
          <div v-if="generatingExport == 'foiDeParcurs'">
            <i class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import { VueAutosuggest } from 'vue-autosuggest';
  import ListingPage from '@/components/page-templates/ListingPage.vue';
  import RomPaginator from '@/components/pagination/RomPaginator.vue';
  import RomfourDriverAutosuggest from "@/components/autosuggest/RomfourDriver";
  import RomfourRouteStationAutosuggest from "@/components/autosuggest/RomfourRouteStations";
  import RomfourVehicleAutosuggest from "@/components/autosuggest/RomfourVehicles";
  import {
    FETCH_ROUTE_FILES,
    FETCH_REGIONS,
    FETCH_REPORT,
    CLOSE_ROUTE_FILE,
} from '@/store/actions.type';
  import Datepicker from 'vuejs-datepicker';

  import VueSimpleAlert from "vue-simple-alert";
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';


  export default {
    name: 'RouteFiles',
    components: {
      RomfourVehicleAutosuggest,
      RomfourRouteStationAutosuggest,
      RomfourDriverAutosuggest,
      VueAutosuggest,
      ListingPage,
      RomPaginator,
      Datepicker,
      VueSimpleAlert,
      Loading,
    },
    data: () => ({
      filters: {
        routeFileId: null,
        routeFileNumber: null,
        departureTime: null,
        arrivalTime: null,
        arrivalRegion: null,
        departureRegion: null,
        selectedVehicle: null,
        routeFileStatus: null,
        driverEmployeeId: null,
      },
      departureRegion: null,
      arrivalRegion: null,
      selectedVehicle: null,
      selectedDriverName: null,
      generatingExport: null,
      results: [],
      timeout: null,
      selected: null,
      debounceMilliseconds: 250,
      isLoading: false,
      fullPage: true,
      loader: 'spinner',
      color: '#007bff',
      columns: [
        {
          name: 'id',
          label: 'ID Dosar',
        },
        {
          name: 'routeFileNumber',
          label: 'Numar Dosar',
        },
        {
          name: 'departureTime',
          label: 'Data plecarii',
          type: 'date'
        },
        {
          name: 'arrivalTime',
          label: 'Data sosirii',
          type: 'date'
        },
        {
          name: 'departureRegion.statia',
          label: 'Localitate Plecare',
        },
        {
          name: 'arrivalRegion.statia',
          label: 'Localitate Destinatie',
        },
        {
          name: 'phoneNumber',
          label: 'Nr. telefon',
        },
        {
          name: 'observations',
          label: 'Observatii',
        },
        {
          name: 'vehicle.numarInmatriculare',
          label: 'Vehicul',
        },
        {
          name: 'trail.numarInmatriculare',
          label: 'Remorca',
        },
        {
          name: ['mainDriver.nume', 'mainDriver.prenume'],
          label: 'Sofer principal',
        },
        {
          name: ['backupDriver.nume', 'backupDriver.prenume'],
          label: 'Sofer rezerva',
        },
        {
          name: ['thirdDriver.nume', 'thirdDriver.prenume'],
          label: 'Sofer Rezerva 2',
        },
        {
        name: ['transferDriver.nume', 'transferDriver.prenume'],
        label: 'Sofer Transfer',
        },
        {
          name: 'status',
          label: 'Status',
          mapper: (value) => {
            switch (value) {
              case 'open':
                return 'Deschis';
              case 'closed':
                return 'Inchis';
              default:
                return 'Deschis';
            }
          }
        },
        {
          name: 'owner.username',
          label: 'Creat de'
        },
        {
          name: 'createdAt',
          label: 'Creat la data',
          type: 'datetime'
        },
      ],
    }),
    computed: {
      canCreateRouteFile() {
        return this.$store.getters.permissions.createEditRouteFile;
      },
      regions() {
        return this.$store.getters.regionsItems;
      },
      routeFiles() {
        return this.$store.getters.routeFilesItems;
      },
      routeFilesPerPage() {
        return this.$store.getters.routeFilesPerPage;
      },
      routeFilesCurrentPage() {
        return this.$store.getters.routeFilesCurrentPage;
      },
      routeFilesLastPage() {
        return this.$store.getters.routeFilesLastPage;
      },
      routeFilesFrom() {
        return this.$store.getters.routeFilesFrom;
      },
      routeFilesTo() {
        return this.$store.getters.routeFilesTo;
      },
      routeFilesTotal() {
        return this.$store.getters.routeFilesTotal;
      },
    },
    mounted() {
      this.initRouteFiles();
    },
    methods: {
      goToRoute(route) {
        this.$router.push({ name: route});
      },
      initRouteFiles() {
        const params = {
          page: this.routeFilesCurrentPage,
          perPage: this.routeFilesPerPage || 20,
        };
        this.fetchRouteFiles(params);
      },
      selectDepRegionHandler(item) {
        this.filters.departureRegion = item ? item.id : null;
      },
      selectVehicleHandler(item) {
        this.filters.selectedVehicle = item ? item.codAutovehicul : null;
      },
      selectDriverHandler(item) {
        if (!item) {
          this.selectedDriverName = '';
        }

        this.filters.driverEmployeeId = item ? item.codAng : null;
      },
      selectArrivalRegionHandler(item) {
        this.filters.arrivalRegion = item ? item.id : null;
      },

      fetchRouteFiles(params) {
        this.$store.dispatch(FETCH_ROUTE_FILES, params).then((response) => {
          this.$log.info('FETCH_ROUTE_FILES', response);
        }).catch((error) => {
          this.$log.error('FETCH_ROUTE_FILES', error);
        });
      },
      onFirstPage() {
        const params = {
          page: 1,
          perPage: this.routeFilesPerPage,
          filters: this.filters
        };
        this.fetchRouteFiles(params);
      },
      onPreviousPage() {
        const params = {
          page: this.routeFilesCurrentPage - 1,
          perPage: this.routeFilesPerPage,
          filters: this.filters
        };
        this.fetchRouteFiles(params);
      },
      onChangePage(data) {
        const params = {
          page: data,
          perPage: this.routeFilesPerPage,
          filters: this.filters
        };
        this.fetchRouteFiles(params);
      },
      onNextPage() {
        const params = {
          page: this.routeFilesCurrentPage + 1,
          perPage: this.routeFilesPerPage,
          filters: this.filters
        };
        this.fetchRouteFiles(params);
      },
      onLastPage() {
        const params = {
          page: this.routeFilesLastPage,
          perPage: this.routeFilesPerPage,
          filters: this.filters
        };
        this.fetchRouteFiles(params);
      },
      onSearch(data) {
        let query = {
          page: 1,
          perPage: this.vehiclesPerPage,
          filters: this.filters
        };

        this.fetchRouteFiles(query);
      },
      refreshList(data) {
        const params = {
          page: this.routeFilesCurrentPage,
          perPage: data || this.routeFilesPerPage,
          filters: this.filters
        };
        this.fetchRouteFiles(params);
      },
      create() {
        this.$toasted.show('create', {
          type: 'info',
          position: 'top-right',
          duration: 5000,
          icon: 'check',
        });
      },
      onEdit(routeFile) {
        this.$router.push({ name: 'routeFilesEdit', params: { slug: routeFile.id } });
      },
      onManageLogbooks(routeFile) {
        this.$router.push({ name: 'routeFilesLogbooks', params: { slug: routeFile.id } });
      },
      onClose(routeFile) {
        this.$confirm('Esti sigur ca vrei sa inchizi dosarul?', 'Inchidere dosar #' + routeFile.id, 'question', {
          cancelButtonText: 'Nu',
          confirmButtonText: 'Da',
          showLoaderOnConfirm: true
        }).then((response) => {
          if (response) {
            this.isLoading = true;
            this.closeRouteFile(routeFile.id);
          }
        });
      },
      dowloadReport(reportName) {
        this.generatingExport = reportName;
        this.$store.dispatch(FETCH_REPORT, {reportName})
          .then((response) => {
          this.generatingExport = null;
          this.$log.info('FETCH_REPORT', response);
        }).catch((error) => {
          this.$log.error('FETCH_REPORT', error);
        });
      },
      closeRouteFile(routeFileId) {
        this.$store.dispatch(CLOSE_ROUTE_FILE, routeFileId)
          .then((response) => {
            this.refreshList();
            this.isLoading = false;
          this.$log.info('CLOSE_ROUTE_FILE', response);
        }).catch((error) => {
          this.isLoading = false;
          this.$log.error('CLOSE_ROUTE_FILE', error);
        });
      },
    },
  };
</script>
