<template>
  <div>
    <!--HEADER-->

    <div class="row">
      <div class="col mb-3">
        <span class="text-purple text-md pl-3 pr-1">Gestiune Hub-uri</span>
        <a class="btn btn-md btn-raised btn-wave btn-icon mb-2 btn-rounded text-white"
           style="background-color:#603084"
           @click="goToRoute('hubNew')"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
               stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
               class="feather feather-plus">
            <line x1="12" y1="5" x2="12" y2="19"></line>
            <line x1="5" y1="12" x2="19" y2="12"></line>
          </svg>
        </a>
      </div>
    </div>


    <md-card class="card" style="z-index: 2">
      <md-card-content class="card-body rounded shadow-lg">
        <div class="card-header text-purple">
          Cautare
        </div>
        <div>
          <div class="row">
<!--            <div class="col">-->
<!--              <div class="form-group">-->
<!--                <label class="text-purple pl-1">-->
<!--                  Status Huburi-->
<!--                </label>-->
<!--                <select class="form-control mb-3" id="sofer_status" label="Tip Hub" v-model="filters.type">-->
<!--                  <option value=""></option>-->
<!--                  <option value="intern">Intern</option>-->
<!--                  <option value="external">Extern</option>-->
<!--                </select>-->
<!--              </div>-->
<!--            </div>-->

            <div class="col-md">
              <div class="form-group">
                <label class="text-purple pl-1">
                  Nume Hub
                </label>
                <input id="nume" type="text" class="form-control" placeholder="Nume" v-model="filters.name">
              </div>
            </div>

<!--            <div class="col d-none d-sm-inline-block">-->
<!--              <div class="form-group">-->
<!--                <label class="text-purple pl-1">-->
<!--                  Latitudine-->
<!--                </label>-->
<!--                <input id="latitudine" type="text" class="form-control" placeholder="Latitudine"-->
<!--                       v-model="filters.latitude">-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="col d-none d-sm-inline-block">-->
<!--              <div class="form-group">-->
<!--                <label class="text-purple pl-1">-->
<!--                  Longitudine-->
<!--                </label>-->
<!--                <input id="longitudine" type="text" class="form-control" placeholder="Longitudine"-->
<!--                       v-model="filters.longitude">-->
<!--              </div>-->
<!--            </div>-->

<!--            <div class="col d-none d-sm-inline-block">-->
<!--              <div class="form-group">-->
<!--                <label class="text-purple pl-1">-->
<!--                  Adresa-->
<!--                </label>-->
<!--                <input id="adresa" type="text" class="form-control" placeholder="Adresa"-->
<!--                       v-model="filters.address">-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="row d-none d-sm-flex">-->

            <div class="col">
              <div class="form-group">
                <label class="text-purple pl-1">
                  Tara
                </label>
                <input id="tara" type="text" class="form-control" placeholder="Tara"
                       v-model="filters.country">
              </div>
            </div>

<!--            <div class="col">-->
<!--              <div class="form-group">-->
<!--                <label class="text-purple pl-1">-->
<!--                  Regiune-->
<!--                </label>-->
<!--                <input id="regiune" type="text" class="form-control" placeholder="Regiune"-->
<!--                       v-model="filters.region">-->
<!--              </div>-->
<!--            </div>-->

<!--            <div class="col">-->
<!--              <div class="form-group">-->
<!--                <label class="text-purple pl-1">-->
<!--                  Cod Postal-->
<!--                </label>-->
<!--                <input id="codPostal" type="text" class="form-control" placeholder="Cod Postal"-->
<!--                       v-model="filters.postalCode">-->
<!--              </div>-->
<!--            </div>-->
          </div>
          <div class="row">
            <div class="col-md-2">
              <button class="btn btn-purple btn-block shadow" @click="onSearch()">Cauta</button>
            </div>
          </div>

        </div>
      </md-card-content>
    </md-card>

    <md-card class="card">
      <md-card-content class="card-body rounded shadow-lg">
        <!--TABLE-->
        <div class="table-responsive-sm">
          <table class="table table-responsive-lg table-hover" style="table-layout: fixed; width: 100%;">
            <!--<table class="table table-bordered table-responsive-lg table-striped text-nowrap">-->
            <thead class="bg--white">
            <tr>
              <th class="text-purple align-top text-center d-md-table-cell" style="word-wrap: normal"
                  @click="onSort('id')">
                <div class="sortable" :class="[currentSort.column === 'id' ? this.currentSort.dir : '']">
                  ID
                </div>
              </th>
              <th class="text-purple align-top text-center d-none d-md-table-cell" style="word-wrap: normal"
                  @click="onSort('name')">
                <div class="sortable" :class="[currentSort.column === 'name' ? this.currentSort.dir : '']">
                  Nume
                </div>
              </th>
              <th class="text-purple align-top text-center d-md-table-cell" style="word-wrap: normal">
                Tip
              </th>
              <th class="text-purple align-top text-center d-none d-md-table-cell" style="word-wrap: normal">
                Latitudine
              </th>
              <th class="text-purple align-top text-center d-none d-md-table-cell" style="word-wrap: normal">
                Longitudine
              </th>
              <th class="text-purple align-top text-center d-none d-md-table-cell" style="word-wrap: normal">
                Adresa
              </th>
              <th class="text-purple align-top text-center d-none d-md-table-cell" style="word-wrap: normal">
                Tara
              </th>
              <th class="text-purple align-top text-center d-md-table-cell" style="word-wrap: normal">
                Regiune
              </th>
              <th class="text-purple align-top text-center d-md-table-cell" style="word-wrap: normal">
                Cod Postal
              </th>
              <th class="text-purple align-top text-center d-md-table-cell"
                  style="word-wrap: normal"
              >Actiuni
              </th>
            </tr>
            </thead>
            <tbody class="text-center" id="tbody">
            <tr v-for="hub in hubs">
              <td class="align-top text-center d-md-table-cell" style="word-wrap: normal">
                {{ hub.id }}
              </td>
              <td class="align-top text-center d-none d-md-table-cell" style="word-wrap: normal">
                {{ hub.name }}
              </td>
              <td class="align-top text-center d-md-table-cell" style="word-wrap: normal">
                {{ hub.type }}
              </td>
              <td class="align-top text-center d-none d-md-table-cell" style="word-wrap: normal">
                {{ hub.latitude }}
              </td>
              <td class="align-top text-center d-none d-md-table-cell" style="word-wrap: normal">
                {{ hub.longitude }}
              </td>
              <td class="align-top text-center d-none d-md-table-cell" style="word-wrap: normal">
                {{ hub.address }}
              </td>
              <td class="align-top text-center d-none d-md-table-cell" style="word-wrap: normal">
                {{ hub.country }}
              </td>
              <td class="align-top text-center d-md-table-cell" style="word-wrap: normal">
                {{ hub.region }}
              </td>
              <td class="align-top text-center d-none d-md-table-cell" style="word-wrap: normal">
                {{ hub.postalCode }}
              </td>
              <td class="align-top text-center d-md-table-cell" style="word-wrap: normal">
                <a class="btn text-purple font-weight-bold" @click="onEdit(hub)">Editeaza</a>
                <a class="btn text-purple font-weight-bold" @click="onDelete(hub)">Sterge</a>
              </td>
            </tr>

            </tbody>
          </table>
        </div>

        <!--PAGINATION-->
        <div class="md-layout">
          <div class="md-layout-item md-size-50">
            {{ hubsTotal }} hub-uri (de la {{ hubsFrom }} pana la {{ hubsTo }})
          </div>
          <div class="md-layout-item md-size-50">
            <rom-paginator :current-page="hubsCurrentPage"
                           :last-page="hubsLastPage"
                           :itemsPerPage="hubsPerPage"
                           :threshold="2"
                           :has-prev-next="true"
                           :has-first-last="true"
                           @onFirstPage="onFirstPage"
                           @onPreviousPage="onPreviousPage"
                           @onNextPage="onNextPage"
                           @onLastPage="onLastPage"
                           @onChangePage="onChangePage"
                           @onChangePageSize="onChangePageSize"
            >
            </rom-paginator>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>

</template>

<script>
import ListingPage from '@/components/page-templates/ListingPage.vue';
import RomPaginator from '@/components/pagination/RomPaginator.vue';
import {
  FETCH_HUBS,
  HUB_DELETE_SUBMIT,
} from '@/store/actions.type';
import moment from 'moment';
import Datepicker from 'vuejs-datepicker';
import Vue from "vue";

export default {
  name: 'Hubs',
  components: {
    ListingPage,
    RomPaginator,
    Datepicker,
  },
  data: () => ({
    filters: {
      id: null,
      name: null,
      type: null,
      latitude: null,
      longitude: null,
      address: null,
      country: null,
      region: null,
      postalCode: null
    },
    currentSort: {column: 'id', dir: 'desc'},
  }),
  computed: {
    hubs() {
      return this.$store.getters.hubsItems;
    },
    hubsPerPage() {
      return this.$store.getters.hubsPerPage;
    },
    hubsCurrentPage() {
      return this.$store.getters.hubsCurrentPage;
    },
    hubsLastPage() {
      return this.$store.getters.hubsLastPage;
    },
    hubsFrom() {
      return this.$store.getters.hubsFrom;
    },
    hubsTo() {
      return this.$store.getters.hubsTo;
    },
    hubsTotal() {
      return this.$store.getters.hubsTotal;
    },
    hubsSortFields() {
      return this.$store.getters.hubsSortFields;
    },
    hubsActiveSort() {
      return this.$store.getters.hubsActiveSort;
    },
    hubsSearch() {
      return this.$store.getters.hubsSearch;
    },
  },
  mounted() {
    this.initHubs();
  },
  methods: {
    goToRoute(route) {
      this.$router.push({name: route});
    },
    onEdit(item) {
      this.$router.push({name: 'hubEdit', params: {slug: item.id}});
    },
    onSeeDocs(item) {
      this.$router.push({name: 'hubDelete', params: {slug: hub.id}});
    },
    initHubs() {
      const params = {
        page: this.hubsCurrentPage,
        perPage: this.hubsPerPage,
        filters: this.filters,
        sort: this.currentSort,
      };
      this.fetchHubs(params);
    },
    fetchHubs(params) {
      this.$store.dispatch(FETCH_HUBS, params).then((response) => {
        this.$log.info('FETCH_HUBS', response);
      }).catch((error) => {
        this.$log.error('FETCH_HUBS', error);
      });
    },
    onChangePageSize(value) {
      const params = {
        page: 1,
        perPage: value,
        filters: this.filters,
        sort: this.currentSort,
      };
      this.fetchHubs(params);
    },
    onFirstPage() {
      const params = {
        page: 1,
        perPage: this.hubsPerPage,
        filters: this.filters,
        sort: this.currentSort,
      };
      this.fetchHubs(params);
    },
    onPreviousPage() {
      const params = {
        page: this.hubsCurrentPage - 1,
        perPage: this.hubsPerPage,
        filters: this.filters,
        sort: this.currentSort,
      };
      this.fetchHubs(params);
    },
    onChangePage(data) {
      const params = {
        page: data,
        perPage: this.hubsPerPage,
        filters: this.filters,
        sort: this.currentSort,
      };
      this.fetchHubs(params);
    },
    onNextPage() {
      const params = {
        page: this.hubsCurrentPage + 1,
        perPage: this.hubsPerPage,
        filters: this.filters,
        sort: this.currentSort,
      };
      this.fetchHubs(params);
    },
    onLastPage() {
      const params = {
        page: this.hubsLastPage,
        perPage: this.hubsPerPage,
        filters: this.filters,
        sort: this.currentSort,
      };
      this.fetchHubs(params);
    },
    onSearch() {
      // debugger;
      let query = {
        page: 1,
        perPage: this.perPage,
        filters: this.filters,
        sort: this.currentSort,
      };

      this.fetchHubs(query);
    },
    onSort: function (s) {
      if (s === this.currentSort.column) {
        this.currentSort.dir = this.currentSort.dir === 'asc' ? 'desc' : 'asc';
      } else if (!this.currentSort.dir) {
        this.currentSort.dir = 'asc';
      }

      this.currentSort.column = s;
      this.onSearch();
    },
    toDate(datetime) {
      return datetime ? moment(datetime).format('DD/MM/YYYY') : '';

    },
    toDatetime(datetime) {
      return datetime ? moment(datetime).format('DD/MM/YYYY, h:mm:ss') : '';
    },

    addSortColumnClass(column) {
      if (column != this.currentSort.column) return '';

      if (column === 'lastArrivalTime') {
        return this.currentSort.dir === 'asc' ? 'desc' : 'asc';
      }
      return this.currentSort.dir;
    },

    create() {
      this.$toasted.show('adauga', {
        type: 'info',
        position: 'top-right',
        duration: 5000,
        icon: 'check',
      });
    },
    onDelete(hub, params) {
      this.$confirm('Esti sigur ca vrei sa stergi Punctul de Transfer?', 'Stergere hub #' + hub.id +" " + hub.name, 'question', {
        cancelButtonText: 'Nu',
        confirmButtonText: 'Da',
        showLoaderOnConfirm: true
      }).then((response) => {
        if (response) {
          this.isLoading = true;
          this.$store.dispatch(HUB_DELETE_SUBMIT, hub.id).then((response) => {
            Vue.notify({
              group: 'success',
              type: 'success',
              title: 'Hub-ul a fost sters cu success!',
            })
            this.$log.info('HUB_DELETE_SUBMIT', response);
            this.initHubs();
          }).catch((error) => {
            this.$log.error('HUB_DELETE_SUBMIT', error);
          });
        }
      });
    },
  },
};
</script>
